import { configureStore } from "@reduxjs/toolkit";
import headerSlice from "./reducers/headerSlice";
import uiSlice from "./reducers/uiSlice";
import userSlice from "./reducers/userSlice";

const store = configureStore({
  reducer: {
    header: headerSlice,
    user: userSlice,
    ui: uiSlice,
  },
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
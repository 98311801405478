import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styles from "./StoryDetailHeader.module.css";

type storyDetailHeaderProps = {
  width: string
}

const StoryDetailHeader = (): JSX.Element => {

  const onClickHandler = () => {
    const modal = document.getElementById("story-detail-modal")
    modal?.scrollTo(0, 0)
  }

  return (
    <React.Fragment>
      <div
        className={styles.header}>
        <FontAwesomeIcon onClick={onClickHandler} icon={faChevronLeft} />
      </div>
    </React.Fragment>
  )
}

export default StoryDetailHeader;
import axios, { AxiosResponse } from "axios";
import { category } from "./category";
import { Endpoint, LOCALSTORAGE_JWT } from "./constant";
import { fetchConfig } from "./generic";
import { place } from "./place";
import { triggerWarning } from "./triggerWarning";

interface story {
  author: string;
  author_alias?: string;
  id: number;
  title: string;
  trigger_warnings: triggerWarning[];
  content: string;
  sticker_count?: number;
  sticker_pressed?: boolean;
  sticker_counter_map?: any;
  place?: place;
  published_at?: string;
  categories?: category[];
  // ? how can we determined that the user has already gave a sticker
  // ? to a story
}

type storyCreateReq = {
  title?: string;
  content?: string;
  author_alias?: string;
  trigger_warnings?: number[];
  categories?: number[];
  place?: number;
};

interface storyFetchConfig extends fetchConfig {
  orderBy?: string;
}

class Story {
  static delete = async (id: number) => {
    const jwt = localStorage.getItem(LOCALSTORAGE_JWT);

    axios
      .delete(`${Endpoint.BACKEND_URL}${Endpoint.PATH_STORY}/${id}`, {
        headers: { Authorization: `Bearer ${jwt}` },
      })
      .then((res) => {
        if (res.status === 200) {
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  static findOne = async (storyId: string): Promise<story | undefined> => {
    const resp = await axios.get(
      `${Endpoint.BACKEND_URL}${Endpoint.PATH_STORY}/${storyId}`
    );
    if (resp.status === 200) {
      return resp.data;
    }

    if (resp.status >= 400) {
      throw new Error(resp.statusText);
    }
  };

  static getRandom = async (limit: number): Promise<story[] | undefined> => {
    const resp = await axios.get(
      `${Endpoint.BACKEND_URL}${Endpoint.PATH_STORY_RANDOM}?_limit=${limit}`
    );

    if (resp.status === 200) {
      return resp.data;
    }
    throw new Error("cannot get random stories: " + resp.statusText);
  };

  static findByPlaceId = async (placeID: number, cfg: storyFetchConfig) => {
    const jwt = localStorage.getItem(LOCALSTORAGE_JWT);

    let fetchQuery: string = `?place_eq=${placeID}&_limit=${cfg.fetchLimit}&_start=${cfg.fetchSkip}`;

    if (cfg.orderBy !== undefined) {
      if (cfg.orderBy === "most recent") {
        fetchQuery += "&_sort=published_at:DESC";
      }
    }

    let resp: AxiosResponse<any>;
    if (jwt === null) {
      resp = await axios.get(
        `${Endpoint.BACKEND_URL}${Endpoint.PATH_STORY}${fetchQuery}`
      );
    } else {
      resp = await axios.get(
        `${Endpoint.BACKEND_URL}${Endpoint.PATH_STORY}${fetchQuery}`,
        { headers: { Authorization: `Bearer ${jwt}` } }
      );
    }

    if (resp.status === 200) {
      return resp.data;
    } else {
      throw new Error(resp.data);
    }
  };

  static findByMostPopular = async (placeId: number, cfg: fetchConfig) => {
    let fetchQuery: string = `?_limit=${cfg.fetchLimit}&_start=${cfg.fetchSkip}&place_eq=${placeId}&_sort=sticker_count:DESC`;

    const resp = await axios.get(
      `${Endpoint.BACKEND_URL}${Endpoint.PATH_STORY}${fetchQuery}`
    );
    if (resp.status === 200) {
      return resp.data;
    } else {
      throw new Error(resp.data);
    }
  };

  static findByIds = async (storyIds: number[]) => {
    let fetchQuery: string = "?";
    for (const storyId of storyIds) {
      fetchQuery += `&id_in=${storyId}`;
    }

    let stories = [];
    const resp = await axios.get(
      `${Endpoint.BACKEND_URL}${Endpoint.PATH_STORY}${fetchQuery}`
    );
    if (resp.status === 200) {
      stories = resp.data;
    } else {
      throw new Error(resp.data);
    }

    // add story to map
    let storyById = new Map<number, any>([]);
    for (const story of stories) {
      storyById.set(story.id, story);
    }

    let orderedStories = [];
    for (const storyId of storyIds) {
      const story = storyById.get(storyId);
      orderedStories.push(story);
    }

    return orderedStories;
  };

  static findByCategoryIds = async (
    categoryIds: number[],
    cfg?: storyFetchConfig
  ) => {
    // sanitize categoryIds
    categoryIds = categoryIds.filter((catId) => !isNaN(catId));

    let query = "?";

    if (categoryIds.length !== 0) {
      for (const categoryId of categoryIds) {
        query += `categories.id_in=${categoryId}&`;
      }
      query = query.slice(0, -1);
    }

    if (cfg !== undefined) {
      query += `&_limit=${cfg.fetchLimit}&_start=${cfg.fetchSkip}`;
    }

    const jwt = localStorage.getItem(LOCALSTORAGE_JWT);
    let resp: AxiosResponse<any>;

    if (jwt === null) {
      resp = await axios.get(
        `${Endpoint.BACKEND_URL}${Endpoint.PATH_STORY}${query}`
      );
    } else {
      resp = await axios.get(
        `${Endpoint.BACKEND_URL}${Endpoint.PATH_STORY}${query}`,
        { headers: { Authorization: `Bearer ${jwt}` } }
      );
    }
    if (resp.status === 200) {
      return resp.data as story[];
    }

    throw resp;
  };
}

export type { story, storyCreateReq };

export { Story };

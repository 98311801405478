import React, { useState, useEffect } from "react";
import styles from "./HomePage.module.css";
import { Story, story } from "../types/story";
import RefreshPNG from "./../assets/icons/refresh.png";
import Button from "../components/ui/Button";
import { Link, useHistory } from "react-router-dom";
import Footer from "../components/layout/Footer";
import Carousel, { Dots } from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import StoryThumbnail from "../components/story/StoryThumbnail";
import Select from "react-select";
import { Category } from "../types/category";
import { SESSIONSTORAGE_SELECTED_CATEGORY_IDS } from "../types/constant";
import AnimatedArrow from "../components/ui/AnimatedArrow";

const HomePage = (): JSX.Element => {
  const history = useHistory();

  const [whiteLayerOpac, setWhiteLayerOpac] = useState(0);
  const [carouselDot, setCarouselDot] = useState(0);
  const [randomStories, setRandomStories] = useState<story[]>();
  const [categoryOptions, setCategoryOptions] = useState<
    { value: any; label: string }[]
  >([]);
  const [selectedCategoryIds, setSelectedCategoryIds] = useState<number[]>([]);

  const handleScroll = () => {
    const currentLocation = window.location.href
      .toString()
      .split(window.location.host)[1];
    if (currentLocation === "/home") {
      const viewportHeight = window.innerHeight;
      const scrollYPos = document.documentElement.scrollTop;
      const scrollPercentOfVPHeight = scrollYPos / viewportHeight;
      setWhiteLayerOpac(scrollPercentOfVPHeight);
    }
  };

  const onSelectCategory = (e: any) => {
    const choices = e as { value: number; label: string }[];

    let _selectedCategoryIds: number[] = [];
    for (const choice of choices) {
      _selectedCategoryIds.push(choice.value);
    }

    setSelectedCategoryIds(_selectedCategoryIds);
  };

  const onRefreshRandomStories = () => {
    Story.getRandom(3)
      .then((stories) => {
        setRandomStories(stories);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onChangeCarousel = (value: number) => {
    if (!isNaN(value)) {
      setCarouselDot(value);
    }
  };

  const onSubmitCategories = () => {
    sessionStorage.setItem(
      SESSIONSTORAGE_SELECTED_CATEGORY_IDS,
      selectedCategoryIds.join(",")
    );

    window.scrollTo(0, 0);
    history.push("/c");
  };

  useEffect(() => {
    // add scroll listener
    window.addEventListener("scroll", handleScroll);

    // get random stories
    Story.getRandom(3)
      .then((stories) => {
        setRandomStories(stories);
      })
      .catch((err) => {
        console.log(err);
      });

    // get all categories sorted by id:asc
    Category.find({ sort: [{ field: "title_th", direction: "asc" }] })
      .then((categories) => {
        let _categoryOptions: { value: any; label: string }[] = [];
        for (const category of categories) {
          _categoryOptions.push({
            label: `${category.title_th} (${category.title_en})`,
            value: category.id,
          });
        }
        setCategoryOptions([..._categoryOptions]);
      })
      .catch((err) => {
        console.log(err);
      });

    return () => {
      // clear scroll listener
      window.removeEventListener("scorll", handleScroll);
    };
  }, []);

  return (
    <React.Fragment>
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
      <div className={styles.homepage_container}>
        <div style={{ opacity: `${1.1 - whiteLayerOpac}` }}>
          <AnimatedArrow />
        </div>

        <div
          style={{
            backgroundColor: `rgb(253,229,222,${whiteLayerOpac - 0.5})`,
          }}
          className={styles.white_layer}
        >
          {/* Go Map Button */}
          <div className={styles.gomap_button_container}>
            <p>
              ยินดีต้อนรับเข้าสู่ All Thai Women
              คุณสามารถเลือกสถานที่ในแผนที่เพื่อเข้าไปบอกเล่าปัญหาหรือเหตุการณ์ต่างๆ
              ที่คุณพบเจอเนื่องจากการเป็นผู้หญิง
              หรือเข้าไปอ่านโพสต์ของผู้หญิงไทยคนอื่นๆ
              และให้กำลังใจเธอเหล่านั้นด้วยการกดส่ง สติกเกอร์ <br />
              <br />
              แตะปุ่ม “เข้าสู่แผนที่” เพื่อเริ่ม
              หรือเลื่อนลงเพื่ออ่านเรื่องผ่านหมวดหมู่
            </p>
            <div className={styles.gomap_button}>
              <Link to="/map">
                <Button
                  backgroundColor="#F13D57"
                  text="เข้าสู่แผนที่"
                  width="100%"
                />
              </Link>
            </div>
          </div>

          {/* Second Page */}
          <div className={styles.category_page_container}>
            <div className={styles.category_selection_container}>
              <span className={styles.header_button}>
                อ่านเรื่องจากหมวดหมู่
              </span>
              <div className={styles.select_category_container}>
                <Select
                  isMulti
                  placeholder="เลือกหมวดหมู่ที่คุณสนใจ"
                  onChange={onSelectCategory}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      width: "100%",
                      marginTop: "1vh",
                      marginBottom: "1vh",
                      fontSize: "0.8em",
                      borderRadius: "2em",
                      fontFamily: "BaiJamjuree",
                    }),
                    option: (provided) => ({
                      ...provided,
                      fontFamily: "BaiJamjuree",
                      textAlign: "left",
                    }),
                  }}
                  options={categoryOptions}
                />
              </div>

              <div className={styles.category_button}>
                <Button
                  onClick={onSubmitCategories}
                  text="ยืนยัน"
                  backgroundColor="#68b7b4"
                  width="100%"
                />
              </div>
            </div>

            <div className={styles.you_may_interested_container}>
              <span
                className={styles.header_button}
                onClick={onRefreshRandomStories}
              >
                เรื่องราวที่คุณอาจสนใจ&nbsp;
                <img src={RefreshPNG} alt="refersh" />
              </span>

              {randomStories === undefined ? (
                <p>no stories...</p>
              ) : (
                <React.Fragment>
                  <Carousel value={carouselDot} onChange={onChangeCarousel}>
                    {randomStories!.map((story, idx) => {
                      return (
                        // click and show stories
                        <StoryThumbnail
                          key={`rand-story-${idx}`}
                          id={story.id}
                          title={story.title}
                          triggerWarnings={story.trigger_warnings}
                          contentPreview={story.content}
                          sitcker_pressed={story.sticker_pressed}
                          sticker_count={story.sticker_count}
                          author_alias={story.author_alias}
                          goToStory={() => {
                            history.push(`/c/${story.id}`, { from: "home" });
                          }}
                        />
                      );
                    })}
                  </Carousel>
                  <Dots
                    value={carouselDot}
                    onChange={onChangeCarousel}
                    number={3}
                  />
                </React.Fragment>
              )}
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </React.Fragment>
  );
};

export default HomePage;

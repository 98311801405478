import React from "react";
import { useEffect } from "react";
import { useHistory } from "react-router";
import NewStoryForm from "../components/story/NewStoryForm";
import { useAppSelector } from "../hooks";
import styles from "./NewStoryPage.module.css";

const NewStoryPage = (): JSX.Element => {
  const userIsLoggedIn = useAppSelector(
    (state) => state.user.userIsAuthenticated
  );
  const history = useHistory();

  useEffect(() => {
    if (!userIsLoggedIn) {
      history.replace("/login");
    }
    return () => {};
  }, []);

  return (
    <React.Fragment>
      <div className={styles.new_story_page_container}>
        <NewStoryForm />
      </div>
    </React.Fragment>
  );
};

export default NewStoryPage;

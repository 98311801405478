import axios from "axios";
import { Endpoint } from "./constant";

class Sticker {
  static addToStory = async (stickerId: number, storyId: number, jwt: string) => {
    const res = await axios.post(`${Endpoint.BACKEND_URL}${Endpoint.PATH_USER_STORY_STICKER}`,
      {
        story: storyId,
        sticker: stickerId,
      },
      { headers: { "Authorization": `Bearer ${jwt}` } })

    if (res.status === 200) {
      return res.data;
    }

    throw (res);
  }
}

export {
  Sticker
};
import styles from "./GoogleButton.module.css"

type GoogleButtonProps = {
  disabled: boolean
}

const GoogleButton = (props: GoogleButtonProps): JSX.Element => {
  return (
    <button type="button" className={styles["login-with-google-btn"]} disabled={props.disabled}>
      Sign in with Google
    </button>
  )
}

export default GoogleButton;

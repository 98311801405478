import React, { useState, useEffect } from "react";
import StoryThumbnail from "../components/story/StoryThumbnail";
import { SESSIONSTORAGE_SELECTED_CATEGORY_IDS } from "../types/constant";
import { Story, story } from "../types/story";
import styles from "./CategoryFeedPage.module.css";
import { useHistory, useLocation } from "react-router";
import Select from "react-select";
import { Category } from "../types/category";
import Button from "../components/ui/Button";
import { useCallback } from "react";

// TODO: Cannot load story 6-10 when coming from previous page

const CategoryFeedPage = (): JSX.Element => {
  const fetchLimit: number = 5;
  const [loadStoryReady, setLoadStoryReady] = useState<boolean>(false);
  const [fetchStart, setFetchStart] = useState<number>(0);
  const [noMoreStories, setNoMoreStories] = useState<boolean>(false);
  const [selectedCategoryIds, setSelectedCategoryIds] = useState<number[]>([]);
  const [firstLanding, setFirstLanding] = useState<boolean>(true);
  const [stories, setStories] = useState<story[]>([]);
  const [categoryOptions, setCategoryOptions] = useState<
    { value: any; label: string }[]
  >([]);

  const history = useHistory();
  const location = useLocation();

  const scrollHandler = useCallback(() => {
    const feedContainer = document.getElementById(
      "category-feed-page-container"
    );

    const scrollYPos = window.scrollY;
    const scrollHeight = feedContainer?.scrollHeight;

    if (scrollHeight! - scrollYPos <= window.innerHeight) {
      if (!loadStoryReady && !noMoreStories) {
        setLoadStoryReady(true);
      }
    }
  }, [loadStoryReady, noMoreStories]);

  // do at start
  useEffect(() => {
    window.onbeforeunload = () => {
      window.scrollTo(0, 0);
    };

    window.addEventListener("scroll", scrollHandler);

    const preSelectedCategoryIdsStr = sessionStorage
      .getItem(SESSIONSTORAGE_SELECTED_CATEGORY_IDS)
      ?.split(",");
    if (
      preSelectedCategoryIdsStr !== undefined &&
      preSelectedCategoryIdsStr !== [""]
    ) {
      const preSelectedCategoryIds = preSelectedCategoryIdsStr?.map((c) => {
        return parseInt(c);
      });

      setSelectedCategoryIds([...preSelectedCategoryIds!]);

      // fetch at start only once
      if (firstLanding) {
        Story.findByCategoryIds(preSelectedCategoryIds!, {
          fetchSkip: 0,
          fetchLimit: fetchLimit,
        })
          .then((stories) => {
            setStories(stories);
            setFetchStart((fetchSkip) => fetchSkip + fetchLimit);
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {});

        // get all categories sorted by id:asc
        Category.find({ sort: [{ field: "title_th", direction: "asc" }] })
          .then((categories) => {
            let _categoryOptions: { value: any; label: string }[] = [];
            for (const category of categories) {
              _categoryOptions.push({
                label: category.title_th,
                value: category.id,
              });
            }
            setCategoryOptions([..._categoryOptions]);
          })
          .catch((err) => {
            console.log(err);
          });

        setFirstLanding(false);
      }

      return () => {
        window.removeEventListener("scroll", scrollHandler);
      };
    }
  }, [scrollHandler]);

  useEffect(() => {
    // % Why add fetchSkip here? Because This use effect is called when component mount too
    // % Sometimes, it overrides the useEffect that run once at start

    if (loadStoryReady && !noMoreStories) {
      Story.findByCategoryIds(selectedCategoryIds!, {
        fetchSkip: fetchStart,
        fetchLimit: fetchLimit,
      })
        .then((moreStories) => {
          // console.log("from reload stories", moreStories);
          if (moreStories.length === 0) {
            setNoMoreStories((state) => true);
          }
          setStories([...stories, ...moreStories]);
          setFetchStart((fetchSkip) => fetchSkip + fetchLimit);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoadStoryReady(false);
        });
    }

    return () => {};
    // ! do not add stories as dependency
  }, [loadStoryReady, noMoreStories]);

  const onSubmit = (selectedCategoryIds: number[]) => {
    Story.findByCategoryIds(selectedCategoryIds, {
      fetchSkip: 0,
      fetchLimit: fetchLimit,
    })
      .then((stories) => {
        setStories(stories);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        // reset fetchSkip
        window.scrollTo(0, 0);
        setFetchStart(fetchLimit);

        // reset fetch variables
        setLoadStoryReady(false);
        setNoMoreStories(false);
      });
  };

  const onSelectCategories = (e: any) => {
    const choices = e as { value: any; label: string }[];
    let _selectedCategoryIds: number[] = [];
    for (const choice of choices) {
      _selectedCategoryIds.push(choice.value);
    }

    setSelectedCategoryIds([..._selectedCategoryIds]);
  };

  return (
    <React.Fragment>
      <div
        id="category-feed-page-container"
        className={styles.category_feed_page_container}
      >
        <div className={styles.category_selection_container}>
          <h3>อ่านเรื่องราวจากหมวดหมู่</h3>
          <div className={styles.category_selection_container_input}>
            <Select
              isMulti
              placeholder="กรุณาเลือกหมวดหมู่"
              onChange={onSelectCategories}
              options={categoryOptions}
              styles={{
                control: (provided) => ({
                  ...provided,
                  borderRadius: "20px",
                  width: "100%",
                  marginTop: "1vh",
                  marginBottom: "1vh",
                }),
              }}
            />
            <Button
              onClick={() => {
                onSubmit(selectedCategoryIds);
              }}
              text="ยืนยัน"
              backgroundColor="#68b7b4"
              width="100%"
            />
          </div>
        </div>

        <div className={styles.category_feed}>
          {stories.length === 0 ? (
            <h2 className={styles.text_center}>ไม่มีเรื่องราว...</h2>
          ) : (
            stories.map((story) => {
              return (
                <StoryThumbnail
                  key={`story-thumbnail-${story.id}`}
                  id={story.id}
                  title={story.title}
                  triggerWarnings={story.trigger_warnings}
                  contentPreview={story.content}
                  sitcker_pressed={story.sticker_pressed}
                  sticker_count={story.sticker_count}
                  author_alias={story.author_alias}
                  goToStory={() => {
                    history.push(`/c/${story.id}${location.search}`, {
                      from: "cpage",
                    });
                  }}
                />
              );
            })
          )}

          {stories.length !== 0 && (
            <p className={styles.text_center}>ไม่มีเรื่องราวเพิ่มเติม ...</p>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default CategoryFeedPage;

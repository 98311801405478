import axios from "axios"
import { Endpoint } from "./constant"

interface category {
  id: number,
  title_th: string,
  title_en: string,
}

interface fetchCategoryConfig {
  sort?: { field: string, direction: string }[],
}

class Category {
  static find = async (cfg: fetchCategoryConfig): Promise<category[]> => {
    let qParam = "?"
    if (cfg.sort !== undefined) {
      qParam += "_sort="

      let sortClauses: string[] = [];
      for (const elem of cfg.sort) {
        sortClauses.push(`${elem.field}:${elem.direction}`)
      }

      qParam += sortClauses.join()
    }

    const resp = await axios.get(`${Endpoint.BACKEND_URL}${Endpoint.PATH_CATEGORY}${qParam}`)
    if (resp.status === 200) {
      return resp.data as category[];
    }

    throw (resp)
  }
}

export type {
  category
}

export {
  Category
}
import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import styles from "./StoryDetailPage.module.css";
import { story } from "../types/story";
import Button from "../components/ui/Button";
import LogoSVG from "./../assets/icons/logo-01.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faFlag } from "@fortawesome/free-solid-svg-icons";
import { useAppDispatch, useAppSelector } from "../hooks";
import { displayHeader } from "../reducers/headerSlice";
import { CSSTransition } from "react-transition-group";
import StoryDetailHeader from "../components/story/StoryDetailHeader";
import StickerShareBox from "../components/story/StickerShareBox";
import axios from "axios";
import {
  Endpoint,
  LOCALSTORAGE_JWT,
  placeIconByString,
} from "../types/constant";
import { setIsReading } from "../reducers/userSlice";
import StickerConfirmModal from "../components/story/StickerConfirmModal";
import { Sticker } from "../types/sticker";
import { useQuery } from "../hooks/useQuery";
import LocSchoolSVG from "./../assets/icons/locations/school.svg";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { faHeart as farHeart } from "@fortawesome/free-regular-svg-icons";

type StoryDetailPageRouteParams = {
  storyId: string;
};

const StoryDetailPage = (): JSX.Element => {
  const { storyId } = useParams<StoryDetailPageRouteParams>();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const userIsLoggedIn = useAppSelector(
    (state) => state.user.userIsAuthenticated
  );
  const [showPleaseLogin, setShowPleaseLogin] = useState<boolean>(false);
  const [showStory, setShowStory] = useState(false);
  const [showSmallHeader, setShowSmallHeader] = useState(false);
  const [showStickerPanel, setShowStickerPanel] = useState(false);
  const [showSolidSticker, setShowSolidSticker] = useState(false);
  const [stickerCount, setStickerCount] = useState(0);
  const jwt = localStorage.getItem(LOCALSTORAGE_JWT);
  const [story, setStory] = useState<story>();
  const [showConfirmStickerModal, setShowConfirmStickerModal] =
    useState<boolean>(false);
  const [sentStickerId, setSentStickerId] = useState<number>(0);
  const [placeIcon, setPlaceIcon] = useState<string>(LocSchoolSVG);
  const queryParams = useQuery();

  const handleScroll = (e: Event) => {
    const viewportHeight = window.innerHeight;
    const modal = document.getElementById("story-detail-modal");
    const scrollYPos = modal?.scrollTop as number;
    const scrollPercentOfVPHeight = scrollYPos / viewportHeight;

    if (scrollPercentOfVPHeight > 0.4) {
      setShowSmallHeader(true);
      return;
    }
    setShowSmallHeader(false);
  };

  useEffect(() => {
    const ac = new AbortController();

    if (jwt !== null) {
      axios
        .get(`${Endpoint.BACKEND_URL}${Endpoint.PATH_STORY}/${storyId}`, {
          headers: { Authorization: `Bearer ${jwt}` },
        })
        .then((res) => {
          if (res.status === 200) {
            setStory(res.data);
            setStickerCount(res.data.sticker_count);
          }
          if (res.data.sticker_pressed) {
            setShowSolidSticker(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .get(`${Endpoint.BACKEND_URL}${Endpoint.PATH_STORY}/${storyId}`)
        .then((res) => {
          if (res.status === 200) {
            setStory(res.data);
            setStickerCount(res.data.sticker_count);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    const place = queryParams.get("place");
    if (place !== null) {
      const placeIcon = placeIconByString.get(place);
      if (placeIcon !== undefined) {
        setPlaceIcon(placeIcon);
      }
    } else {
    }

    return () => {
      ac.abort();

      // when user press goBack, set displayHeader and isReading
      dispatch(displayHeader(true));
      setShowStory(false);
      dispatch(setIsReading(false));
    };
    // ! Do not add queryParams as dependencies (page won't load)
  }, [jwt, storyId, dispatch]);

  useEffect(() => {
    // * story-detail-modal is a div living in FeedPage.tsx
    // * I created a div that acts as a modal.
    // * Since that modal is fixed position. The browser won't be able to
    // * detect scrolling because the body is un-scrollable (due to redux isReading state)
    // * and if you try to listen to scroll using window.scroll, it'll always returns 0.
    // *
    // * So I added a listener to the modal itself to detect scroll of a div.
    const modal = document.getElementById("story-detail-modal");
    dispatch(setIsReading(true));
    dispatch(displayHeader(false));
    // scroll to top when page loads
    window.scrollTo(0, 0);

    const showStoryAtLoad = setTimeout(() => {
      setShowStory(true);
      modal?.addEventListener("scroll", handleScroll);
    }, 250);

    return () => {
      clearTimeout(showStoryAtLoad);

      modal?.removeEventListener("scroll", handleScroll);
    };
  }, [dispatch]);

  function closeStoryHandler() {
    setShowStory(false);
    dispatch(setIsReading(false));

    setTimeout(() => {
      // * the state contains the information of the previous page
      const historyState = history.location.state as any;
      if (historyState !== undefined) {
        switch (historyState.from) {
          case "home":
            history.goBack();
            break;
          case "cpage":
            history.goBack();
            break;
          case "spage":
            history.goBack();
            break;
          case "internal":
            history.goBack();
            break;
          default:
            break;
        }
      } else {
        history.push("/home");
      }

      dispatch(displayHeader(true));
    }, 600);
  }

  const toggleStickerPanel = () => {
    if (userIsLoggedIn) {
      setShowStickerPanel((state) => !state);
      return;
    }

    setShowPleaseLogin(true);
  };

  const onRemoveSticker = (storyId: number) => {
    axios
      .post(
        `${Endpoint.BACKEND_URL}${Endpoint.PATH_USER_STORY_STICKER_DELETE}`,
        { story: storyId },
        { headers: { Authorization: `Bearer ${jwt}` } }
      )
      .then((res) => {
        if (res.status === 200) {
          setShowSolidSticker(false);
          setStickerCount((state) => state - 1);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let triggerWarningText: string = "";
  story?.trigger_warnings.forEach((tw) => {
    triggerWarningText += tw.title_th + ", ";
  });

  let categoriesText: string = "";
  story?.categories?.forEach((cat) => {
    categoriesText += "#" + cat.title_th + ", ";
  });

  return (
    <React.Fragment>
      {showConfirmStickerModal && (
        <div className={styles.sticker_confirm_modal}>
          <StickerConfirmModal
            stickerId={sentStickerId}
            onCancel={() => {
              setShowConfirmStickerModal(false);
            }}
            onConfirm={() => {
              setShowConfirmStickerModal(false);

              Sticker.addToStory(sentStickerId, story?.id!, jwt!)
                .then((res) => {
                  setShowSolidSticker(true);

                  if (!showSolidSticker) {
                    setStickerCount((state) => state + 1);
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            }}
          />
        </div>
      )}

      {/* Small Header */}
      <CSSTransition
        mountOnEnter
        unmountOnExit
        in={showSmallHeader}
        timeout={500}
        classNames={{
          enter: styles.small_header_enter,
          enterActive: styles.small_header_enter_active,
          exitActive: styles.small_header_exit_acitve,
          exit: styles.small_header_exit,
        }}
      >
        <StoryDetailHeader />
      </CSSTransition>

      <div className={styles.story_detail_page}>
        {/* Small Header */}
        {/* <CSSTransition mountOnEnter unmountOnExit
          in={showSmallHeader}
          timeout={500}
          classNames={{
            enter: styles.small_header_enter,
            enterActive: styles.small_header_enter_active,
            exitActive: styles.small_header_exit_acitve,
            exit: styles.small_header_exit,
          }}
        >
          <StoryDetailHeader />
        </CSSTransition> */}

        {/* Logo */}
        <div className={styles.logo_container}>
          <img src={LogoSVG} alt="logo" />
        </div>

        {/* Story Container*/}
        <CSSTransition
          unmountOnExit
          mountOnEnter
          timeout={1000}
          in={showStory}
          classNames={{
            enter: styles.story_container_enter,
            enterActive: styles.story_container_enter_active,
            exitActive: styles.story_container_exit_active,
            exit: styles.story_container_exit,
          }}
        >
          <div className={styles.story_container}>
            <img
              className={styles.location_icon}
              src={placeIcon}
              alt="place icon"
            />

            {/* Banner */}
            <div className={styles.story_banner}>
              <div className={styles.story_banner_icon}>
                <FontAwesomeIcon
                  onClick={closeStoryHandler}
                  icon={faChevronDown}
                />
              </div>
              <h1>{story?.title}</h1>
            </div>

            {/* Content */}
            <div className={styles.content_container}>
              {/* Metadata */}
              <h4>
                TW: {triggerWarningText.slice(0, triggerWarningText.length - 2)}
              </h4>
              <div className={styles.content_container_sticker_bar}>
                <span>{story?.author_alias}</span>
                <div className={styles.sticker_counter}>
                  <span>{stickerCount}&nbsp;</span>

                  {showSolidSticker ? (
                    <FontAwesomeIcon
                      icon={faHeart}
                      onClick={() => {
                        onRemoveSticker(story!.id);
                      }}
                    />
                  ) : (
                    <FontAwesomeIcon icon={farHeart} />
                  )}
                </div>
              </div>

              {/* Actual Content */}
              <p>{story?.content}</p>

              {/* Categories */}
              <div className={styles.categories_container}>
                <p className={styles.textbold}>หมวดหมู่</p>
                <div className={styles.category_line}></div>
                <p>{categoriesText.slice(0, categoriesText.length - 2)}</p>
              </div>

              <div className={styles.sticker_sharebox_container}>
                {showStickerPanel && (
                  <div className={styles.sticker_sharebox}>
                    <StickerShareBox
                      storyId={story?.id}
                      onSetSentSticker={setSentStickerId}
                      onClickSticker={() => {
                        setShowConfirmStickerModal(true);
                      }}
                      toggleStickerPanel={toggleStickerPanel}
                    />
                  </div>
                )}
                <div className={styles.sticker_sharebox_button}>
                  {showPleaseLogin ? (
                    <p className={`${styles.small_text} ${styles.text_center}`}>
                      กรุณาลงชื่อเข้าใช้เพื่อส่งสติ๊กเกอร์{" "}
                      <a href="/login">Login</a>
                    </p>
                  ) : (
                    ""
                  )}
                  <Button
                    onClick={toggleStickerPanel}
                    width="60%"
                    text="ส่งสติ๊กเกอร์"
                    backgroundColor="#68b6b4"
                  />
                </div>

                <div className={styles.report_container}>
                  <p>
                    <span
                      onClick={() => {
                        history.push("/report", { storyId: storyId });
                      }}
                    >
                      Report &nbsp;
                      <FontAwesomeIcon icon={faFlag} />
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </CSSTransition>
      </div>
    </React.Fragment>
  );
};

export default StoryDetailPage;

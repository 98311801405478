import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";
import styles from "./StoryFoldDropdown.module.css";

type StoryFoldDropdownProps = {
  title: string
  listOptions: StoryFoldDropdownOption[]
  onChange?: React.Dispatch<any>
}

type StoryFoldDropdownOption = {
  name: string
  value: any
}

const StoryFoldDropdown = (props: StoryFoldDropdownProps): JSX.Element => {
  const [selectedOpt, setSelectedOpt] = useState(props.listOptions[0].name);
  const [showListOptions, setShowListOptions] = useState(false);

  const showListOptionsHandler = () => {
    setShowListOptions(state => !state)
  }

  const selectOptionHandler = (event: React.MouseEvent<HTMLElement>, value: string, name: string) => {
    setSelectedOpt(name);

    if (props.onChange !== undefined) {
      props.onChange(value);
    }
  }


  return (
    <React.Fragment>
      <div onClick={showListOptionsHandler} className={styles.dropdown_container}>
        <p className={styles.select_opt}>{selectedOpt} <FontAwesomeIcon icon={faChevronDown} /></p>

        <CSSTransition in={showListOptions} timeout={1000}
          classNames={{
            enter: styles.option_container_enter,
            enterActive: styles.option_container_enter_active,
            exit: styles.option_container_exit,
            exitActive: styles.option_container_exit_active,
          }}
          mountOnEnter
          unmountOnExit
        >
          <div className={styles.option_container}>
            {
              props.listOptions.map((listOpt, index) => {
                index === props.listOptions.length ?
                  <div className={styles.option_list_item}
                    onClick={(e) => { selectOptionHandler(e, listOpt.value, listOpt.name) }} key={index}>
                    {listOpt.name}
                  </div>
                  :
                  <div className={styles.option_list_item}
                    onClick={(e) => { selectOptionHandler(e, listOpt.value, listOpt.name) }} key={index}>
                    {listOpt.name}
                  </div>

                return (
                  index !== (props.listOptions.length - 1) && index !== 0 ?
                    <div className={`${styles.option_list_item} ${styles.option_list_item_middle}`}
                      onClick={(e) => { selectOptionHandler(e, listOpt.value, listOpt.name) }} key={index}>
                      {listOpt.name}
                    </div>
                    :
                    <div className={styles.option_list_item}
                      onClick={(e) => { selectOptionHandler(e, listOpt.value, listOpt.name) }} key={index}>
                      {listOpt.name}
                    </div>
                )
              }
              )
            }
          </div>
        </CSSTransition>
      </div>
    </React.Fragment >
  )
}

export default StoryFoldDropdown;
export type {
  StoryFoldDropdownOption
};

import { useState, useEffect } from "react";
import styles from "./LandingPage.module.css";
import { useHistory } from "react-router";
import { useAppDispatch } from "./../hooks";
import { displayHeader } from "./../reducers/headerSlice";

import LogoPNG from "./../assets/icons/logo-02.png";
import Button from "../components/ui/Button";
import { CSSTransition } from "react-transition-group";
import "./styles.css";

const paragraphs: JSX.Element[] = [
  <p>
    <span className={styles.text_bold_weight}>ALL THAI WOMEN</span>
    <br />
    คือโครงการที่ต้องการรวบรวมเสียงของผู้หญิงไทย ที่ต้องเผชิญกับปัญหาต่างๆ เช่น
    การกดขี่ อคติ หรือการเลือกปฏิบัติทางเพศในมิติต่างๆ
    <br />
    <br />
    แม้ผู้หญิงทั่วโลกจะประสบปัญหาร่วมกันในหลายด้าน แต่ด้วยอัตลักษณ์ที่แตกต่างกัน
    เช่น เชื้อชาติ ศาสนา ชนชั้น เพศสภาพ ฯลฯ จึงทำให้ปัญหาของผู้หญิงมีความซับซ้อน
    <br />
    <br />
    อย่างไรก็ตาม
    กลุ่มต่อต้านเฟมินิสต์และผู้เกลียดชังผู้หญิงยังคงปฏิเสธปัญหาที่ผู้หญิงต้องเผชิญในสังคมปิตาธิปไตย
    <br />
    <br />
    <span className={styles.text_bold_weight}>
      Spring Movement และ Democracy Restoration Group (DRG)
    </span>
    จึงร่วมกันเปิดช่องทางรวบรวมปัญหาต่างๆ ที่ผู้มีอัตลักษณ์เป็น “ผู้หญิงไทย”
    ต้องเผชิญ
  </p>,

  <p>
    <span className={styles.text_bold_weight}>
      เป้าหมายของโครงการ ALL THAI WOMEN คือการสร้างความเข้าอกเข้าใจ
      ความเป็นอันหนึ่งอันเดียวกันระหว่างผู้หญิงไทย
    </span>{" "}
    ทำให้ผู้หญิงไทยรู้สึกว่า
    ตนเองไม่ได้เผชิญปัญหาอันเกิดจากเพศหญิงและความเป็นหญิงอยู่เพียงลำพัง
    และต้องการให้ผู้ที่ปฏิเสธการมีอยู่ของสังคมปิตาธิปไตย
    หรือผู้ที่มองไม่เห็นการกดขี่ อคติ และการเลือกปฏิบัติทางเพศต่อเพศหญิง
    รับฟังเสียงของผู้หญิงไทย และเห็นว่า
    ผู้หญิงไทยประสบปัญหาอันเกิดจากเหตุแห่งเพศอย่างไร
  </p>,
];

const LandingPage = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [pageCounter, setPageCounter] = useState(0);
  const [inProp, setInProp] = useState(false);

  const tapHandler = async () => {
    setInProp(false);
    setTimeout(() => {
      setPageCounter((pageCounter) => pageCounter + 1);
    }, 300);
    setTimeout(() => {
      setInProp(true);
    }, 1000);
  };

  useEffect(() => {
    dispatch(displayHeader(false));

    setTimeout(() => {
      setInProp(true);
    }, 300);
    return () => {
      clearTimeout();
    };
  });

  useEffect(() => {
    if (pageCounter === paragraphs.length) {
      dispatch(displayHeader(true));
      history.push("/home");
    }

    return () => {};
  }, [pageCounter, history, dispatch]);

  return (
    <div className={styles.landing_page} onClick={tapHandler}>
      <div className={styles.logo_container}>
        <img className={`${styles.logo}`} src={LogoPNG} alt="Logo" />
      </div>

      <CSSTransition
        in={inProp}
        timeout={4000}
        classNames={{
          enter: styles.mynode_enter,
          enterActive: styles.mynode_enter_active,
          exit: styles.mynode_exit,
          exitActive: styles.mynode_exit_active,
        }}
        mountOnEnter
        unmountOnExit
      >
        <div className={styles.content_container}>
          {paragraphs[pageCounter]}
        </div>
      </CSSTransition>

      <div className={styles.tap_container}>
        <Button
          width="250px"
          fontSize="1.3em"
          backgroundColor="#68B6B4"
          text="TAP TO CONTINUE"
        />
      </div>
    </div>
  );
};

export default LandingPage;

import styles from './Button.module.css';

type ButtonProps = {
  text: string
  value?: string
  textColor?: string
  backgroundColor?: string
  width?: string
  height?: string
  margin?: string
  padding?: string
  fontSize?: string
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

const Button = (props: ButtonProps): JSX.Element => {
  let btnStyle: React.CSSProperties = {
    backgroundColor: props.backgroundColor,
    width: props.width === "" ? "" : props.width,
    height: props.height === "" ? "" : props.height,
    fontSize: props.fontSize === "" ? "" : props.fontSize,
    color: props.textColor === "" ? "" : props.textColor,
    margin: props.margin === "" ? "" : props.margin,
    padding: props.padding === "" ? "" : props.padding,
  }


  return (
    <button
      value={props.value}
      style={btnStyle}
      className={`${styles["button_default"]}`}
      onClick={props.onClick}>
      {props.text}
    </button>
  );
}

export default Button;
import React from "react";
import { Switch, Route } from "react-router";
import { NavLink } from "react-router-dom";
import styles from "./PrivacyPolicyPage.module.css";

const PrivacyPolicyPage = (): JSX.Element => {
  const contentTH = (
    <React.Fragment>
      <div></div>
      <h1>นโยบายความเป็นส่วนตัว</h1>
      <p>
        Spring Movement ให้ความสำคัญกับการคุ้มครองข้อมูลส่วนบุคคลของคุณ
        โดยนโยบายความเป็นส่วนตัวฉบับนี้ได้อธิบายแนวปฏิบัติเกี่ยวกับการเก็บรวบรวม
        ใช้ หรือเปิดเผยข้อมูลส่วนบุคคล รวมถึงสิทธิต่าง ๆ
        ของเจ้าของข้อมูลส่วนบุคคล ตามกฎหมายคุ้มครองข้อมูลส่วนบุคคล
      </p>

      <h2>การเก็บรวบรวมข้อมูลส่วนบุคคล</h2>
      <p>
        เราจะเก็บรวบรวมข้อมูลส่วนบุคคลที่ได้รับโดยตรงจากคุณผ่านช่องทาง
        ดังต่อไปนี้
      </p>

      <ul>
        <li>การสมัครสมาชิก</li>
        <li>อีเมล</li>
        <li>Facebook Login</li>
        <li>Google Login</li>
      </ul>

      <h2>ประเภทข้อมูลส่วนบุคคลที่เก็บรวบรวม</h2>
      <ul>
        <li>
          ข้อมูลส่วนบุคคล เช่น ชื่อ นามสกุล อายุ วันเดือนปีเกิด เพศ เป็นต้น
        </li>
        <li>ข้อมูลการติดต่อ เช่น อีเมล เป็นต้น</li>
        <li>ข้อมูลบัญชี เช่น บัญชีผู้ใช้งาน ประวัติการใช้งาน เป็นต้น</li>
      </ul>

      <h2>ผู้เยาว์</h2>
      <p>
        หากคุณมีอายุต่ำกว่า 20 ปีหรือมีข้อจำกัดความสามารถตามกฎหมาย
        เราอาจเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของคุณ
        เราอาจจำเป็นต้องให้พ่อแม่หรือผู้ปกครองของคุณให้ความยินยอมหรือที่กฎหมายอนุญาตให้ทำได้
        หากเราทราบว่ามีการเก็บรวบรวมข้อมูลส่วนบุคคลจากผู้เยาว์โดยไม่ได้รับความยินยอมจากพ่อแม่หรือผู้ปกครอง
        เราจะดำเนินการลบข้อมูลนั้นออกจากเซิร์ฟเวอร์ของเรา
      </p>

      <h2>วิธีการเก็บรักษาข้อมูลส่วนบุคคล</h2>
      <p>
        เราจะเก็บรักษาข้อมูลส่วนบุคคลของคุณในรูปแบบเอกสารและรูปแบบอิเล็กทรอนิกส์
      </p>
      <p>เราเก็บรักษาข้อมูลส่วนบุคคลของคุณ ดังต่อไปนี้</p>
      <ul>
        <li>ผู้ให้บริการเซิร์ฟเวอร์ในต่างประเทศ</li>
      </ul>

      <h2>การประมวลผลข้อมูลส่วนบุคคล</h2>
      <p>
        เราจะเก็บรวบรวม ใช้
        หรือเปิดเผยข้อมูลส่วนบุคคลของคุณเพื่อวัตถุประสงค์ดังต่อไปนี้
      </p>
      <ul>
        <li>เพื่อสร้างและจัดการบัญชีผู้ใช้งาน</li>
        <li>
          เพื่อการวิเคราะห์ข้อมูล และจัดทำสื่อนำเสนอในโซเชียลมีเดียของ Spring
          Movement
        </li>
      </ul>

      <h2>การเปิดเผยข้อมูลส่วนบุคคล</h2>
      <p>
        เราอาจเปิดเผยข้อมูลส่วนบุคคลของคุณให้แก่ผู้อื่นภายใต้ความยินยอมของคุณหรือที่กฎหมายอนุญาตให้เปิดเผยได้
      </p>

      <h2>ระยะเวลาจัดเก็บข้อมูลส่วนบุคคล</h2>
      <p>
        เราจะเก็บรักษาข้อมูลส่วนบุคคลของคุณไว้ตามระยะเวลาที่จำเป็นในระหว่างที่คุณเป็นผู้ใช้บริการหรือมีความสัมพันธ์อยู่กับเราหรือตลอดระยะเวลาที่จำเป็นเพื่อให้บรรลุวัตถุประสงค์ที่เกี่ยวข้องกับนโยบายฉบับนี้
        ซึ่งอาจจำเป็นต้องเก็บรักษาไว้ต่อไปภายหลังจากนั้น หากมีกฎหมายกำหนดไว้
        เราจะลบ ทำลาย หรือทำให้เป็นข้อมูลที่ไม่สามารถระบุตัวตนของคุณได้
        เมื่อหมดความจำเป็นหรือสิ้นสุดระยะเวลาดังกล่าว
      </p>

      <h2>สิทธิของเจ้าของข้อมูลส่วนบุคคล</h2>
      <p>
        ภายใต้กฎหมายคุ้มครองข้อมูลส่วนบุคคล คุณมีสิทธิในการดำเนินการดังต่อไปนี้
      </p>

      <p>
        สิทธิขอถอนความยินยอม (right to withdraw consent) หากคุณได้ให้ความยินยอม
        เราจะเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของคุณ
        ไม่ว่าจะเป็นความยินยอมที่คุณให้ไว้ก่อนวันที่กฎหมายคุ้มครองข้อมูลส่วนบุคคลใช้บังคับหรือหลังจากนั้น
        คุณมีสิทธิที่จะถอนความยินยอมเมื่อใดก็ได้ตลอดเวลา
      </p>
      <p>
        สิทธิขอเข้าถึงข้อมูล (right to access)
        คุณมีสิทธิขอเข้าถึงข้อมูลส่วนบุคคลของคุณที่อยู่ในความรับผิดชอบของเราและขอให้เราทำสำเนาข้อมูลดังกล่าวให้แก่คุณ
        รวมถึงขอให้เราเปิดเผยว่าเราได้ข้อมูลส่วนบุคคลของคุณมาได้อย่างไร
      </p>
      <p>
        สิทธิขอถ่ายโอนข้อมูล (right to data portability)
        คุณมีสิทธิขอรับข้อมูลส่วนบุคคลของคุณในกรณีที่เราได้จัดทำข้อมูลส่วนบุคคลนั้นอยู่ในรูปแบบให้สามารถอ่านหรือใช้งานได้ด้วยเครื่องมือหรืออุปกรณ์ที่ทำงานได้โดยอัตโนมัติและสามารถใช้หรือเปิดเผยข้อมูลส่วนบุคคลได้ด้วยวิธีการอัตโนมัติ
        รวมทั้งมีสิทธิขอให้เราส่งหรือโอนข้อมูลส่วนบุคคลในรูปแบบดังกล่าวไปยังผู้ควบคุมข้อมูลส่วนบุคคลอื่นเมื่อสามารถทำได้ด้วยวิธีการอัตโนมัติ
        และมีสิทธิขอรับข้อมูลส่วนบุคคลที่เราส่งหรือโอนข้อมูลส่วนบุคคลในรูปแบบดังกล่าวไปยังผู้ควบคุมข้อมูลส่วนบุคคลอื่นโดยตรง
        เว้นแต่ไม่สามารถดำเนินการได้เพราะเหตุทางเทคนิค
      </p>
      <p>
        สิทธิขอคัดค้าน (right to object) คุณมีสิทธิขอคัดค้านการเก็บรวบรวม ใช้
        หรือเปิดเผยข้อมูลส่วนบุคคลของคุณในเวลาใดก็ได้ หากการเก็บรวบรวม ใช้
        หรือเปิดเผยข้อมูลส่วนบุคคลของคุณที่ทำขึ้นเพื่อการดำเนินงานที่จำเป็นภายใต้ประโยชน์โดยชอบด้วยกฎหมายของเราหรือของบุคคลหรือนิติบุคคลอื่น
        โดยไม่เกินขอบเขตที่คุณสามารถคาดหมายได้อย่างสมเหตุสมผลหรือเพื่อดำเนินการตามภารกิจเพื่อสาธารณประโยชน์
      </p>
      <p>
        สิทธิขอให้ลบหรือทำลายข้อมูล (right to erasure/destruction)
        คุณมีสิทธิขอลบหรือทำลายข้อมูลส่วนบุคคลของคุณหรือทำให้ข้อมูลส่วนบุคคลเป็นข้อมูลที่ไม่สามารถระบุตัวคุณได้
        หากคุณเชื่อว่าข้อมูลส่วนบุคคลของคุณถูกเก็บรวบรวม ใช้
        หรือเปิดเผยโดยไม่ชอบด้วยกฎหมายที่เกี่ยวข้องหรือเห็นว่าเราหมดความจำเป็นในการเก็บรักษาไว้ตามวัตถุประสงค์ที่เกี่ยวข้องในนโยบายฉบับนี้
        หรือเมื่อคุณได้ใช้สิทธิขอถอนความยินยอมหรือใช้สิทธิขอคัดค้านตามที่แจ้งไว้ข้างต้นแล้ว
      </p>
      <p>
        สิทธิขอให้ระงับการใช้ข้อมูล (right to restriction of processing)
        คุณมีสิทธิขอให้ระงับการใช้ข้อมูลส่วนบุคคลชั่วคราวในกรณีที่เราอยู่ระหว่างตรวจสอบตามคำร้องขอใช้สิทธิขอแก้ไขข้อมูลส่วนบุคคลหรือขอคัดค้านของคุณหรือกรณีอื่นใดที่เราหมดความจำเป็นและต้องลบหรือทำลายข้อมูลส่วนบุคคลของคุณตามกฎหมายที่เกี่ยวข้องแต่คุณขอให้เราระงับการใช้แทน
      </p>
      <p>
        สิทธิขอให้แก้ไขข้อมูล (right to rectification)
        คุณมีสิทธิขอแก้ไขข้อมูลส่วนบุคคลของคุณให้ถูกต้อง เป็นปัจจุบัน สมบูรณ์
        และไม่ก่อให้เกิดความเข้าใจผิด
      </p>
      <p>
        สิทธิร้องเรียน (right to lodge a complaint)
        คุณมีสิทธิร้องเรียนต่อผู้มีอำนาจตามกฎหมายที่เกี่ยวข้อง
        หากคุณเชื่อว่าการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของคุณ
        เป็นการกระทำในลักษณะที่ฝ่าฝืนหรือไม่ปฏิบัติตามกฎหมายที่เกี่ยวข้อง
      </p>
      <p>
        คุณสามารถใช้สิทธิของคุณในฐานะเจ้าของข้อมูลส่วนบุคคลข้างต้นได้
        โดยติดต่อมาที่เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคลของเราตามรายละเอียดท้ายนโยบายนี้
        เราจะแจ้งผลการดำเนินการภายในระยะเวลา 30 วัน
        นับแต่วันที่เราได้รับคำขอใช้สิทธิจากคุณ
        ตามแบบฟอร์มหรือวิธีการที่เรากำหนด ทั้งนี้
        หากเราปฏิเสธคำขอเราจะแจ้งเหตุผลของการปฏิเสธให้คุณทราบผ่านช่องทางต่าง ๆ
        เช่น ข้อความ (SMS) อีเมล โทรศัพท์ จดหมาย เป็นต้น
      </p>

      <h2>การรักษาความมั่นคงปลอดภัยของข้อมูลส่วนบุคคล</h2>
      <p>
        เราจะรักษาความมั่นคงปลอดภัยของข้อมูลส่วนบุคคลของคุณไว้ตามหลักการ
        การรักษาความลับ (confidentiality) ความถูกต้องครบถ้วน (integrity)
        และสภาพพร้อมใช้งาน (availability) ทั้งนี้ เพื่อป้องกันการสูญหาย เข้าถึง
        ใช้ เปลี่ยนแปลง แก้ไข หรือเปิดเผย
        นอกจากนี้เราจะจัดให้มีมาตรการรักษาความมั่นคงปลอดภัยของข้อมูลส่วนบุคคล
        ซึ่งครอบคลุมถึงมาตรการป้องกันด้านการบริหารจัดการ (administrative
        safeguard) มาตรการป้องกันด้านเทคนิค (technical safeguard)
        และมาตรการป้องกันทางกายภาพ (physical safeguard)
        ในเรื่องการเข้าถึงหรือควบคุมการใช้งานข้อมูลส่วนบุคคล (access control)
      </p>

      <h2>การแจ้งเหตุละเมิดข้อมูลส่วนบุคคล</h2>
      <p>
        ในกรณีที่มีเหตุละเมิดข้อมูลส่วนบุคคลของคุณเกิดขึ้น
        เราจะแจ้งให้สำนักงานคณะกรรมการคุ้มครองข้อมูลส่วนบุคคลทราบโดยไม่ชักช้าภายใน
        72 ชั่วโมง นับแต่ทราบเหตุเท่าที่สามารถกระทำได้
        ในกรณีที่การละเมิดมีความเสี่ยงสูงที่จะมีผลกระทบต่อสิทธิและเสรีภาพของคุณ
        เราจะแจ้งการละเมิดให้คุณทราบพร้อมกับแนวทางการเยียวยาโดยไม่ชักช้าผ่านช่องทางต่าง
        ๆ เช่น เว็บไซต์ ข้อความ (SMS) อีเมล โทรศัพท์ จดหมาย เป็นต้น
      </p>

      <h2>การแก้ไขเปลี่ยนแปลงนโยบายความเป็นส่วนตัว</h2>
      <p>
        เราอาจแก้ไขเปลี่ยนแปลงนโยบายนี้เป็นครั้งคราว
        โดยคุณสามารถทราบข้อกำหนดและเงื่อนไขนโยบายที่มีการแก้ไขเปลี่ยนแปลงนี้ได้ผ่านทางเว็บไซต์ของเรา
        นโยบายนี้แก้ไขล่าสุดและมีผลใช้บังคับตั้งแต่วันที่ 27 กรกฎาคม 2564
      </p>

      <h2>นโยบายความเป็นส่วนตัวของเว็บไซต์อื่น</h2>
      <p>
        นโยบายความเป็นส่วนตัวฉบับนี้ใช้สำหรับการใช้งานบนเว็บไซต์สำหรับคุณเท่านั้น
        หากคุณเข้าชมเว็บไซต์อื่นแม้จะผ่านช่องทางเว็บไซต์ของเรา
        การคุ้มครองข้อมูลส่วนบุคคลต่าง ๆ
        จะเป็นไปตามนโยบายความเป็นส่วนตัวของเว็บไซต์นั้น
        ซึ่งเราไม่มีส่วนเกี่ยวข้องด้วย
      </p>

      <h2>รายละเอียดการติดต่อ</h2>
      <p>
        หากคุณต้องการสอบถามข้อมูลเกี่ยวกับนโยบายความเป็นส่วนตัวฉบับนี้
        รวมถึงการขอใช้สิทธิต่าง ๆ คุณสามารถติดต่อเราได้ ดังนี้
      </p>

      <p>ผู้ควบคุมข้อมูลส่วนบุคคล</p>
      <p>
        Spring Movement 161/41 วังบูรพาภิรมย์ พระนคร กรุงเทพมหานคร 10200 อีเมล
        <br />
        <a href="mailto:springmovement@gmail.com">springmovement@gmail.com</a>
        <br />
        www.allthaiwomen.com
        <br />
        หมายเลขโทรศัพท์ 085-251-7279
      </p>
    </React.Fragment>
  );

  const contentEN = (
    <React.Fragment>
      <h1>Privacy Policy</h1>
      <p>
        Spring Movement recognizes the importance of the protection of your
        personal data. This Privacy Policy explains our practices regarding the
        collection, use or disclosure of personal data including other rights of
        the Data Subjects in accordance with the Personal Data Protection Laws.
      </p>

      <h2>Collection of Personal Data</h2>
      <p>
        We will collect your personal data that receive directly from you as
        following:
      </p>

      <ul>
        <li>Your account registration</li>
        <li>Email address</li>
        <li>Facebook Login</li>
        <li>Google Login</li>
      </ul>

      <h2>Types of Data Collected</h2>
      <ul>
        <li>Personal data such as name, surname, age, date of birth, etc.</li>
        <li>Contact information such as e-mail address, etc.</li>
        <li>
          Account details such as username, password, transactions history, etc.
        </li>
      </ul>

      <h2>Children</h2>
      <p>
        If you are under the age of 20 or having legal restrictions, we may
        collect use or disclose your personal data. We require your parents or
        guardian to be aware and provide consent to us or allowed by applicable
        laws. If we become aware that we have collected personal data from
        children without verification of parental consent, we take steps to
        remove that information from our servers.
      </p>

      <h2>Storage of Data</h2>
      <p>We store your personal data as hard copy and soft copy.</p>
      <p>We store your personal data by using the following systems:</p>
      <ul>
        <li>Third-party server service providers outside of Thailand</li>
      </ul>

      <h2>Use of Data</h2>
      <p>We use the collected data for various purposes:</p>
      <ul>
        <li>To create and manage accounts</li>
        <li>To share and manage information within organization</li>
      </ul>

      <h2>Disclosure of Personal Data</h2>
      <p>
        We may disclose your personal data to the following parties in certain
        circumstances:
      </p>

      <h2>Organization</h2>
      <p>
        We may disclose your personal data within our organization to provide
        and develop our products or services. We may combine information
        internally across the different products or services covered by this
        Privacy Policy to help us be more relevant and useful to you and others.
      </p>

      <h2>Data Retention</h2>
      <p>
        We will retain your personal data for as long as necessary during the
        period you are a customer or under relationship with us, or for as long
        as necessary in connection with the purposes set out in this Privacy
        Policy, unless law requires or permits a longer retention period. We
        will erase, destroy or anonymize your personal data when it is no longer
        necessary or when the period lapses.
      </p>

      <h2>Data Subject Rights</h2>
      <p>
        Subject to the Personal Data Protection Laws thereof, you may exercise
        any of these rights in the following:
      </p>

      <p>
        Withdrawal of consent: If you have given consent to us to collect, use
        or disclose your personal data whether before or after the effective
        date of the Personal Data Protection Laws, you have the right to
        withdraw such consent at any time throughout the period your personal
        data available to us, unless it is restricted by laws or you are still
        under beneficial contract.
      </p>
      <p>
        Data access: You have the right to access your personal data that is
        under our responsibility; to request us to make a copy of such data for
        you; and to request us to reveal as to how we obtain your personal data.
      </p>
      <p>
        Data portability: You have the right to obtain your personal data if the
        we organizes such personal data in automatic machine-readable or usable
        format and can be processed or disclosed by automatic means; to request
        us to send or transfer the personal data in such format directly to
        other data controllers if doable by automatic means; and to request to
        obtain the personal data in such format sent or transferred by us
        directly to other data controller unless not technically feasible.
      </p>
      <p>
        Objection: You have the right to object to collection, use or disclosure
        of your personal data at any time if such doing is conducted for
        legitimate interests of us, corporation or individual which is within
        your reasonable expectation; or for carrying out public tasks.
      </p>
      <p>
        Data erasure or destruction: You have the right to request us to erase,
        destroy or anonymize your personal data if you believe that the
        collection, use or disclosure of your personal data is against relevant
        laws; or retention of the data by us is no longer necessary in
        connection with related purposes under this Privacy Policy; or when you
        request to withdraw your consent or to object to the processing as
        earlier described.
      </p>
      <p>
        Suspension: You have the right to request us to suspend processing your
        personal data during the period where we examine your rectification or
        objection request; or when it is no longer necessary and we must erase
        or destroy your personal data pursuant to relevant laws but you instead
        request us to suspend the processing.
      </p>
      <p>
        Rectification: You have the right to rectify your personal data to be
        updated, complete and not misleading.
      </p>
      <p>
        Complaint lodging: You have the right to complain to competent
        authorities pursuant to relevant laws if you believe that the
        collection, use or disclosure of your personal data is violating or not
        in compliance with relevant laws.
      </p>

      <p>
        You can exercise these rights as the Data Subject by contacting our Data
        Protection Officer as mentioned below. We will notify the result of your
        request within 30 days upon receipt of such request. If we deny the
        request, we will inform you of the reason via SMS, email address,
        telephone, registered mail (if applicable).
      </p>

      <h2>Data Security</h2>
      <p>
        We endeavor to protect your personal data by establishing security
        measures in accordance with the principles of confidentiality,
        integrity, and availability to prevent loss, unauthorized or unlawful
        access, destruction, use, alteration, or disclosure including
        administrative safeguard, technical safeguard, physical safeguard and
        access controls.
      </p>

      <h2>Data Breach Notification</h2>
      <p>
        We will notify the Office of the Personal Data Protection Committee
        without delay and, where feasible, within 72 hours after having become
        aware of it, unless such personal data breach is unlikely to result in a
        risk to the rights and freedoms of you. If the personal data breach is
        likely to result in a high risk to the rights and freedoms of you, we
        will also notify the personal data breach and the remedial measures to
        you without delay through our website, SMS, email address, telephone or
        registered mail (if applicable).
      </p>

      <h2>Changes to this Privacy Policy</h2>
      <p>
        We may change this Privacy Policy from time to time. Any changes of this
        Privacy Policy, we encourage you to frequently check on our website.
        This Privacy Policy was last updated and effective on 27th July 2021
      </p>

      <h2>Links to Other Sites</h2>
      <p>
        The purpose of this Privacy Policy is to offer products or services and
        use of our website. Any websites from other domains found on our site is
        subject to their privacy policy which is not related to us.
      </p>

      <h2>Contact Information</h2>
      <p>
        If you have any questions about this Privacy Policy or would like to
        exercise your rights, you can contact us by using the following details:
      </p>

      <h5>Data Controller Office</h5>
      <p>
        Spring Movement 161/41, Wang Burapha Phirom, Phra Nakhon, Bangkok, 10200
        <br />
        <a href="mailto:springmovement@gmail.com">springmovement@gmail.com</a>
        <br />
        www.allthaiwomen.com
        <br />
        085-251-7279
      </p>
    </React.Fragment>
  );

  const activeLinkStyles: React.CSSProperties = { textDecoration: "underline" };

  return (
    <React.Fragment>
      <div className={styles.lang_toggle_container}>
        <NavLink to="/privacy/en" activeStyle={activeLinkStyles}>
          EN
        </NavLink>
        &nbsp;/&nbsp;
        <NavLink to="/privacy/th" activeStyle={activeLinkStyles}>
          TH
        </NavLink>
      </div>
      <div className={styles.privacy_policy_page}>
        <Switch>
          <Route path="/privacy/th" exact>
            {contentTH}
          </Route>
          <Route path="/privacy/en" exact>
            {contentEN}
          </Route>
        </Switch>
      </div>
    </React.Fragment>
  );
};

export default PrivacyPolicyPage;

import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import styles from "./FaqBox.module.css";

type QA = {
  question: string;
  answer: string;
};

type FAQBoxProps = {
  question: string;
  answer: string;
};

const FAQBox = (props: FAQBoxProps): JSX.Element => {
  const [showFAQ, setShowFAQ] = useState(false);

  function toggleShowFAQ() {
    setShowFAQ((showFAQ) => !showFAQ);
  }

  const answerText = props.answer.split("\n").map((str) => <p>{str}</p>);

  return (
    <React.Fragment>
      <div className={styles.faqbox_container}>
        {/* Question */}
        <div
          className={styles.faqbox_question_container}
          onClick={toggleShowFAQ}
        >
          <div>
            <h4>{props.question}</h4>
          </div>

          <div>
            {showFAQ ? (
              <FontAwesomeIcon icon={faChevronUp} />
            ) : (
              <FontAwesomeIcon icon={faChevronDown} />
            )}
          </div>
        </div>

        {/* Answer */}
        <div
          className={`
        ${styles.faqbox_answer_container}
        ${!showFAQ ? styles.faqbox_answer_container_hidden : null}
        `}
        >
          <p>{answerText}</p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FAQBox;
export type { QA };

type SizedBoxProps = {
  height?: string;
  width?: string;
}

const SizedBox = (props: SizedBoxProps): JSX.Element => {
  const sbStyle: Object = {
    height: props.height === null ? "0px" : props.height,
    width: props.width === null ? "0px" : props.width,
  }

  return (
    <div style={sbStyle}>
      {/* height and width can be percent,int,vh */}
    </div >
  );
}

export default SizedBox;
import React from "react";
import styles from "./ResetPasswordPage.module.css";
import ResetPasswordForm from "../components/access/ResetPasswordForm";

const ResetPasswordPage = (): JSX.Element => {
  return (
    <React.Fragment>
      <div className={styles.reset_password_page}>
        <ResetPasswordForm />
      </div>
    </React.Fragment>
  );
};

export default ResetPasswordPage;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";

interface HeaderState {
  showHeader: boolean
}

const initialState: HeaderState = {
  showHeader: true,
}

export const headerSlice = createSlice({
  name: "header",
  initialState,
  reducers: {
    displayHeader: (state, action: PayloadAction<boolean>) => {
      state.showHeader = action.payload;
    },
  }
})

export const { displayHeader } = headerSlice.actions;
export const selectHeader = (state: RootState) => state.header;
export default headerSlice.reducer;
import TextInput from "../ui/TextInput";
import Button from "../ui/Button";
import SizedBox from "../ui/SizedBox";
import React, { BaseSyntheticEvent, useState } from "react";
import GoogleButton from "../ui/GoogleButton";
import FacebookButton from "../ui/FacebookButton";

import styles from "./SignupForm.module.css";
import { Link } from "react-router-dom";
import Select, { OptionTypeBase } from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { Endpoint, LOCALSTORAGE_JWT } from "../../types/constant";
import { userCreateReq } from "../../types/user";
import { useHistory } from "react-router";
import { useAppDispatch } from "../../hooks";
import { displayLoadingScreen } from "../../reducers/uiSlice";

const SignupForm = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [signupStage, setSignupStage] = useState(0);
  const [newUserData, setNewUserData] = useState<userCreateReq>({
    notebook_cover: "cover1",
    notebook_pattern: "pattern1",
  });

  const [showUsernameWarning, setShowUsernameWarning] = useState(false);
  const [showEmailWarning, setShowEmailWarning] = useState(false);
  const [showPasswordWarning, setShowPasswordWarning] = useState(false);
  const [showAgreeWarning, setShowAgreeWarning] = useState(false);
  const [userAgreeTOS, setUserAgreeTOS] = useState(false);
  const [registerSuccess, setRegisterSuccess] = useState<boolean>(false);
  const [registerFailed, setRegisterFailed] = useState<boolean>(false);

  const onSubmitForm = (e: BaseSyntheticEvent) => {
    // this function is called every time the button is clicked so we won't use
    // it to create user
    e.preventDefault();

    if (formHasError()) {
      return;
    }

    setSignupStage(1);
  };

  function emailOK(email: string | undefined): boolean {
    if (email !== undefined) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }
    return false;
  }

  const usernameOK = (username: string | undefined): boolean => {
    if (username !== undefined) {
      const re = /[^A-za-z0-9_]/;
      const ok = !re.test(username);
      if (!ok) {
        return false;
      }

      if (username.length < 8) {
        return false;
      }

      return true;
    }

    return false;
  };

  const passwordOK = (password: string | undefined): boolean => {
    if (password !== undefined) {
      if (password.length < 8) {
        return false;
      }

      return true;
    }

    return false;
  };

  const formHasError = (): boolean => {
    let hasError = false;

    if (!emailOK(newUserData.email)) {
      setShowEmailWarning(true);
      hasError = true;
    }

    if (!usernameOK(newUserData.username)) {
      setShowUsernameWarning(true);
      hasError = true;
    }

    if (!passwordOK(newUserData.password)) {
      setShowPasswordWarning(true);
      hasError = true;
    }

    if (!userAgreeTOS) {
      setShowAgreeWarning(true);
      hasError = true;
    }

    return hasError;
  };

  const onCreateUser = () => {
    dispatch(displayLoadingScreen(true));

    // create user
    axios
      .post(
        Endpoint.BACKEND_URL + Endpoint.PATH_USER_LOCAL_REGISTER,
        newUserData
      )
      .then((res) => {
        if (res.status === 200) {
          localStorage.setItem(LOCALSTORAGE_JWT, res.data.jwt);
          setRegisterSuccess(true);
        }
      })
      .catch((err) => {
        console.log(err.data);
        setRegisterFailed(true);
      })
      .finally(() => {
        setSignupStage(2);
        dispatch(displayLoadingScreen(false));
      });
  };

  const selectOptions: OptionTypeBase[] = [
    { value: "female", label: "หญิง" },
    { value: "male", label: "ชาย" },
    { value: "non_binary", label: "นอนไบนารี" },
  ];

  const onUsernameChange = (e: React.FormEvent<HTMLInputElement>) => {
    const currentUsernameVal = e.currentTarget.value;
    setShowUsernameWarning(false);

    if (!usernameOK(currentUsernameVal)) {
    }

    setNewUserData({ ...newUserData, username: currentUsernameVal });
  };

  const onEmailChange = (e: React.FormEvent<HTMLInputElement>) => {
    const currentEmailVal = e.currentTarget.value;
    setShowEmailWarning(false);

    if (!emailOK(currentEmailVal)) {
      // console.log("bad email");
    }

    setNewUserData({ ...newUserData, email: currentEmailVal });
  };

  const onPasswordChange = (e: React.FormEvent<HTMLInputElement>) => {
    const currentPasswordVal = e.currentTarget.value;
    setShowPasswordWarning(false);

    if (!passwordOK(currentPasswordVal)) {
    }

    setNewUserData({ ...newUserData, password: currentPasswordVal });
  };

  const onGenderSelect = (e: OptionTypeBase | null) => {
    if (e !== null) {
      const value = e.value;
      setNewUserData({ ...newUserData, gender: value });
    }
  };

  const onBirthdaySelect = (e: React.FormEvent<HTMLInputElement>) => {
    const date: string = e.currentTarget.value;
    setNewUserData({ ...newUserData, birthdate: date });
  };

  return (
    <form className={styles["form_wrapper"]} onSubmit={onSubmitForm}>
      {/* Stage 0 */}
      {signupStage === 0 && (
        <div>
          <h1 className={styles.text_header}>SIGN UP</h1>
          <TextInput hintText="Username" onChange={onUsernameChange} />
          {showUsernameWarning && (
            <p className={styles.text_warning}>
              * username ต้องมีความยาวอย่างน้อย 8 ตัวอักษรและไม่มีอักขระพิเศษ
            </p>
          )}
          <SizedBox height="3vh" />
          <TextInput hintText="Email" onChange={onEmailChange} />
          {showEmailWarning && (
            <p className={styles.text_warning}>* email ไม่ถูกต้อง</p>
          )}
          <SizedBox height="3vh" />
          <TextInput
            hintText="Password"
            inputType="password"
            onChange={onPasswordChange}
          />
          {showPasswordWarning && (
            <p className={styles.text_warning}>
              * password ต้องมีความยาวอย่างน้อย 8 ตัวอักษร
            </p>
          )}
          <SizedBox height="3vh" />
          <SizedBox height="5vh" />
          <div className={styles["term_container"]}>
            <div className={styles["term_container--checkbox"]}>
              <input
                type="checkbox"
                className={styles.checkbox_big}
                onClick={() => {
                  setUserAgreeTOS((state) => !state);
                  setShowAgreeWarning(false);
                }}
                checked={userAgreeTOS}
              />
            </div>
            <div className={styles["term_container--termtext"]}>
              By signing up you agree to our
              <Link to="/tos/th" target="_blank">
                Terms&Service
              </Link>
              &nbsp;and&nbsp;
              <Link to="/privacy" target="_blank">
                Privacy Policy
              </Link>
            </div>
          </div>
          {showAgreeWarning && (
            <p className={styles.text_warning}>
              * กรุณากดยอมรับเงื่อนไขเพื่อสมัครสมาชิก
            </p>
          )}
          <SizedBox height="3vh" />
          <Button backgroundColor="#76C3C0" text="Sign up" width="100%" />
          <SizedBox height="2vh" />
          <p>OR</p>
          <a href="https://svc.allthaiwomen.com/connect/google">
            <GoogleButton disabled={false} />
          </a>

          {/* Facebook Login */}
          <a href="https://svc.allthaiwomen.com/connect/facebook">
            <FacebookButton disabled={false} />
          </a>
          <p className={`${styles["text_narrowline"]}`}>
            Already have an account?
          </p>
          <Link
            to="/login"
            className={`${styles.text_grey} ${styles.text_underlined}`}
          >
            Sign in
          </Link>
        </div>
      )}
      {/* Stage 1 */}
      {signupStage === 1 && (
        <div className={styles.stage_container}>
          <h1 className={styles.text_header}>
            YOU'RE
            <br />
            ALMOST
            <br />
            THERE
          </h1>
          <p>เพศ (* ไม่จำเป็น)</p>
          <Select
            onChange={onGenderSelect}
            styles={{
              option: (provided, state) => ({
                ...provided,
                borderBottom: "1px dotted pink",
                color: state.isSelected ? "red" : "blue",
              }),
              control: (provided) => ({
                ...provided,
                marginBottom: 20,
              }),
              singleValue: (provided, state) => {
                const opacity = state.isDisabled ? 0.5 : 1;
                const transition = "opacity 300ms";

                return { ...provided, opacity, transition };
              },
            }}
            options={selectOptions}
          />

          <p>วัน/เดือน/ปีเกิด (* ไม่จำเป็น)</p>
          <input
            onChange={onBirthdaySelect}
            type="date"
            className={styles.input_date}
            max="2015-12-31"
          />
          <br />

          <div className={styles.submit_form_button_container}>
            <Button
              onClick={onCreateUser}
              text="done"
              backgroundColor="#76c3c0"
              width="30%"
            />
          </div>

          <p
            onClick={() => {
              setSignupStage(0);
            }}
            className={styles.back_icon}
          >
            <FontAwesomeIcon color="#F13D57" icon={faChevronLeft} /> ย้อนกลับ
          </p>
        </div>
      )}

      {signupStage === 2 && (
        <div className={styles.stage_container}>
          {registerSuccess && (
            <React.Fragment>
              <h5 style={{ color: "green" }}>สำเร็จ !</h5>
              <p>
                กรุณาตรวจสอบกล่องข้อความที่อีเมลของคุณ เพื่อยืนยันการตัวตน
                หากไม่พบในกล่องข้อความ ให้ตรวจสอบใน junk mail
              </p>
              <div>
                <Button
                  text="กลับสู่หน้าหลัก"
                  backgroundColor="#f13d57"
                  width="100%"
                  onClick={() => {
                    history.push("/home");
                  }}
                />
              </div>
            </React.Fragment>
          )}
          {registerFailed && (
            <React.Fragment>
              <h5 style={{ color: "red" }}>เกิดบางอย่างผิดพลาด !</h5>
              <p>ไม่สามารถสร้างบัญชีผู้ใช้ได้ อาจะเกิดขึ้นเนื่องจาก</p>
              <ul>
                <li>username หรือ email มีผู้ใช้งานแล้ว</li>
                <li>ไม่สามารถเชื่อมต่อ server ได้</li>
              </ul>
              <div>
                <Button
                  text="กลับสู่หน้าหลัก"
                  backgroundColor="#f13d57"
                  width="100%"
                  onClick={() => {
                    history.push("/home");
                  }}
                />
              </div>
            </React.Fragment>
          )}
        </div>
      )}
    </form>
  );
};

export default SignupForm;

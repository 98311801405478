import React from "react";
import styles from "./TextInput.module.css";
import { useState } from "react";

import EyeOpenedPNG from "./../../assets/icons/eye-opened.png";
import EyeClosedPNG from "./../../assets/icons/eye-closed.png";

type TextInputProps = {
  hintText?: string;
  value?: string;
  inputType?: string;
  disabled?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
};

const TextInput = (props: TextInputProps): JSX.Element => {
  const [eyeOpen, setEyeOpen] = useState(false);

  function toggleEye() {
    setEyeOpen(!eyeOpen);
  }

  return (
    <React.Fragment>
      <input
        disabled={props.disabled !== undefined ? props.disabled : false}
        className={styles["text_input"]}
        type={props.inputType === "password" && !eyeOpen ? "password" : "text"}
        value={props.value}
        placeholder={props.hintText}
        onChange={props.onChange}
      />

      {props.inputType === "password" && (
        <img
          className={styles.text_input_icon}
          onClick={toggleEye}
          alt="input field icon"
          src={eyeOpen ? EyeOpenedPNG : EyeClosedPNG}
        />
      )}
    </React.Fragment>
  );
};

export default TextInput;

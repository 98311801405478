import React from "react";
import FAQBox from "../components/faq/FaqBox";
import { QA } from "../components/faq/FaqBox";
import SizedBox from "../components/ui/SizedBox";
import styles from "./FAQPage.module.css";

const qas: QA[] = [
  {
    question: "All Thai Women คืออะไร",
    answer:
      "All Thai Women คือโครงการร่วมกันของกลุ่ม Spring Movement และ Democracy Restoration Group (DRG) ทำหน้าที่เป็นพื้นที่รวบรวมปัญหา แบ่งปันเรื่องราว และให้กำลังใจกันของผู้หญิงไทยในสังคมปิตาธิปไตย เราต้องการรวบรวมเสียงของผู้หญิงไทย ที่ต้องเผชิญกับปัญหาต่าง ๆ เช่น การกดขี่ อคติ หรือการเลือกปฏิบัติทางเพศในมิติต่าง ๆ เพื่อสื่อสารกันระหว่างผู้หญิงไทยว่าพวกเราไม่ได้โดดเดี่ยว และเป็นการสื่อสารต่อสังคมว่าปัญหาของผู้หญิงที่เกิดจากเหตุแห่งเพศนั้นมีอยู่จริง",
  },
  {
    question: "เว็บไซต์ All Thai Women ใช้งานอย่างไร",
    answer:
      "ผู้ใช้งานสามารถเขียนเล่าเรื่องราวของตนเองเพื่อเผยแพร่ในเว็บไซต์ได้ และอ่านเรื่องราวต่าง ๆ ได้โดยเข้าดูสถานที่ในแผนที่ หรือเลือกดูตามหมวดหมู่ของเรื่อง โดยสามารถส่งสติกเกอร์ให้กับแต่ละเรื่องราวเพื่อเป็นกำลังใจให้กับผู้เล่าเรื่องได้ และผู้ใช้งานสามารถเข้าดูสมุดของตนเองเพื่ออ่านเรื่องราวที่ตนเองเขียน ดูสติ๊กเกอร์ที่ตนเองได้รับ และแชร์เรื่องราวของตนเองได้",
  },
  {
    question: "เล่าเรื่องอะไรได้บ้าง",
    answer:
      "สามารถเล่าเรื่องราวใดก็ได้ที่แสดงถึงการเป็นผู้หญิงไทยในสังคมปิตาธิปไตย เช่น ปัญหาหรือเหตุการณ์ต่าง ๆ ที่พบเจอเนื่องจากการเป็นผู้หญิง โดยสามารถเล่าตามสถานที่ต่าง ๆ ในแผนที่ เช่น ที่อยู่อาศัย สถานศึกษา พื้นที่สาธารณะ ฯลฯ",
  },
  {
    question: "ใครสามารถเล่าเรื่องใน All Thai Women ได้บ้าง",
    answer:
      "ผู้หญิงไทยทุกคนสามารถเล่าเรื่องใน All Thai Women ได้ ซึ่งแน่นอนว่ารวมถึงผู้หญิงข้ามเพศด้วยเช่นกัน",
  },
  {
    question: "อยากเล่าเรื่องโดยไม่เปิดเผยตัวตนได้หรือไม่",
    answer:
      "ได้ ผู้เขียนสามารถเลือกไม่เปิดเผยตัวตนได้โดยเลือกช่องไม่ต้องการแสดงชื่อผู้เขียนก่อนเผยแพร่เรื่องราว",
  },
  {
    question: "ทำไมไม่มี All Thai Men",
    answer:
      "สังคมปิตาธิปไตยส่งผลกระทบต่อทุกเพศ เพศชายก็ประสบปัญหาจากสังคมปิตาธิปไตยเช่นเดียวกัน เราไม่ได้ต้องการละเลยปัญหาของเพศชาย แต่ปฏิเสธไม่ได้ว่าผู้หญิงจำนวนมากถูกปฏิเสธว่าปัญหาที่พวกเธอเผชิญในสังคมปิตาธิปไตยไม่มีอยู่จริง พวกเธอคิดไปเอง เป็นเรื่องเล็กๆ ฯลฯ เราจึงต้องการมอบพื้นที่เล็กๆ นี้ให้กับผู้หญิงไทย ให้เรื่องราวหรือปัญหาของผู้หญิงไทยเป็นได้รับการมองเห็น โดยเราขอให้พื้นที่เว็บไซต์ All Thai Women ให้เป็นพื้นที่สำหรับกลุ่มผู้หญิงไทยเท่านั้น ขณะเดียวกัน ผู้ชายก็สามารถสนับสนุนประเด็นที่เกี่ยวข้องกับผู้หญิง และสร้างพื้นที่ปลอดภัยให้ผู้หญิงแลกเปลี่ยนปัญหาได้ผ่านการอ่านและแชร์เรื่องราวของพวกเธอ",
  },
  {
    question: "สามารถติดต่อผู้จัดทำเว็บไซต์ได้อย่างไรบ้าง",
    answer:
      "หากเกิดปัญหาในการใช้งานเว็บไซต์ หรือมีข้อเสนอแนะใด ๆ สามารถติดต่อผู้จัดทำเว็บไซต์ได้ผ่านเพจเฟซบุ๊ก Spring Movement ",
  },
  {
    question: "ฉันต้องทำอย่างไรเมื่อฉันต้องการลบบัญชีผู้ใช้งานของฉัน",
    answer: `หากคุณต้องการลบบัญชีผู้ใช้ของตนเอง สามารถทำได้ดังนี้\n
1. เข้าสู่ระบบด้วยอีเมลและรหัสผ่าน\n
2. คลิกที่ชื่อผู้ใช้ของคุณและเลือก แก้ไขข้อมูลส่วนตัว\n
3. ไปยังบริเวณ "Danger Zone" และกดปุ่ม "ลบบัญชีผู้ใช้"\n
4. กรอกอีเมลเพื่อยืนยัน และกดปุ่มยืนยัน\n
(หมายเหตุ: เมื่อลบบัญชีผู้ใช้งานแล้วจะไม่สามารถกู้ข้อมูลกลับคืนมาได้อีก)`,
  },
];

const FAQPage = (): JSX.Element => {
  return (
    <React.Fragment>
      <div className={styles.faq_page}>
        <h2 className={styles.title_text}>คำถามที่พบบ่อย</h2>

        <div className={styles.faq_container}>
          {/* QA lists */}
          {qas.map((qa, index) => {
            return (
              <React.Fragment key={index}>
                <SizedBox height="20px" />
                <FAQBox question={qa.question} answer={qa.answer} />
              </React.Fragment>
            );
          })}
        </div>

        <SizedBox height="30px" />

        <div className={styles.contact_container}>
          หากมีข้อสงสัยเพิ่มเติม
          <br />
          ติดต่อ{" "}
          <a href="https://www.facebook.com/SpringMovementTH">
            Spring Movement Page
          </a>
        </div>

        <SizedBox height="100px" />
      </div>
    </React.Fragment>
  );
};

export default FAQPage;

import React from "react";
import { useAppSelector } from "../../hooks";
import styles from "./UserNavDesktop.module.css";
import { NavLink } from "react-router-dom";

const UserNavDesktop = (): JSX.Element => {
  const username = useAppSelector(state => state.user.currentUser.username);

  const userNavItemActive: React.CSSProperties = {
    textDecoration: "none",
    color: "#fff",
    textShadow: "2px 2px 5px red"
  }

  return (<React.Fragment>
    <div className={styles.user_nav_desktop}>
      <ul className={styles.list_item_container}>
        <li className={styles.list_item_first}>Hi, {username}</li>
        <li>
          <NavLink
            activeStyle={userNavItemActive}
            className={styles.usernav_item} to={`/user/${username}/info`}>
            แก้ไขข้อมูลส่วนตัว
          </NavLink>
        </li>
        <li>
          <NavLink
            activeStyle={userNavItemActive}
            className={styles.usernav_item} to={`/user/${username}/notebook`}>
            สมุดของคุณ
          </NavLink>

        </li>
        <li>
          <NavLink
            activeStyle={userNavItemActive}
            className={styles.usernav_item} to={`/user/${username}/posts`}>
            <div>โพสต์ของคุณ</div>
          </NavLink>
        </li>
      </ul>
    </div>
  </React.Fragment>)
}

export default UserNavDesktop;
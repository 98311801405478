import axios from "axios";
import React, { useState, useMemo } from "react";
import Select from "react-select";
import Button from "../components/ui/Button";
import { Endpoint, LOCALSTORAGE_JWT } from "../types/constant";
import styles from "./UserInfoPage.module.css";
import { user, userUpdateReq } from "../types/user";
import { useEffect } from "react";
import { useAppDispatch } from "../hooks";
import { displayLoadingScreen } from "../reducers/uiSlice";
import { displayHeader } from "../reducers/headerSlice";
import TextInput from "../components/ui/TextInput";
import { useHistory } from "react-router";

type userInfoPageProps = {
  currentUser: user | undefined;
  setCurrentUser: React.Dispatch<React.SetStateAction<user | undefined>>;
};

// ! ATTENTION
// TODO: Must apply isOwner policy to update user. Right now, user can update other's data

const UserInfoPage = (props: userInfoPageProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const genderOptions = useMemo(() => {
    return [
      { label: "หญิง", value: "female" },
      { label: "ชาย", value: "male" },
      { label: "นอนไบนารี", value: "non_binary" },
    ];
  }, []);

  const jwt = localStorage.getItem(LOCALSTORAGE_JWT);
  const [dGender, setDGender] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const [isDeleteUser, setIsDeleteUser] = useState<boolean>(false);

  // select gender
  useEffect(() => {
    if (props.currentUser?.gender === "female") {
      setDGender(genderOptions[0]);
    } else if (props.currentUser?.gender === "male") {
      setDGender(genderOptions[1]);
    } else if (props.currentUser?.gender === "non_binary") {
      setDGender(genderOptions[2]);
    }

    return () => {};
  }, [genderOptions, props.currentUser]);

  const onChangeGender = (e: any) => {
    setDGender(e);
    props.setCurrentUser({ ...props.currentUser, gender: e.value });
  };

  const onClickDeleteUserButton = (e: any) => {
    e.preventDefault(); // prevent form submission
    setIsDeleteUser(true);
  };

  const onSubmitForm = (e: React.FormEvent) => {
    e.preventDefault();

    dispatch(displayLoadingScreen(true));

    const data: userUpdateReq = {
      username: props.currentUser!.username!,
      email: props.currentUser!.email!,
      notebook_cover: props.currentUser!.notebook_cover!,
      notebook_pattern: props.currentUser!.notebook_pattern!,
      birthdate: props.currentUser!.birthdate!,
      gender: props.currentUser!.gender!,
    };

    axios
      .put(
        `${Endpoint.BACKEND_URL}${
          Endpoint.PATH_STRAPI_USERS_PERMISSIONS_CUSTOM_UPDATE
        }/${props.currentUser!.id}`,
        data,
        { headers: { Authorization: `Bearer ${jwt}` } }
      )
      .then((res) => {
        // console.log(res);

        dispatch(displayLoadingScreen(false));

        // refreshing header
        dispatch(displayHeader(false));
        dispatch(displayHeader(true));
      })
      .catch((err) => {
        console.log(err);
        alert("An error occurred! Please try again.");
        dispatch(displayLoadingScreen(false));
      });
  };

  return (
    <React.Fragment>
      <form onSubmit={onSubmitForm}>
        <div className={styles.userinfopage_container}>
          <h3 className={`${styles.text_centered}`}>แก้ไขข้อมูลส่วนตัว</h3>

          <p>ชื่อผู้ใช้งาน</p>
          <input
            onChange={(e) =>
              props.setCurrentUser({
                ...props.currentUser,
                username: e.currentTarget.value,
              })
            }
            className={styles.full_width}
            value={props.currentUser?.username}
            type="text"
          />

          <p>อีเมล</p>
          <input
            onChange={(e) =>
              props.setCurrentUser({
                ...props.currentUser,
                email: e.currentTarget.value,
              })
            }
            className={styles.full_width}
            value={props.currentUser?.email}
            type="text"
          />

          <p>เพศ</p>
          <Select
            onChange={onChangeGender}
            options={genderOptions}
            value={dGender}
            styles={{
              control: (provided) => ({
                ...provided,
                marginBottom: "20px",
              }),
            }}
          />

          <p>วัน/เดือน/ปีเกิด</p>
          <input
            max="2015-12-31"
            onChange={(e) =>
              props.setCurrentUser({
                ...props.currentUser,
                birthdate: e.currentTarget.value,
              })
            }
            className={styles.full_width}
            value={props.currentUser?.birthdate}
            type="date"
          />

          <Button width="100%" backgroundColor="#76C3C0" text="ยืนยัน" />

          {/* Danger Zone */}
          <div className={styles.dangerzone_container}>
            <h3 className={styles.text_danger}>Danger Zone</h3>
            <Button
              text="ลบบัญชีผู้ใช้"
              backgroundColor="white"
              textColor="red"
              width="40%"
              onClick={onClickDeleteUserButton}
            />

            {isDeleteUser && (
              <UserDeleteConfirm
                userId={props.currentUser?.id}
                email={props.currentUser?.email}
              />
            )}
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};

// --- Helper Props

interface UserDeleteConfirmProps {
  email?: string;
  userId?: number;
}

const UserDeleteConfirm = (props: UserDeleteConfirmProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [isEmailMatch, setIsEmailMatch] = useState<boolean>(false);
  const jwt = localStorage.getItem(LOCALSTORAGE_JWT);

  const onChangeEmail = (e: any) => {
    if (props.email === e.target.value) {
      setIsEmailMatch(true);
      return;
    }
    setIsEmailMatch(false);
  };

  const onSubmitDeleteUser = (e: any) => {
    e.preventDefault(); // prevent form submission

    dispatch(displayLoadingScreen(true));

    axios
      .delete(
        `${Endpoint.BACKEND_URL}${Endpoint.PATH_STRAPI_USERS_PERMISSIONS_CUSTOM_DELETE}/${props.userId}`,
        {
          headers: { Authorization: `Bearer ${jwt}` },
        }
      )
      .then((res) => {
        console.log(res);

        // refreshing header
        dispatch(displayHeader(false));
        dispatch(displayHeader(true));
      })
      .catch((err) => {
        console.log(err);
        alert("An error occurred! Please try again.");
      });

    // delete JWT Tokens from local storage
    localStorage.removeItem(LOCALSTORAGE_JWT);

    // redirect back to home
    history.replace("/home");

    dispatch(displayLoadingScreen(false));
  };

  return (
    <React.Fragment>
      <div
        className={`${styles.confirm_delete_container} ${styles.text_danger}`}
      >
        <p className={styles.text_danger}>
          การลบบัญชีผู้ใช้จะไม่สามารถกู้คืนข้อมูลกลับมาได้ กรุณากรอก{" "}
          <strong>{props.email}</strong> เพื่อเป็นการยืนยันการลบบัญชี
        </p>
        <TextInput hintText="กรุณากรอกอีเมลของคุณ" onChange={onChangeEmail} />
        {isEmailMatch && (
          <Button
            text="ยืนยัน"
            backgroundColor="red"
            textColor="white"
            width="100px"
            onClick={onSubmitDeleteUser}
          />
        )}
        {/* Else Hack! */}
        {!isEmailMatch && (
          <Button
            text="ยืนยัน"
            backgroundColor="grey"
            textColor="white"
            width="100px"
            onClick={(e) => {
              e.preventDefault();
            }}
          />
        )}
      </div>
    </React.Fragment>
  );
};

// --- Exports

export default UserInfoPage;

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import 'bootstrap/dist/css/bootstrap.min.css';

import './assets/fonts/Mitr/Mitr-Regular.ttf';
import './assets/fonts/Bai_Jamjuree/BaiJamjuree-Regular.ttf';
import './assets/fonts/BreakFill/break_regular_fill.ttf';
import './assets/fonts/Crimson_Pro/static/CrimsonPro-Regular.ttf';
import './assets/fonts/Montserrat/Montserrat-Regular.ttf';
import './assets/fonts/Prompt/Prompt-Regular.ttf';
import { Provider } from 'react-redux';
import store from './store';

ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <App />
  </Provider>,
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

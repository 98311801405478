import React from "react";
import SignupForm from "../components/access/SignupForm";
import styles from "./SignupPage.module.css";

type SignupPageProps = {
  children?: React.ReactNode,
}

const SignupPage = (props?: SignupPageProps): JSX.Element => {
  return (
    <React.Fragment>
      {/* Backdrop container */}
      <div className={styles.signup_page}>
        <div className={styles.signup_form_container} >
          <SignupForm />
        </div>
      </div>
    </React.Fragment>
  );
}

export default SignupPage;
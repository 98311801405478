import React from "react";
import Button from "../components/ui/Button";
import styles from "./NotFoundPage.module.css";
import Flower from "./../assets/icons/stickers/flower.png";
import { useHistory } from "react-router";

const NotFoundPage = (): JSX.Element => {
  const history = useHistory();

  return (<React.Fragment>
    <div className={styles.page_not_found}>
      <div className={styles.page_not_found_container}>
        <div className={styles.fof_container}>
          <h1>404</h1>
          <h2>Page Not Found</h2>
        </div>

        <div className={styles.break_line} />

        <div className={styles.desc_container}>
          <p>ไม่พบผลการค้นหา<br />สำหรับหน้าที่คุณต้องการ</p>
          <img src={Flower} alt="flower" />
          <Button
            onClick={() => { history.push("/home") }}
            text="กลับสู่แผนที่"
            width="80%"
            backgroundColor="#F13D57" />
        </div>
      </div>
    </div>
  </React.Fragment>)
}

export default NotFoundPage;
import { BrowserRouter, Switch, Route } from "react-router-dom";

import "./App.css";
import FAQPage from "./pages/FAQPage";
import FeedPage from "./pages/FeedPage";
import Header from "./components/layout/Header";
import MapPage from "./pages/MapPage";
import Layout from "./components/layout/Layout";
import LandingPage from "./pages/LandingPage";
import LoadingPage from "./pages/LoadingPage";
import LoginPage from "./pages/LoginPage";
import SignupPage from "./pages/SignupPage";
import SignupCompletePage from "./pages/SignupCompletePage";
import Footer from "./components/layout/Footer";
import Construction from "./components/maintenance/Construction";

import React, { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "./hooks";
import { displayHeader } from "./reducers/headerSlice";
import HomePage from "./pages/HomePage";
import NewStoryPage from "./pages/NewStoryPage";
import LoginRedirect from "./pages/LoginRedirect";
import UserPage from "./pages/UserPage";
import TermsCondPage from "./pages/TermsCondPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import Modal from "./components/ui/Modal";
import YikesPage from "./pages/YikesPage";
import EditStoryPage from "./pages/EditStoryPage";
import NotFoundPage from "./pages/NotFoundPage";
import StoryDetailPage from "./pages/StoryDetailPage";
import SharePage from "./pages/SharePage";
import CategoryFeedPage from "./pages/CategoryFeedPage";
import ReportPage from "./pages/ReportPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";

const isUnderconstruction: boolean = false;

function App() {
  let appTitle = "Altered";

  const isShowHeader: boolean = useAppSelector(
    (state) => state.header.showHeader
  );
  const isLoading: boolean = useAppSelector(
    (state) => state.ui.showLoadingScreen
  );
  const isShowModal: boolean = useAppSelector((state) => state.ui.showModal);
  const dispatch = useAppDispatch();
  const [showNotSupportedPlatform, setShowNotSupportedPlatform] =
    useState(false);

  useEffect(() => {
    const screnSize = window.screen.width;
    if (screnSize > 425) {
      setShowNotSupportedPlatform(true);
    }
    return () => {};
  }, []);

  if (document.location.pathname === "/") {
    dispatch(displayHeader(false));
  }

  return (
    <div className="App">
      {false ? (
        <YikesPage
          title="How do we get here..."
          description="Yikes! Our app is not currently supported on your platform. Please use web browser on your mobile phone."
        />
      ) : (
        <React.Fragment>
          {/* <BetaTag /> */}

          {isLoading && <LoadingPage />}

          {isShowModal && <Modal />}

          {isUnderconstruction ? (
            <Construction />
          ) : (
            <BrowserRouter>
              <Layout>
                {isShowHeader && (
                  <Header useDefaultTitle={true} title={appTitle} />
                )}

                {/* Routing */}
                <Switch>
                  <Route path="/" exact>
                    <LandingPage />
                  </Route>
                  <Route path="/home" exact>
                    <HomePage />
                  </Route>
                  <Route path="/login">
                    <LoginPage />
                  </Route>
                  <Route path="/forgot-password">
                    <ForgotPasswordPage />
                  </Route>
                  <Route path="/reset-password">
                    <ResetPasswordPage />
                  </Route>
                  <Route path="/signup">
                    <SignupPage />
                  </Route>
                  <Route path="/signupcomplete">
                    <SignupCompletePage />
                  </Route>
                  <Route path="/faq">
                    <FAQPage />
                    <Footer />
                  </Route>
                  <Route path="/s">
                    <FeedPage />
                  </Route>
                  <Route path="/map" exact>
                    <MapPage />
                  </Route>
                  <Route path="/user/:username">
                    <UserPage />
                  </Route>
                  <Route path="/new-story">
                    <NewStoryPage />
                  </Route>
                  <Route path="/edit-story/:storyId">
                    <EditStoryPage />
                  </Route>
                  <Route path="/connect/:provider">
                    <LoginRedirect />
                  </Route>
                  <Route path="/tos">
                    <TermsCondPage />
                  </Route>
                  <Route path="/privacy">
                    <PrivacyPolicyPage />
                  </Route>
                  <Route path="/share">
                    <SharePage />
                  </Route>
                  <Route path="/c">
                    <CategoryFeedPage />
                  </Route>
                  <Route path="/report">
                    <ReportPage />
                  </Route>
                  <Route path="*" exact>
                    <NotFoundPage />
                  </Route>
                </Switch>

                {/* Read one story from /s Path */}
                <Route path="/s/:storyId">
                  <div
                    id="story-detail-modal"
                    style={{
                      zIndex: 0,
                      width: "100%",
                      height: "100%",
                      top: 0,
                      left: 0,
                      position: "fixed",
                      overflowY: "scroll",
                    }}
                  >
                    <StoryDetailPage />
                  </div>
                </Route>

                {/* Read one story from /c Path */}
                <Route path="/c/:storyId">
                  <div
                    id="story-detail-modal"
                    style={{
                      zIndex: 0,
                      width: "100%",
                      height: "100%",
                      top: 0,
                      left: 0,
                      position: "fixed",
                      overflowY: "scroll",
                    }}
                  >
                    <StoryDetailPage />
                  </div>
                </Route>
              </Layout>
            </BrowserRouter>
          )}
        </React.Fragment>
      )}
    </div>
  );
}

export default App;

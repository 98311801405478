import React from "react";
import { NavLink, Route, Switch } from "react-router-dom";

import styles from "./TermsCondPage.module.css";

const TermsCondPage = (): JSX.Element => {
  const contentEN = (
    <div className={styles.tos_page}>
      <h1>Terms and Conditions</h1>
      <p>
        Agreement between service provider and service user allthaiwomen.com
        website (which from now on will be called “website”) is developed by
        Spring Movement (which from now on will be called “Provider”) to provide
        services related to the All Thai Women campaign. User’s use of website
        will be under these terms and conditions. User, therefore, should read
        this terms and conditions, If user doesn’t accept the terms and
        conditions of this website, please proceed to stop using services on
        allthaiwomen website.{" "}
      </p>

      <p>
        &nbsp;&nbsp;1. Changes to the website’s terms and conditions The
        Provider reserves the right to modify or change any terms and conditions
        stated on this website. You have the duty to constantly check the
        website’s terms and conditions, including any additional terms on the
        website. User’s constant use of the website inevitably forms agreements
        and contracts in accordance with any and all terms and conditions which
        has been modified or changed.
      </p>

      <p>
        &nbsp;&nbsp;2. Use and disclosure of personal information The Provider
        has a private policy in which the Provider will not disclose your
        personal information to the public. You can continue to read this in
        “Private Policy””
      </p>

      <p>
        &nbsp;&nbsp;3. Connecting to other websites The Provider may arrange
        connections to other websites. This is for information and for your
        convenience only. The provider connecting to these websites is
        inevitably at your own risk. In no case shall the Provider be liable for
        any damages suffered by the User in connection with the information,
        goods or services provided or offered on third-party websites connected
        to this website.
      </p>

      <p>
        &nbsp;&nbsp;4. Intellectual Property www.allthaiwomen.com, database,
        content on our website related to the service, except information and
        content created by users, belong to Spring Movement. The website’s
        source code and software belongs to Siraphob Kanjanasansong. Other
        content that belongs to Spring Movement: logos, visual graphics,
        stickers are permitted to be used, replicated, distributed, or adjusted.
        However, you must give credit to Spring Movement and cannot use our
        content for commercial purposes.
      </p>

      <p>
        &nbsp;&nbsp;5. Account You may need to create an account to use some of
        our Services. You are responsible for safeguarding your password and
        every action performed under your account and password.
      </p>

      <p>
        &nbsp;&nbsp;6. Limitations You acknowledge and agree that some of the
        content on this website is written or created by you. Your content is
        not related to the service provider and is beyond our control. Spring
        Movement makes no warranty or representation and disclaim all
        responsibility and liability for (i) any consequential damages,
        prosecutions, torts in civil laws or computer crime, or any legal
        damages (ii) any harm to your computer system, loss of profits or
        revenues that result from the use of our services, which belong to you
        or to any third party whether it is a legal binding, violation of
        criminal liability, or other kind
      </p>

      <p>
        &nbsp;&nbsp;7. Governing law and jurisdiction If any dispute that
        results from the use of our services occurs, this condition shall be
        construed in accordance with, and governed by the laws of Thailand in
        order to settle the dispute. You acknowledge and agree to the terms and
        conditions on this website. Any use of the services to the site after
        the amendment of these Web Terms will be considered as acceptance of the
        amended Web Terms by you.
      </p>

      <p>
        If you have any questions or concerns about these Web Terms or any
        issues raised in these Web Terms or on the Site, please contact us at
        <a href="mailto:allthaiwomen2021@gmail.com">
          {" "}
          allthaiwomen2021@gmail.com
        </a>
        .
      </p>
    </div>
  );

  const contentTH = (
    <div className={styles.tos_page}>
      <h1>ข้อกำหนดและเงื่อนไข</h1>
      <p>
        ข้อตกลงระหว่างผู้ให้บริการกับผู้ใช้บริการ เว็บไซต์ allthaiwomen.com
        (ซึ่งต่อไปนี้จะเรียกว่า “เว็บไซต์”) จัดทำขึ้นโดย Spring Movement
        (ซึ่งต่อไปนี้จะเรียกว่า “ผู้ให้บริการ”) เพื่อให้บริการเกี่ยวกับแคมเปญ
        allthaiwomen
        การใช้เว็บไซต์ของผู้ใช้บริการจะอยู่ภายใต้เงื่อนไขและข้อตกลงนี้
        ดังนั้นผู้ใช้บริการจึงควรศึกษาเงื่อนไขและข้อตกลงฉบับนี้
        หากผู้ใช้บริการไม่ยอมรับเงื่อนไข และข้อกำหนดการใช้งานเว็บไซต์นี้
        โปรดหยุดใช้บริการที่อยู่ใน เว็บไซต์ allthaiwomen
      </p>

      <p>
        &nbsp;&nbsp;1. การเปลี่ยนแปลงแก้ไขเงื่อนไขและข้อกำหนดการใช้งาน
        เว็บไซต์นี้
        ผู้ให้บริการขอสงวนสิทธิที่จะแก้ไขหรือเปลี่ยนแปลงข้อกำหนดและเงื่อนไขใดๆ
        ที่ระบุอยู่ในเว็บไซต์นี้
        คุณมีหน้าที่ที่จะต้องคอยตรวจสอบข้อกำหนดเงื่อนไขการใช้งานเว็บไซต์รวมทั้งข้อกำหนดเพิ่มเติมใดๆ
        ที่กำหนดอยู่ในเว็บไซต์อย่างสม่ำเสมอ การใช้เว็บไซต์ของผู้ใช้บริการ
        อย่างต่อเนื่องย่อมก่อให้เกิดข้อตกลงและสัญญาตามข้อกำหนดและเงื่อนไขใดๆ
        ที่ได้ถูกแก้ไขหรือเปลี่ยนแปลงดังกล่าวทั้งหมดด้วย
      </p>

      <p>
        &nbsp;&nbsp;2. การใช้และการเปิดเผยข้อมูลส่วนบุคคล
        ผู้ให้บริการมีข้อกำหนดด้านนโยบายความเป็นส่วนตัว (Privacy Policy)
        โดยผู้ให้บริการจะไม่เปิดเผยข้อมูลส่วนตัวของคุณให้แก่สาธารณะ
        คุณสามารถศึกษาต่อได้ในข้อ “นโยบายความเป็นส่วนตัว”
      </p>

      <p>
        &nbsp;&nbsp;3. การเชื่อมต่อกับเว็บไซต์อื่นๆ
        ผู้ให้บริการอาจจัดการให้มีการเชื่อมต่อกับเว็บไซต์อื่นๆ
        ทั้งนี้เพื่อเป็นการให้ข้อมูลและเพื่อความสะดวกของคุณเท่านั้น
        การที่ผู้ให้บริการเชื่อมต่อกับเว็บไซต์เหล่านั้นย่อมเป็นความเสี่ยงของคุณแต่เพียงผู้เดียวไม่ว่ากรณี
        ใดๆ ก็ตาม
        ผู้ให้บริการจะไม่รับผิดสำหรับความเสียหายที่ผู้ใช้บริการได้รับอันเนื่องมาจากข้อมูล
        สินค้า
        หรือบริการที่กำหนดหรือเสนอบนเว็บไซต์ของบุคคลที่สามที่เชื่อมต่อกับเว็บไซต์นี้
      </p>

      <p>
        &nbsp;&nbsp;4. ทรัพย์สินทางปัญญา เว้นแต่จะระบุไว้เป็นประการอื่น เว็บไซต์
        allthaiwomen.com ฐานข้อมูล
        ข้อมูลและเนื้อหาทั้งหมดบนเว็บไซต์ที่เกี่ยวข้องกับการให้บริการแคมเปญ
        allthaiwomen.com เป็นทรัพย์สินของ Spring Movement
        ยกเว้นข้อมูลและเนื้อหาที่เขียนขึ้นโดยผู้ใช้บริการ
        รหัสต้นฉบับสำหรับภาษาโปรแกรมทั้งหมด ซอฟต์แวร์ เป็นกรรมสิทธิ์ของสิรภพ
        กาญจนแสนส่ง สำหรับโลโก้ กราฟฟิก สติ๊กเกอร์
        อนุญาตให้ผู้อื่นสามารถนำผลงานไปใช้ ทำซ้ำ แจกจ่าย หรือดัดแปลงงานนั้นได้
        แต่ต้องให้เครดิตที่มาของ Spring Movement และและต้องไม่ใช่เพื่อการค้า
      </p>

      <p>
        &nbsp;&nbsp;5. บัญชี คุณอาจต้องทำการลงทะเบียนเพื่อเข้าใช้งานเว็บไซต์นี้
        คุณยอมรับที่จะเก็บรักษารหัสผ่านของคุณไว้เป็นความลับและรับผิดชอบต่อการใช้งานทั้งหมดที่เกิดขึ้นภายใต้บัญชีและรหัสผ่านของคุณ
      </p>

      <p>
        &nbsp;&nbsp;6. การจำกัดความรับผิด คุณตกลงและยอมรับว่า
        บรรดาข้อมูลบางส่วนที่ปรากฏในเว็บไซต์นี้ข้อความดังกล่าวเป็นข้อความที่เขียนหรือทำขึ้นโดยคุณ
        ซึ่งไม่เกี่ยวข้อง และอยู่เหนือความควบคุมของผู้ให้บริการ
        ผู้ให้บริการไม่มีความรับผิดใดๆ ที่เกิดจากเนื้อหาหรือข้อความดังกล่าว
        ไม่ว่าจะเป็นความเสียหายการฟ้องร้อง เรียกร้อง หรือการกระทำละเมิดใดๆไม่ว่า
        ในทางแพ่งหรือทางอาญาอาชญากรรมทางคอมพิวเตอร์หรือความรับผิดใดๆ
        ในทางกฎหมายซึ่งมีผลกระทบต่ออุปกรณ์ คอมพิวเตอร์ทรัพย์สิน สิทธิหรือ
        ทรัพย์สินใดๆ ที่เกี่ยวข้องกับคุณหรือบุคคลภายนอก
        ไม่ว่าจะโดยความผูกพันตามสัญญา ละเมิด ความรับผิดทางอาญา หรือโดยประการอื่น
      </p>

      <p>
        &nbsp;&nbsp;7. กฎหมายที่ใช้บังคับ หากเกิดข้อพิพาทใดๆ
        ที่เกิดจากการให้บริการของผู้ให้บริการ อันเนื่องมาจากเว็บไซต์นี้
        คุณและผู้ให้บริการตกลงใช้กฎหมายไทยในการระงับข้อพิพาทการใช้บริการในเว็บไซต์นี้ของคุณ
        ถือเป็นการตกลงและยอมรับข้อกำหนดและเงื่อนไขของเว็บไซต์ฉบับนี้และการที่คุณใช้บริการภายหลังจากการมีการแก้ไขเพิ่มเติม
        ข้อกำหนดและเงื่อนไขของเว็บไซต์ฉบับนี้ของผู้ให้บริการในอนาคตย่อมถือว่าคุณยอมรับข้อกำหนดและเงื่อนไขของเว็บไซต์ของผู้ให้บริการที่ได้มีการแก้ไขเพิ่มเติมดังกล่าว
      </p>

      <p>
        ข้อมูลการติดต่อผู้ให้บริการเว็บไซต์:
        <a href="mailto:allthaiwomen2021@gmail.com">
          allthaiwomen2021@gmail.com
        </a>
      </p>
    </div>
  );

  const activeLinkStyles: React.CSSProperties = { textDecoration: "underline" };

  return (
    <React.Fragment>
      <div className={styles.lang_toggle_container}>
        <NavLink to="/tos/en" activeStyle={activeLinkStyles}>
          EN
        </NavLink>
        &nbsp;/&nbsp;
        <NavLink to="/tos/th" activeStyle={activeLinkStyles}>
          TH
        </NavLink>
      </div>

      <Switch>
        <Route path="/tos/th" exact>
          {contentTH}
        </Route>
        <Route path="/tos/en" exact>
          {contentEN}
        </Route>
      </Switch>
    </React.Fragment>
  );
};

export default TermsCondPage;

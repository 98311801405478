/**
 * 
 * @param input - an array of string
 * @param delimiter - a delimiter to place between each element of the given input array
 * @returns a string from the given string array delimited by the given delimiter
 */
const getDelimitedString = (input: string[], delimiter: string): string => {
  let res: string = ""
  for (let i = 0; i < input.length; i++) {
    res += input[i] + delimiter
  }
  return res.slice(0, res.length - delimiter.length)
}

const getLimitedString = (input: string, limit: number, addTrailingDots: boolean = false): string => {
  if (input.length <= limit) return input

  let res = input.slice(0, limit)
  if (addTrailingDots) {
    return res + "..."
  }
  return res
}

const iso8601ToPrettyDate = (input: string): string => {
  const months = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน",
    "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"]

  input = input.slice(0, 10)

  const [y, m, d] = input.split("-")


  return `${d} ${months[parseInt(m) - 1]} ${parseInt(y) + 543}`
}


export {
  getDelimitedString,
  getLimitedString,
  iso8601ToPrettyDate
}



import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { user } from "../types/user";

interface UserState {
  currentUser: user,
  userIsAuthenticated: boolean,
  isReading: boolean,
}

const initialState: UserState = {
  currentUser: {},
  userIsAuthenticated: false,
  isReading: false,
}

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setCurrentUser: (state, action: PayloadAction<user>) => {
      state.currentUser = action.payload;
    },
    setUserIsAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.userIsAuthenticated = action.payload;
    },
    setIsReading: (state, action: PayloadAction<boolean>) => {
      state.isReading = action.payload;
    },
  }
})

export const { setCurrentUser, setUserIsAuthenticated, setIsReading } = userSlice.actions;
export const selectUser = (state: RootState) => state.user;
export default userSlice.reducer;
import LocBusSVG from "./../assets/icons/locations/bus.svg";
import LocGymSVG from "./../assets/icons/locations/gym.svg";
import LocHomeSVG from "./../assets/icons/locations/home.svg";
import LocMallSVG from "./../assets/icons/locations/mall.svg";
import LocMarketSVG from "./../assets/icons/locations/market.svg";
import LocOfficeSVG from "./../assets/icons/locations/office.svg";
import LocOtherSVG from "./../assets/icons/locations/others.svg";
import LocParkSVG from "./../assets/icons/locations/park.svg";
import LocPhoneSVG from "./../assets/icons/locations/phone.svg";
import LocPubSVG from "./../assets/icons/locations/pub.svg";
import LocReligiousSVG from "./../assets/icons/locations/religious_place.svg";
import LocRestaurantSVG from "./../assets/icons/locations/restaurant.svg";
import LocSchoolSVG from "./../assets/icons/locations/school.svg";
import LocStreetSVG from "./../assets/icons/locations/street.svg";
import LocUniversitySVG from "./../assets/icons/locations/university.svg";

const LOCALSTORAGE_JWT: string = "jwt";
const LOCALSTORAGE_USERNAME: string = "username";
const SESSIONSTORAGE_SELECTED_CATEGORY_IDS =
  "SESSIONSTORAGE_SELECTED_CATEGORY_IDS";

class Endpoint {
  static BACKEND_URL: string = "https://svc.allthaiwomen.com";
  static PATH_USER_LOCAL_REGISTER: string = "/auth/local/register";
  static PATH_USER_LOCAL_LOGIN: string = "/auth/local";
  static PATH_USER_ME: string = "/users/me";
  static PATH_USER: string = "/users";
  static PATH_STRAPI_USERS_PERMISSIONS_CUSTOM_UPDATE = "/users-permissions/customUpdate";
  static PATH_STRAPI_USERS_PERMISSIONS_CUSTOM_DELETE = "/users-permissions/customDelete";

  static PATH_STORY: string = "/stories";
  static PATH_STORY_RANDOM: string = "/stories-random";

  static PATH_CATEGORY: string = "/categories";

  static PATH_USER_STORY_STICKER = "/user-story-stickers";
  static PATH_USER_STORY_STICKER_DELETE = "/user-story-stickers-delete";
}

const PlaceByString = new Map<string, number>([
  ["school", 1],
  ["university", 2],
  ["home", 3],
  ["workplace", 4],
  ["religious_place", 5],
  ["entertainment_venue", 6],
  ["outdoor", 7],
  ["public_transportation", 8],
  ["mall", 9],
  ["market", 10],
  ["restaurant", 11],
  ["park", 12],
  ["social_media", 13],
  ["gym", 14],
  ["foot_path", 15],
  ["other", 16],
]);

const TriggerWarningByString = new Map<string, number>([
  ["abusive_relationship", 1],
  ["domestic_violence", 2],
  ["verbal_violence", 3],
  ["physical_violence", 4],
  ["psychological_violence", 5],
  ["death", 6],
  ["bullying", 7],
  ["sexual_harassment", 8],
  ["racism", 9],
  ["sexism", 10],
  ["classism", 11],
  ["homophobia", 12],
  ["transphobia", 13],
  ["drug_use", 14],
  ["alcohol_use", 15],
  ["suicide", 16],
  ["self_harm", 17],
  ["pregnancy", 18],
  ["abortion", 19],
  ["mental_illness", 20],
  ["eating_disorders", 21],
  ["pedophilia", 22],
]);

const CategoryByString = new Map<string, number>([
  ["gender_inequality", 1],
  ["gender_discrimination", 2],
  ["harassment", 4],
  ["sexual_objectification", 5],
  ["misogyny", 6],
  ["abusive_relationship", 7],
  ["sexual_violence", 8],
  ["domestic_violence", 9],
  ["mansplaining", 10],
  ["beauty_standard", 11],
  ["feminine_burden", 12],
  ["reproductive_rights", 13],
  ["autonomy", 14],
  ["access_to_resources", 15],
  ["other", 16],
]);

const StickerIdByString = new Map<string, number>([
  ["coffee", 1],
  ["queen", 2],
  ["crown", 3],
  ["metoo", 4],
  ["flower", 5],
  ["goodjob", 6],
  ["pplhug", 7],
  ["cactushug", 8],
  ["rainbow", 9],
  ["music", 10],
  ["star", 11],
  ["greenheart", 12],
  ["pinkheart", 13],
  ["redheart", 14],
  ["yellowheart", 15],
  ["pearfect", 16],
  ["fist", 17],
]);

const placeIconByString = new Map([
  ["public_transportation", LocBusSVG],
  ["gym", LocGymSVG],
  ["home", LocHomeSVG],
  ["mall", LocMallSVG],
  ["market", LocMarketSVG],
  ["office", LocOfficeSVG],
  ["other", LocOtherSVG],
  ["park", LocParkSVG],
  ["social_media", LocPhoneSVG],
  ["entertainment_venue", LocPubSVG],
  ["religious", LocReligiousSVG],
  ["restaurant", LocRestaurantSVG],
  ["school", LocSchoolSVG],
  ["foot_path", LocStreetSVG],
  ["university", LocUniversitySVG],
]);

export {
  LOCALSTORAGE_JWT,
  LOCALSTORAGE_USERNAME,
  SESSIONSTORAGE_SELECTED_CATEGORY_IDS,
  Endpoint,
  PlaceByString,
  TriggerWarningByString,
  CategoryByString,
  StickerIdByString,
  placeIconByString,
};

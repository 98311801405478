import React from "react";
import LoginForm from "../components/access/LoginForm";
import styles from "./SignupPage.module.css";

const LoginPage = (): JSX.Element => {
  return (
    <React.Fragment>
      <div className={styles.login_page}>
        <div className={styles.login_form_container}  >
          <LoginForm />
        </div>
      </div>

    </React.Fragment>
  );
}

export default LoginPage;
import React from "react"
import { useParams } from "react-router"
import NewStoryForm from "../components/story/NewStoryForm"
import styles from "./NewStoryPage.module.css"

type editStoryRouteParams = {
  storyId: string
}

const EditStoryPage = (): JSX.Element => {
  const { storyId } = useParams<editStoryRouteParams>();

  return (
    <React.Fragment>
      <div className={styles.new_story_page_container}>
        <NewStoryForm storyId={storyId} />
      </div>
    </React.Fragment>
  )
}

export default EditStoryPage;
import { faBook, faChevronDown, faChevronUp, faPen, faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { useState } from "react";
import { useHistory } from "react-router";
import { useAppDispatch } from "../hooks";
import { displayHeader } from "../reducers/headerSlice";
import { displayLoadingScreen } from "../reducers/uiSlice";
import { setIsReading } from "../reducers/userSlice";
import { Story, story } from "../types/story";
import styles from "./UserPostsPage.module.css";

type UserPostsPageProps = {
  stories?: story[],
  setStories: React.Dispatch<React.SetStateAction<story[] | undefined>>,
}

const UserPostsPage = (props: UserPostsPageProps): JSX.Element => {
  const [toggleStates, setToggleStates] = useState<boolean[]>(new Array(props.stories?.length).fill(false));
  const dispatch = useAppDispatch();
  const history = useHistory();

  const onTogglePost = (postIdx: number) => {
    let _toggleStates = [...toggleStates]; // make a shallow copy
    _toggleStates[postIdx] = !_toggleStates[postIdx];
    setToggleStates(_toggleStates);
  }

  const onDeleteStory = (storyID: number) => {
    Story.delete(storyID)
      .then(res => {
        dispatch(displayLoadingScreen(true));

        const _stories: story[] = [];
        props.stories?.forEach((story) => {
          if (story.id !== storyID) {
            _stories.push(story);
          }
        })

        props.setStories(_stories);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        // set all stories to fold
        let _toggleStates = [];
        for (const _ of toggleStates) {
          _toggleStates.push(false);
        }
        setToggleStates([..._toggleStates]);

        dispatch(displayLoadingScreen(false));
      })
  }

  const onGoEditPage = (storyId: number) => {
    history.push(`/edit-story/${storyId}`)
  }

  const onGoReadPage = (storyId: number) => {
    // TODO: Should I add a place to the story url?

    history.push(`/s/${storyId}`, { from: "internal" })
    dispatch(displayHeader(false));
    dispatch(setIsReading(true));
  }

  return (
    <React.Fragment>
      <div className={styles.user_posts_container}>
        <h3>โพสต์ทั้งหมดของคุณ</h3>
        <table>
          <thead>
            <tr className={styles.table_row_head}>
              <th>ชื่อเรื่อง</th>
              <th>สถานที่</th>
              <th>วันที่โพสต์</th>
            </tr>
          </thead>
          <tbody>
            {
              props.stories?.map((story, idx) => {
                return (
                  <React.Fragment key={story.id}>

                    <tr className={styles.table_row}>
                      <td>{story.title}</td>
                      {/* <td>{story.title.length > 15 ?
                        story.title.slice(0, 15) + "..." :
                        story.title
                      }</td> */}
                      <td>{story.place?.title_th}</td>
                      <td>{story.published_at?.toString().slice(0, 10)}</td>
                      {
                        toggleStates[idx] ?
                          <td onClick={() => onTogglePost(idx)}><FontAwesomeIcon icon={faChevronUp} /></td>
                          :
                          <td onClick={() => onTogglePost(idx)}><FontAwesomeIcon icon={faChevronDown} /></td>
                      }
                    </tr>

                    {/* Dropdown */}
                    {
                      toggleStates[idx] &&
                      <React.Fragment>
                        <tr className={styles.table_row} >
                          <td colSpan={4} onClick={() => onGoReadPage(story.id)}>
                            <FontAwesomeIcon icon={faBook} color="teal" /> อ่านเรื่องนี้
                          </td>
                        </tr>
                        <tr className={styles.table_row} >
                          <td colSpan={4} onClick={() => onGoEditPage(story.id)}>
                            <FontAwesomeIcon icon={faPen} color="teal" /> แก้ไขโพสต์
                          </td>
                        </tr>
                        <tr className={styles.table_row} >
                          <td colSpan={4} onClick={() => onDeleteStory(story.id)}>
                            <FontAwesomeIcon icon={faTrash} color="red" /> ลบโพสต์
                          </td>
                        </tr>
                      </React.Fragment>
                    }

                  </React.Fragment>
                )
              })
            }
          </tbody>
        </table>
      </div>
    </React.Fragment>
  )
}

export default UserPostsPage;
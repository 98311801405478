import React from "react";
import styles from "./StoryThumbnail.module.css";
import { getLimitedString } from "../../utils/string";

import { useAppDispatch } from "../../hooks";
import { displayHeader } from "../../reducers/headerSlice";
import { triggerWarning } from "../../types/triggerWarning";
import { setIsReading } from "../../reducers/userSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { faHeart as farHeart } from "@fortawesome/free-regular-svg-icons";

const kContentCharacterLimit: number = 70;
const kTitleCharacterLimit: number = 20;

type storyThumbnailProps = {
  id: number;
  title: string;
  triggerWarnings: triggerWarning[];
  contentPreview: string;
  author_alias?: string;
  sticker_count?: number;
  sitcker_pressed: boolean | undefined;

  goToStory?: Function;
};

const StoryThumbnail = (props: storyThumbnailProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const contentPreviewText: string = getLimitedString(
    props.contentPreview,
    kContentCharacterLimit,
    true
  );
  let triggerWarningText: string = "";
  props.triggerWarnings.forEach((tw) => {
    triggerWarningText += tw.title_th + ", ";
  });

  const titlePreviewText: string = getLimitedString(
    props.title,
    kTitleCharacterLimit,
    true
  );

  function clickStoryHandler() {
    // redirect to the story reading page
    if (props.goToStory !== undefined) {
      dispatch(displayHeader(false));
      dispatch(setIsReading(true));

      props.goToStory();
    }
  }

  return (
    <React.Fragment>
      <div onClick={clickStoryHandler} className={styles.thumbnail_container}>
        {/* Title */}
        <div className={styles.thumbnail_title_container}>
          <h4 className={styles.title_text}>{titlePreviewText}</h4>
        </div>

        {/* Content */}
        <div className={styles.thumbnail_content_container}>
          <h5 className={styles.trigger_warning_text}>
            TW: {triggerWarningText.slice(0, triggerWarningText.length - 2)}
          </h5>
          <p className={styles.content_text}>{contentPreviewText}</p>

          <div className={styles.thumbnail_sticker_bar}>
            <p className={styles.author_alias_text}>{props.author_alias}</p>

            <div className={styles.sticker_counter}>
              <span style={{ fontSize: ".8em" }}>{props.sticker_count}</span>
              {props.sitcker_pressed ? (
                <FontAwesomeIcon icon={faHeart} />
              ) : (
                <FontAwesomeIcon icon={farHeart} />
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default StoryThumbnail;

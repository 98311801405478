import styles from "./FacebookButton.module.css"

type FacebookButtonProps = {
  disabled: boolean
}

const FacebookButton = (props: FacebookButtonProps): JSX.Element => {
  return (
    <button
      type="button"
      className={styles["login-with-facebook-btn"]}
      disabled={props.disabled}>
      Log in with Facebook
    </button>
  )
}

export default FacebookButton;

// import mapFull from "../assets/images/map_full.svg"
import mapAnimateGIF from "../assets/images/map_animate.gif";
import styles from "./MapPage.module.css";
import Button from "../components/ui/Button";
import { Link } from "react-router-dom";

// location icons
import bus1PNG from "./../assets/icons/map_locations/bus_1.png";
import school1PNG from "./../assets/icons/map_locations/school_1.png";
import uni1PNG from "./../assets/icons/map_locations/uni_1.png";
import home1PNG from "./../assets/icons/map_locations/home_1.png";
import market1PNG from "./../assets/icons/map_locations/market_1.png";
import mall1PNG from "./../assets/icons/map_locations/mall_1.png";
import restaurant1PNG from "./../assets/icons/map_locations/restaurant_1.png";
import park1PNG from "./../assets/icons/map_locations/park_1.png";
import street1PNG from "./../assets/icons/map_locations/street_1.png";
import religious1PNG from "./../assets/icons/map_locations/religious_1.png";
import office1PNG from "./../assets/icons/map_locations/office_1.png";
import gym1PNG from "./../assets/icons/map_locations/gym_1.png";
import others1PNG from "./../assets/icons/map_locations/others_1.png";
import social1PNG from "./../assets/icons/map_locations/phone_1.png";
import pub1PNG from "./../assets/icons/map_locations/pub_1.png";

// book icons
import nbCover1PNG from "./../assets/images/notebook/cover/cov1.png";
import { useHistory } from "react-router";
import { useAppSelector } from "../hooks";
import AnimatedArrow from "../components/ui/AnimatedArrow";

const MapPage = (): JSX.Element => {
  const history = useHistory();
  const username = useAppSelector((state) => state.user.currentUser.username);

  const goToNewStoryPage = (username: string | undefined) => {
    if (username !== undefined) {
      history.push(`/new-story`);
      return;
    }

    history.push(`/login`);
  };

  const onNavigateToFeed = (path: string) => {
    history.push(`/s?place=${path}`);
  };

  return (
    <div className={styles.homepage_container}>
      {/* Map Container*/}
      <div className={styles.map_container}>
        <img
          id="map-page-map"
          className={styles.map}
          src={mapAnimateGIF}
          alt="map full"
        />
        {/* <img id="map-page-map" className={styles.map} src={mapAnimateGIF2} alt="map full" /> */}

        <div
          onClick={() => {
            onNavigateToFeed("public_transportation");
          }}
          className={`${styles["icon_location_container"]} ${styles["icon_bus_container"]}`}
        >
          <img src={bus1PNG} alt="bus" />
          <p>ขนส่งสาธารณะ</p>
        </div>

        <div
          onClick={() => {
            onNavigateToFeed("school");
          }}
          className={`${styles["icon_location_container"]} ${styles["icon_school_container"]}`}
        >
          <img src={school1PNG} alt="school" />
          <p>โรงเรียน</p>
        </div>

        <div
          onClick={() => {
            onNavigateToFeed("university");
          }}
          className={`${styles["icon_location_container"]} ${styles["icon_uni_container"]}`}
        >
          <img src={uni1PNG} alt="uni" />
          <p>มหาวิทยาลัย</p>
        </div>

        <div
          onClick={() => {
            onNavigateToFeed("home");
          }}
          className={`${styles["icon_location_container"]} ${styles["icon_home_container"]}`}
        >
          <img src={home1PNG} alt="home" />
          <p>ที่อยู่อาศัย</p>
        </div>

        <div
          onClick={() => {
            onNavigateToFeed("market");
          }}
          className={`${styles["icon_location_container"]} ${styles["icon_market_container"]}`}
        >
          <img src={market1PNG} alt="market" />
          <p>ตลาด</p>
        </div>

        <div
          onClick={() => {
            onNavigateToFeed("mall");
          }}
          className={`${styles["icon_location_container"]} ${styles["icon_mall_container"]}`}
        >
          <img src={mall1PNG} alt="mall" />
          <p>ห้างสรรพสินค้า</p>
        </div>

        <div
          onClick={() => {
            onNavigateToFeed("restaurant");
          }}
          className={`${styles["icon_location_container"]} ${styles["icon_restaurant_container"]}`}
        >
          <img src={restaurant1PNG} alt="restaurant" />
          <p>ร้านอาหาร</p>
        </div>

        <div
          onClick={() => {
            onNavigateToFeed("park");
          }}
          className={`${styles["icon_location_container"]} ${styles["icon_park_container"]}`}
        >
          <img src={park1PNG} alt="park" />
          <p>สวนสาธารณะ</p>
        </div>

        <div
          onClick={() => {
            onNavigateToFeed("foot_path");
          }}
          className={`${styles["icon_location_container"]} ${styles["icon_street_container"]}`}
        >
          <img src={street1PNG} alt="foot_path" />
          <p>ริมถนน</p>
        </div>

        <div
          onClick={() => {
            onNavigateToFeed("religious");
          }}
          className={`${styles["icon_location_container"]} ${styles["icon_religious_container"]}`}
        >
          <img src={religious1PNG} alt="religious_place" />
          <p>ศาสนสถาน</p>
        </div>

        <div
          onClick={() => {
            onNavigateToFeed("workplace");
          }}
          className={`${styles["icon_location_container"]} ${styles["icon_office_container"]}`}
        >
          <img src={office1PNG} alt="office" />
          <p>ที่ทำงาน</p>
        </div>

        <div
          onClick={() => {
            onNavigateToFeed("gym");
          }}
          className={`${styles["icon_location_container"]} ${styles["icon_gym_container"]}`}
        >
          <img src={gym1PNG} alt="gym" />
          <p>สถานที่ออกกำลังกาย</p>
        </div>

        <div
          onClick={() => {
            onNavigateToFeed("other");
          }}
          className={`${styles["icon_location_container"]} ${styles["icon_others_container"]}`}
        >
          <img src={others1PNG} alt="other" />
          <p>อื่น ๆ</p>
        </div>

        <div
          onClick={() => {
            onNavigateToFeed("social_media");
          }}
          className={`${styles["icon_location_container"]} ${styles["icon_social_container"]}`}
        >
          <img src={social1PNG} alt="social media" />
          <p>Social Media</p>
        </div>

        <div
          onClick={() => {
            onNavigateToFeed("entertainment_venue");
          }}
          className={`${styles["icon_location_container"]} ${styles["icon_pub_container"]}`}
        >
          <img src={pub1PNG} alt="pub" />
          <p>สถานบันเทิง</p>
        </div>
      </div>

      {username !== undefined && (
        <Link to={`/user/${username}/notebook`}>
          <div className={`${styles["icon_notebook_container"]}`}>
            <img src={nbCover1PNG} alt="notebook" />
          </div>
        </Link>
      )}

      {/* Navigation */}
      <div className={styles.map_navigation_container}>
        <Button
          onClick={() => {
            goToNewStoryPage(username);
          }}
          padding="0px 20px 0 20px"
          backgroundColor="#f13d57"
          text="เขียนเรื่องราวของคุณ"
        />
      </div>
    </div>
  );
};

export default MapPage;

import React from "react";
import styles from "./Footer.module.css";
import DrgSpringPNG from "./../../assets/icons/spring-drg-logo.png";
import { Link, NavLink, useHistory } from "react-router-dom";
import facebookPNG from "./../../assets/icons/facebook-round.png";
import instagramPNG from "./../../assets/icons/instagram-round.png";
import twitterPNG from "./../../assets/icons/twitter-round.png";

const Footer = (): JSX.Element => {
  return (
    <React.Fragment>
      <div className={styles.footer}>
        <div className={`${styles.col} ${styles.content_container}`}>
          <div className={styles.logo_container}>
            <h5>ติดตาม Spring Movement</h5>
            <div className={styles.social_icon_container}>
              <Link
                to={{ pathname: "https://www.facebook.com/SpringMovementTH/" }}
                target="_blank"
              >
                <img src={facebookPNG} alt="fb" />
              </Link>
              <Link
                to={{
                  pathname:
                    "https://instagram.com/springmovement.th?utm_medium=copy_link",
                }}
                target="_blank"
              >
                <img src={instagramPNG} alt="ig" />
              </Link>
              <Link
                to={{ pathname: "https://twitter.com/SpringMovement_?s=09" }}
                target="_blank"
              >
                <img src={twitterPNG} alt="tw" />
              </Link>
            </div>

            <br />
            <div className={styles.made_by_container}>
              <h5>จัดทำโดย</h5>
              <div className={styles.drg_container}>
                <img src={DrgSpringPNG} alt="footer logo" />
                <p>
                  Spring Movement
                  <br />
                  DRG
                </p>
              </div>
            </div>
          </div>

          <div className={styles.about_container}>
            <h5>About</h5>

            <NavLink to="/tos/th" target="_blank">
              <p>Terms & Conditions</p>
            </NavLink>
            <NavLink to="/privacy/th" target="_blank">
              <p>Privacy & Policy</p>
            </NavLink>
          </div>
        </div>
        <div className={`${styles.row} ${styles.copyrighted}`}>
          <p>Copyright © 2021. All Rights Reserved.</p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Footer;

import React from "react";
import Button from "../ui/Button";
import styles from "./StickerConfirmModal.module.css";

import cactusHugPNG from "./../../assets/icons/stickers/cactushug.png";
import coffeePNG from "./../../assets/icons/stickers/coffee.png";
import crownPNG from "./../../assets/icons/stickers/crown.png";
import fistPNG from "./../../assets/icons/stickers/fist.png";
import flowerPNG from "./../../assets/icons/stickers/flower.png";
import goodjobPNG from "./../../assets/icons/stickers/goodjob.png";
import greenheartPNG from "./../../assets/icons/stickers/greenheart.png";
import metooPNG from "./../../assets/icons/stickers/metoo.png";
import musicPNG from "./../../assets/icons/stickers/music.png";
import pinkheartPNG from "./../../assets/icons/stickers/pinkheart.png";
import pplhugPNG from "./../../assets/icons/stickers/pplhug.png";
import queenPNG from "./../../assets/icons/stickers/queen.png";
import rainbowPNG from "./../../assets/icons/stickers/rainbow.png";
import redheartPNG from "./../../assets/icons/stickers/redheart.png";
import starPNG from "./../../assets/icons/stickers/star.png";
import yellowheartPNG from "./../../assets/icons/stickers/yellowheart.png";
import pearfectPNG from "./../../assets/icons/stickers/pearfect.png";
import checkedPNG from "./../../assets/icons/checked.png";
import { useEffect } from "react";
import { useState } from "react";

type stickerConfirmModalProps = {
  stickerId: number;
  onConfirm: Function;
  onCancel: Function;
};

const stickerImgById = new Map<number, string>([
  [1, coffeePNG],
  [2, queenPNG],
  [3, crownPNG],
  [4, metooPNG],
  [5, flowerPNG],
  [6, goodjobPNG],
  [7, pplhugPNG],
  [8, cactusHugPNG],
  [9, rainbowPNG],
  [10, musicPNG],
  [11, starPNG],
  [12, greenheartPNG],
  [13, pinkheartPNG],
  [14, redheartPNG],
  [15, yellowheartPNG],
  [16, pearfectPNG],
  [17, fistPNG],
]);

const stickerNameById = new Map<number, string>([
  [1, "ซื้อกาแฟให้นะ"],
  [2, "You're the queen!"],
  [3, "มอบมง"],
  [4, "กอด ๆ นะ"],
  [5, "ให้กำลังใจ"],
  [6, "เก่งมากที่ผ่านมาได้"],
  [7, "A hug from me"],
  [8, "ไม่เป็นไรนะ"],
  [9, "ฟ้าหลังฝน"],
  [10, "อยากส่งเพลงให้เธอสบายใจ"],
  [11, "เก่งมาก เอาดาวไปหนึ่งดวง"],
  [12, "หัวใจ"],
  [13, "หัวใจเหมือนกันแต่สีชมพู"],
  [14, "หัวใจเหมือนกันแต่สีแดง"],
  [15, "หัวใจเหมือนกันแต่สีเหลือง"],
  [16, "You're Pearfect!"],
  [17, "สู้ไปด้วยกัน"],
]);

const StickerConfirmModal = (props: stickerConfirmModalProps): JSX.Element => {
  const [stickerImg, setStickerImg] = useState<string>("");
  const [stickerName, setStickerName] = useState<string>("");
  const [stage, setStage] = useState<number>(0);

  useEffect(() => {
    const stickerImgPath = stickerImgById.get(props.stickerId);
    setStickerImg(stickerImgPath!);

    const _stickerName = stickerNameById.get(props.stickerId);
    setStickerName(_stickerName!);

    return () => {};
  }, [props.stickerId]);

  const onConfirm = () => {
    // increment stage if stage is 0
    if (stage === 0) {
      setStage(1);
      return;
    }

    // otherwise, execute confirm props
    props.onConfirm();
  };

  return (
    <React.Fragment>
      <div className={styles.modal_container}>
        {stage === 0 ? (
          <React.Fragment>
            <h3 className={styles.header_text}>
              กดยืนยันเพื่อส่ง
              <br />
              สติ๊กเกอร์ให้ผู้เขียน
            </h3>
            <p>{stickerName}</p>
            <img src={stickerImg} alt="selected-sticker" />

            <div className={styles.button_container}>
              <Button
                onClick={onConfirm}
                text="ยืนยัน"
                backgroundColor="#68B6B4"
                width="40%"
              />
              <Button
                onClick={() => {
                  props.onCancel();
                }}
                text="ยกเลิก"
                backgroundColor="#F13D57"
                width="40%"
              />
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <h3 className={styles.header_text}>ส่งสติ๊กเกอร์เรียบร้อย</h3>
            <img src={checkedPNG} alt="selected-sticker" />

            <div className={styles.button_container}>
              <Button
                onClick={onConfirm}
                text="กลับสู่หน้าเดิม"
                backgroundColor="#68B6B4"
                width="80%"
              />
            </div>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

export default StickerConfirmModal;

import React from "react";

type LayoutProps = {
  children?: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }): JSX.Element => {
  return (
    <div style={{
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      backgroundPosition: "center",
    }}>
      {children}
    </div>
  )
}

export default Layout;
import { faCheck, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router";
import { Endpoint } from "../../types/constant";
import Button from "../ui/Button";
import styles from "./ReportForm.module.css";
import ErrorPNG from "./../../assets/icons/error.png";

const ReportForm = (): JSX.Element => {
  const [selectedReportType, setSelectedReportType] = useState<string>("");
  const [stage, setStage] = useState<number>(0);
  const [reportedStoryId, setReportedStoryId] = useState<number>(0);
  const history = useHistory();

  useEffect(() => {
    const state = history.location.state as { storyId: string } | undefined;
    if (state === undefined) {
      history.replace("/home");
      return;
    }
    if (state.storyId === undefined) {
      history.replace("/home");
      return;
    }
    const storyId: number = parseInt(state?.storyId);
    setReportedStoryId(storyId);

    return () => {};
  }, []);

  const submitReport = (type: string, storyId: number) => {
    axios
      .post(`${Endpoint.BACKEND_URL}/reports`, {
        story: storyId,
        type: type,
      })
      .then((res) => {
        console.log(res);

        setStage(1);
        setSelectedReportType(type);
      })
      .catch((err) => {
        console.log(err);
        setStage(2);
      })
      .finally();

    console.log(storyId);

    setSelectedReportType(type);
  };

  const reportList = (
    <ul className={styles.list_container}>
      <li
        className={styles.list_item}
        onClick={() => {
          submitReport("hatespeech", reportedStoryId);
        }}
      >
        Hate speech
      </li>
      <li
        className={styles.list_item}
        onClick={() => {
          submitReport("spam", reportedStoryId);
        }}
      >
        Spam
      </li>
      <li
        className={styles.list_item}
        onClick={() => {
          submitReport("other", reportedStoryId);
        }}
      >
        อื่น ๆ
      </li>
    </ul>
  );

  return (
    <React.Fragment>
      <div className={styles.header}>
        <span
          onClick={() => {
            history.goBack();
          }}
        >
          <FontAwesomeIcon icon={faChevronLeft} color="#F13D57" />
          &nbsp;รายงานปัญหา
        </span>
      </div>

      <div className={styles.report_form}>
        {stage === 0 && (
          <React.Fragment>
            <h1>รายงานเรื่องนี้</h1>
            <h3>โปรดระบุปัญหาที่คุณพบ</h3>
            {reportList}
          </React.Fragment>
        )}
        {stage === 1 && (
          <React.Fragment>
            <h1>รายงานเรื่องนี้สำเร็จ</h1>
            <h3>ปัญหาที่คุณรายงานคือ</h3>

            <p className={styles.selected_type}>
              <FontAwesomeIcon icon={faCheck} color="#F13D57" />{" "}
              {selectedReportType}
            </p>

            <p>
              ขอบคุณสำหรับการรายงาน <br />
              หากพบปัญหาเกี่ยวกับเรื่องดังกล่าว
              <br />
              เราจะเร่งดำเนินการทันที
            </p>

            <div className={styles.button_container}>
              <Button
                onClick={() => {
                  history.replace("/home");
                }}
                text="กลับสู่หน้าหลัก"
                backgroundColor="#68B6B4"
                padding="10px"
              />
            </div>
          </React.Fragment>
        )}
        {stage === 2 && (
          <React.Fragment>
            <h1>เกิดบางอย่างผิดพลาด</h1>
            <h3>กรุณาลองอีกครั้ง</h3>
            <div className={styles.button_container}>
              <img className={styles.error_image} src={ErrorPNG} alt="error" />
              <br />
              <Button
                onClick={() => {
                  history.replace("/home");
                }}
                text="กลับสู่หน้าหลัก"
                backgroundColor="#68B6B4"
                padding="10px"
              />
            </div>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

export default ReportForm;

import React from "react"
import { NavLink, useParams } from "react-router-dom";

import styles from "./UserNav.module.css";

type userNavRouteParams = {
  username: string
}

const UserNav = (): JSX.Element => {
  const userNavItemActive: React.CSSProperties = {
    backgroundColor: "#8596A6",
    textDecoration: "none",
    color: "#fff"
  }

  const { username } = useParams<userNavRouteParams>();

  return (
    <React.Fragment>
      <div className={styles.usernav_container}>
        <NavLink
          activeStyle={userNavItemActive}
          className={styles.usernav_item} to={`/user/${username}/info`}>
          <div >ข้อมูลส่วนตัว</div>
        </NavLink>
        <NavLink
          activeStyle={userNavItemActive}
          className={styles.usernav_item} to={`/user/${username}/notebook`}>
          <div>สมุดของคุณ</div>
        </NavLink>
        <NavLink
          activeStyle={userNavItemActive}
          className={styles.usernav_item} to={`/user/${username}/posts`}>
          <div>โพสต์ของคุณ</div>
        </NavLink>
      </div>
    </React.Fragment>
  )
}

export default UserNav;
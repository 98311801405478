import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";

interface UIState {
  showLoadingScreen: boolean,
  showModal: boolean,
  modalInfo: { title: string, description: string },
}

const initialState: UIState = {
  showLoadingScreen: false,
  showModal: false,
  modalInfo: { title: "คำเตือน", description: "มีบางอย่างผิดพลาด" }
}

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    displayLoadingScreen: (state, action: PayloadAction<boolean>) => {
      state.showLoadingScreen = action.payload;
    },
    displayModal: (state, action: PayloadAction<boolean>) => {
      state.showModal = action.payload;
    },
    setModalInfo: (state, action: PayloadAction<{ title: string, description: string }>) => {
      state.modalInfo = action.payload;
    }
  }
})

export const { displayLoadingScreen, displayModal, setModalInfo } = uiSlice.actions;
export const selectUI = (state: RootState) => state.ui;
export default uiSlice.reducer
import React from "react";
import ForgotPasswordForm from "../components/access/ForgotPasswordForm";
import styles from "./ForgotPasswordPage.module.css";

const ForgotPasswordPage = (): JSX.Element => {
  return (
    <React.Fragment>
      <div className={styles.forgot_password_page}>
        <ForgotPasswordForm />
      </div>
    </React.Fragment>
  );
};

export default ForgotPasswordPage;

import React from "react";
import ReportForm from "../components/report/ReportForm";
import styles from "./ReportPage.module.css";

const ReportPage = (): JSX.Element => {
  return (
    <React.Fragment>
      <div className={styles.report_page}>
        <ReportForm />
      </div>
    </React.Fragment>
  );
};

export default ReportPage;

import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import Select, { OptionsType } from "react-select";
import Button from "../ui/Button";
import styles from "./NewStoryForm.module.css";
import cactusHugPNG from "./../../assets/icons/stickers/cactushug.png";
import sadPotatoPNG from "./../../assets/icons/sad-potato.png";
import { Story, storyCreateReq } from "../../types/story";
import {
  CategoryByString,
  Endpoint,
  LOCALSTORAGE_JWT,
  PlaceByString,
  TriggerWarningByString,
} from "../../types/constant";
import { useHistory } from "react-router";
import { useAppDispatch, useAppSelector } from "../../hooks";
import axios from "axios";
import sanitize from "sanitize-html";
import { useEffect } from "react";
import {
  displayLoadingScreen,
  displayModal,
  setModalInfo,
} from "../../reducers/uiSlice";
import { Category } from "../../types/category";

type NewStoryFormProps = {
  storyId?: string;
};

const NewStoryForm = (props: NewStoryFormProps): JSX.Element => {
  const [sectionNum, setSectionNum] = useState(0);
  const [dStoryName, setDStoryName] = useState("กรุณาใส่ชื่อเรื่อง");
  const [dStoryNameInputWidth, setDStoryNameInputWidth] = useState(
    dStoryName.length
  );
  const [dPlace, setDPlace] = useState<{ value: string; label: string } | null>(
    null
  );
  const [dTriggerWarnings, setDTriggerWarnings] = useState<OptionsType<{
    value: string;
    label: string;
  }> | null>(null);
  const [dCategories, setDCategories] = useState<OptionsType<{
    value: string;
    label: string;
  }> | null>(null);
  const [newStoryData, setNewStoryData] = useState<storyCreateReq>({
    content: "",
    author_alias: "anonymous",
  });
  const [isUseUsername, setIsUseUsername] = useState(false);
  const history = useHistory();
  const currentUser = useAppSelector((state) => state.user.currentUser);
  const [storyMutatedSuccess, setStoryMutatedSuccess] =
    useState<boolean>(false);

  const [showPlaceWarning, setShowPlaceWarning] = useState(false);
  const [showContentWarning, setShowContentWarning] = useState(false);
  const [showStoryTitleWarning, setShowStoryTitleWarning] = useState(false);
  const [showCategoryWarning, setShowCategoryWarning] = useState(false);
  const [redContentCounter, setRedContentCounter] = useState(true);
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const dispatch = useAppDispatch();

  const [categoryOptions2, setCategoryOptions2] = useState<
    { label: string; value: any }[]
  >([]);

  const placeOptions = [
    { value: "foot_path", label: "ริมถนน" },
    { value: "gym", label: "สถานที่ออกกำลังกาย" },
    { value: "social_media", label: "สังคมออนไลน์" },
    { value: "park", label: "สวนสาธารณะ" },
    { value: "restaurant", label: "ร้านอาหาร" },
    { value: "market", label: "ตลาด" },
    { value: "mall", label: "ห้างสรรพสินค้า" },
    { value: "public_transportation", label: "ขนส่งสาธารณะ" },
    { value: "outdoor", label: "พื้นที่สาธารณะ" },
    { value: "entertainment_venue", label: "สถานบันเทิง" },
    { value: "religious_place", label: "ศาสนสถาน" },
    { value: "workplace", label: "ที่ทำงาน" },
    { value: "home", label: "ที่อยู่อาศัย" },
    { value: "university", label: "มหาวิทยาลัย" },
    { value: "school", label: "โรงเรียน" },
    { value: "other", label: "สถานที่อื่น ๆ" },
  ];

  const placeOptionsMap = new Map([
    [1, { value: "school", label: "โรงเรียน" }],
    [2, { value: "university", label: "มหาวิทยาลัย" }],
    [3, { value: "home", label: "ที่อยู่อาศัย" }],
    [4, { value: "workplace", label: "ที่ทำงาน" }],
    [5, { value: "religious_place", label: "ศาสนสถาน" }],
    [6, { value: "entertainment_venue", label: "สถานบันเทิง" }],
    [7, { value: "outdoor", label: "พื้นที่สาธารณะ" }],
    [8, { value: "public_transportation", label: "ขนส่งสาธารณะ" }],
    [9, { value: "mall", label: "ห้างสรรพสินค้า" }],
    [10, { value: "market", label: "ตลาด" }],
    [11, { value: "restaurant", label: "ร้านอาหาร" }],
    [12, { value: "park", label: "สวนสาธารณะ" }],
    [13, { value: "social_media", label: "สังคมออนไลน์" }],
    [14, { value: "gym", label: "สถานที่ออกกำลังกาย" }],
    [15, { value: "foot_path", label: "ริมถนน" }],
    [16, { value: "other", label: "สถานที่อื่น ๆ" }],
  ]);

  const triggerWarningOptions = [
    { value: "abortion", label: "การยุติการตั้งครรภ์" },
    { value: "alcohol_use", label: "การใช้เครื่องดื่มแอลกอฮอล์" },
    { value: "bullying", label: "การกลั่นแกล้ง" },
    { value: "classism", label: "การเหยียดชนชั้น" },
    { value: "drug_use", label: "การใช้ยาเสพติด" },
    { value: "pregnancy", label: "การตั้งครรภ์" },
    { value: "racism", label: "การเหยียดเชื้อชาติ" },
    { value: "self_harm", label: "การทำร้ายตนเอง" },
    { value: "sexism", label: "การเหยียดเพศ" },
    { value: "sexual_harassment", label: "การล่วงละเมิดทางเพศ" },
    { value: "suicide", label: "การฆ่าตัวตาย" },
    { value: "abusive_relationship", label: "ความสัมพันธ์ที่มีความรุนแรง" },
    { value: "death", label: "ความตาย" },
    { value: "domestic_violence", label: "ความรุนแรงในครัวเรือน" },
    { value: "verbal_violence", label: "ความรุนแรงทางคำพูด" },
    { value: "physical_violence", label: "ความรุนแรงทางกาย" },
    { value: "psychological", label: "ความรุนแรงทางจิตใจ" },
    { value: "eating_disorders", label: "โรคเกี่ยวกับการกิน" },
    { value: "mental_illness", label: "โรคทางจิตเวช" },
    { value: "pedophilia", label: "โรคใคร่เด็ก" },
    { value: "transphobia", label: "อคติต่อบุคคลข้ามเพศ" },
    { value: "homophobia", label: "อคติต่อบุคคลรักเพศเดียวกัน" },
  ];

  const triggerWarningOptionsMap = new Map([
    [7, { value: "bullying", label: "การกลั่นแกล้ง" }],
    [8, { value: "sexual_harassment", label: "การล่วงละเมิดทางเพศ" }],
    [9, { value: "racism", label: "การเหยียดเชื้อชาติ" }],
    [10, { value: "classism", label: "การเหยียดชนชั้น" }],
    [13, { value: "drug_use", label: "การใช้ยาเสพติด" }],
    [14, { value: "alcohol_use", label: "การใช้เครื่องดื่มแอลกอฮอล์" }],
    [15, { value: "suicide", label: "การฆ่าตัวตาย" }],
    [16, { value: "self_harm", label: "การำทร้ายตนเอง" }],
    [17, { value: "pregnancy", label: "การตั้งครรภ์" }],
    [18, { value: "abortion", label: "การยุติการตั้งครรภ์" }],
    [
      1,
      { value: "abusive_relationship", label: "ความสัมพันธ์ที่มีความรุนแรง" },
    ],
    [2, { value: "domestic_violence", label: "ความรุนแรงในครัวเรือน" }],
    [3, { value: "verbal_violence", label: "ความรุนแรงทางคำพูด" }],
    [4, { value: "physical_violence", label: "ความรุนแรงทางกาย" }],
    [5, { value: "psychological", label: "ความรุนแรงทางจิตใจ" }],
    [6, { value: "death", label: "ความตาย" }],
    [11, { value: "homophobia", label: "อคติต่อบุคคลรักเพศเดียวกัน" }],
    [12, { value: "transphobia", label: "อคติต่อบุคคลข้ามเพศ" }],
    [19, { value: "mental_illness", label: "โรคทางจิตเวช" }],
    [20, { value: "eating_disorders", label: "โรคเกี่ยวกับการกิน" }],
    [21, { value: "pedophilia", label: "โรคใคร่เด็ก" }],
    [22, { value: "sexism", label: "การเหยียดเพศ" }],
  ]);

  const categoryOptions = [
    { value: "gender_inequality", label: "ความไม่เท่าเทียมทางเพศ" },
    { value: "gender_discrimination", label: "การเลือกปฏิบัติทางเพศ" },
    { value: "harassment", label: "การถูกคุกคาม" },
    { value: "sexual_objectification", label: "การถูกทำให้เป็นวัตถุทางเพศ" },
    { value: "misogyny", label: "ความเกลียดชังต่อผู้หญิง" },
    { value: "abusive_relationship", label: "ความสัมพันธ์ที่มีความรุนแรง" },
    { value: "sexual_violence", label: "ความรุนแรงทางเพศ" },
    { value: "domestic_violence", label: "ความรุนแรงในครัวเรือน" },
    { value: "mansplaining", label: "ถูกผู้ชายอธิบายใส่" },
    { value: "beauty_standard", label: "มาตรฐานความงาม" },
    { value: "feminine_burden", label: "ภาระที่มากับความเป็นหญิง" },
    { value: "reproductive_rights", label: "สิทธิในการเจริญพันธุ์" },
    { value: "autonomy", label: "สิทธิเหนือร่างกายตนเอง" },
    { value: "access_to_resources", label: "การเข้าถึงทรัพยากร" },
    { value: "other", label: "อื่น ๆ" },
  ];

  const categoryOptionsMap = new Map([
    [2, { value: "gender_discrimination", label: "การเลือกปฏิบัติทางเพศ" }],
    [15, { value: "access_to_resources", label: "การเข้าถึงทรัพยากร" }],
    [4, { value: "harassment", label: "การถูกคุกคาม" }],
    [
      5,
      { value: "sexual_objectification", label: "การถูกทำให้เป็นวัตถุทางเพศ" },
    ],
    [1, { value: "gender_inequality", label: "ความไม่เท่าเทียมทางเพศ" }],
    [6, { value: "misogyny", label: "ความเกลียดชังต่อผู้หญิง" }],
    [
      7,
      { value: "abusive_relationship", label: "ความสัมพันธ์ที่มีความรุนแรง" },
    ],
    [8, { value: "sexual_violence", label: "ความรุนแรงทางเพศ" }],
    [9, { value: "domestic_violence", label: "ความรุนแรงในครัวเรือน" }],
    [10, { value: "mansplaining", label: "ถูกผู้ชายอธิบายใส่" }],
    [11, { value: "beauty_standard", label: "มาตรฐานความงาม" }],
    [12, { value: "feminine_burden", label: "ภาระที่มากับความเป็นหญิง" }],
    [13, { value: "reproductive_rights", label: "สิทธิในการเจริญพันธุ์" }],
    [14, { value: "autonomy", label: "สิทธิเหนือร่างกายตนเอง" }],
    [16, { value: "other", label: "อื่น ๆ" }],
  ]);

  useEffect(() => {
    // fetch categories
    // Category.find({ sort: [{ field: "title_th", direction: "asc" }] })
    //   .then((res) => {
    //     const _categoryOptions: { label: string; value: any }[] = res.map(
    //       (category) => {
    //         return { value: category.id, label: category.title_th };
    //       }
    //     );
    //     setCategoryOptions2([..._categoryOptions]);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   })
    //   .finally(() => {});

    // update mode
    if (props.storyId !== undefined) {
      setIsUpdateMode(true);

      Story.findOne(props.storyId!)
        .then((story) => {
          const triggerWarningIds: number[] | undefined =
            story?.trigger_warnings.map((tw) => {
              return tw.id;
            });

          const categoryIds: number[] | undefined = story?.categories?.map(
            (cat) => {
              return cat.id;
            }
          );

          setDStoryName(story?.title!);
          setDStoryNameInputWidth(story?.title.length!);

          // setDPlace
          const placeOption = placeOptionsMap.get(story?.place?.id!);
          setDPlace(placeOption!);

          // setDTriggerWarnings
          const _tws = story?.trigger_warnings.map((tw) => {
            return triggerWarningOptionsMap.get(tw.id);
          });
          setDTriggerWarnings(
            _tws as OptionsType<{ value: string; label: string }>
          );

          // setDCategories
          const _cats = story?.categories!.map((cat) => {
            return categoryOptionsMap.get(cat.id);
          });
          setDCategories(
            _cats as OptionsType<{ value: string; label: string }>
          );

          // setNewStoryData
          setNewStoryData({
            title: story?.title,
            content: story?.content,
            author_alias: story?.author_alias,
            trigger_warnings: triggerWarningIds,
            categories: categoryIds,
            place: story?.place?.id,
          });

          // setIsUseUsername
          setIsUseUsername(false);
          if (story?.author_alias !== "anonymous") {
            setIsUseUsername(true);
          }

          // set red counter
          setRedContentCounter(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    return () => {};
  }, []);

  const validateFormSection0 = (): boolean => {
    let hasError = false;
    if (newStoryData.title?.trim() === "" || newStoryData.title === undefined) {
      setShowStoryTitleWarning(true);
      hasError = true;
    }

    if (newStoryData.place === undefined) {
      setShowPlaceWarning(true);
      hasError = true;
    }

    if (newStoryData.content === undefined) {
      setShowContentWarning(true);
      hasError = true;
    } else {
      if (newStoryData.content.length < 50) {
        setShowContentWarning(true);
        hasError = true;
      }
    }

    return hasError;
  };

  const validateFormSection1 = (): boolean => {
    let hasError = false;
    if (newStoryData.categories === undefined) {
      setShowCategoryWarning(true);
      hasError = true;
    } else {
      if (newStoryData.categories.length > 5) {
        setShowCategoryWarning(true);
        hasError = true;
      }
    }

    return hasError;
  };

  const showPreview = () => {
    history.push("/new-story/preview");
  };

  const goNextSection = () => {
    if (sectionNum === 0) {
      const hasError = validateFormSection0();
      if (hasError) {
        return;
      }

      setSectionNum(sectionNum + 1);
    }
  };

  const goPrevSection = () => {
    if (sectionNum <= 2) {
      setSectionNum(sectionNum - 1);
    }
  };

  const onStoryTitleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const newStoryName: string = e.currentTarget.value;
    const newWidth: number = newStoryName.length;
    setDStoryName(newStoryName);
    setDStoryNameInputWidth(newWidth);
    setShowStoryTitleWarning(false);
    setNewStoryData({ ...newStoryData, title: newStoryName });
  };

  const onContentChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
    if (e.currentTarget.value.length >= 50) {
      setRedContentCounter(false);
    } else {
      setRedContentCounter(true);
    }

    setShowContentWarning(false);
    setNewStoryData({ ...newStoryData, content: e.currentTarget.value });
  };

  const onSubmitForm = (e: React.FormEvent) => {
    e.preventDefault();
  };

  const onPlaceChange = (selected: { value: string; label: string } | null) => {
    setDPlace(selected);
    setShowPlaceWarning(false);

    const placeID = PlaceByString.get(selected!.value);
    setNewStoryData({ ...newStoryData, place: placeID });
  };

  const onTriggerWarningsChange = (
    selected: OptionsType<{ value: string; label: string }> | null
  ) => {
    setDTriggerWarnings(selected);

    let triggerWarningIDs: number[] = [];
    selected?.forEach((value) => {
      const twID = TriggerWarningByString.get(value.value);
      triggerWarningIDs.push(twID!);
    });

    setNewStoryData({ ...newStoryData, trigger_warnings: triggerWarningIDs });
  };

  const onSetAuthorAlias = (authorAlias: string) => {
    if (authorAlias === "anonymous") {
      setIsUseUsername(false);
    } else {
      setIsUseUsername(true);
    }
  };

  const onCategoriesChange = (
    selected: OptionsType<{ value: any; label: string }> | null
  ) => {
    setDCategories(selected);
    setShowCategoryWarning(false);

    let categoryIDs: number[] = [];
    selected?.forEach((value) => {
      const catID = CategoryByString.get(value.value);
      categoryIDs.push(catID!);
    });

    setNewStoryData({ ...newStoryData, categories: categoryIDs });
  };

  const getSanitizedStory = (storyData: storyCreateReq): storyCreateReq => {
    return {
      ...storyData,
      title: sanitize(storyData.title!),
      content: sanitize(storyData.content!),
    };
  };

  const onCreateStory = async () => {
    // set use username
    if (isUseUsername) {
      newStoryData.author_alias = currentUser.username;
    }
    const requestBody = getSanitizedStory(newStoryData);

    if (
      requestBody.title?.trim().length === 0 ||
      requestBody.content?.trim().length === 0
    ) {
      // * hacker would reach this point
      // * because request body is sanitized
      alert("Something went wrong! Taking you home...");
      history.push("/home");
      return;
    }

    const jwt = localStorage.getItem(LOCALSTORAGE_JWT);

    axios
      .post(`${Endpoint.BACKEND_URL}${Endpoint.PATH_STORY}`, requestBody, {
        headers: { Authorization: `Bearer ${jwt}` },
      })
      .then((res) => {
        if (res.status === 200) {
          console.log(res);

          setStoryMutatedSuccess(true);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setSectionNum(2);
      });
  };

  const onUpdateStory = async () => {
    // set use username
    if (isUseUsername) {
      newStoryData.author_alias = currentUser.username;
    }
    const requestBody = getSanitizedStory(newStoryData);

    if (
      requestBody.title?.trim().length === 0 ||
      requestBody.content?.trim().length === 0
    ) {
      // * hacker would reach this point
      // * because request body is sanitized and nobody should ever do this
      alert("Something went wrong! Taking you home...");
      history.push("/home");
      return;
    }

    const jwt = localStorage.getItem(LOCALSTORAGE_JWT);
    axios
      .put(
        `${Endpoint.BACKEND_URL}${Endpoint.PATH_STORY}/${props.storyId}`,
        requestBody,
        {
          headers: { Authorization: `Bearer ${jwt}` },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setStoryMutatedSuccess(true);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setSectionNum(2);
      });
  };

  const onMutateStory = async () => {
    // validate form
    let hasError = validateFormSection0();
    if (hasError) return;

    hasError = validateFormSection1();
    if (hasError) return;

    dispatch(displayLoadingScreen(true));

    if (isUpdateMode) {
      await onUpdateStory();
      dispatch(displayLoadingScreen(false));
      return;
    }

    await onCreateStory();
    dispatch(displayLoadingScreen(false));
  };

  return (
    <React.Fragment>
      <form
        className={styles.form_container}
        action="#"
        onSubmit={onSubmitForm}
      >
        {sectionNum < 2 && (
          <React.Fragment>
            <div className={styles.story_name_container}>
              <div className={styles.story_name_line}>
                <input
                  className={styles.input_story_name}
                  size={dStoryNameInputWidth}
                  onChange={onStoryTitleChange}
                  type="text"
                  value={dStoryName}
                  placeholder={dStoryName}
                  maxLength={50}
                />
              </div>
            </div>
            {showStoryTitleWarning && (
              <p
                style={{ textAlign: "center" }}
                className={`${styles.warning_text}`}
              >
                * กรุณาใส่ชื่อเรื่องราวของคุณ
              </p>
            )}
          </React.Fragment>
        )}

        {/* Section 0 */}
        {sectionNum === 0 && (
          <section className={styles.form_section}>
            {showPlaceWarning && (
              <p className={`${styles.warning_text}`}>* กรุณาเลือกสถานที่</p>
            )}
            <Select
              onChange={(e) => {
                onPlaceChange(e);
              }}
              value={dPlace}
              options={placeOptions}
              placeholder="กรุณาเลือกสถานที่ *"
              styles={{
                placeholder: (provided) => ({
                  ...provided,
                  color: "#AEB98A",
                  fontSize: ".8em",
                }),
                control: (provided) => ({
                  ...provided,
                  borderColor: "#68B7B4",
                  borderRadius: "2em",
                }),
                container: (provided) => ({
                  ...provided,
                  width: "80%",
                  marginBottom: "20px",
                }),
                option: (provided) => ({
                  ...provided,
                  textAlign: "left",
                }),
              }}
            />

            <Select
              onChange={(e) => {
                onTriggerWarningsChange(e);
              }}
              value={dTriggerWarnings}
              isMulti
              options={triggerWarningOptions}
              placeholder="กรุณาเลือกคำเตือน *"
              styles={{
                placeholder: (provided) => ({
                  ...provided,
                  color: "#AEB98A",
                  fontSize: ".8em",
                }),
                control: (provided) => ({
                  ...provided,
                  borderColor: "#68B7B4",
                  borderRadius: "2em",
                }),
                container: (provided) => ({
                  ...provided,
                  width: "80%",
                  marginBottom: "20px",
                }),
              }}
            />

            {showContentWarning && (
              <p className={`${styles.warning_text}`}>
                * กรุณากรอกเรื่องราวอย่างน้อย 50 ตัวอักษร
              </p>
            )}

            <div className={styles.story_content}>
              <textarea
                onChange={onContentChange}
                value={newStoryData.content}
                // className={styles.story_content}
                placeholder="เขียนเรื่องราวของคุณได้ที่นี่ คุณสามารถบอกเล่าเรื่องราวปัญหา หรือเหตุการณ์ใด ๆ ก็ได้ที่เกี่ยวข้องกับการเป็นผู้หญิงไทย โปรดระมัดระวังไม่เปิดเผยข้อมูลส่วนตัว"
              />
            </div>

            <p className={`${styles.content_word_counter}`}>
              <span style={redContentCounter ? { color: "red" } : {}}>
                {newStoryData.content?.length}
              </span>
            </p>

            <div className={styles.section_one_nav}>
              <p
                onClick={() => {
                  history.goBack();
                }}
              >
                <FontAwesomeIcon
                  icon={faChevronLeft}
                  className={styles.green_text}
                />
                &nbsp;
                <span className={styles.pink_text}>ย้อนกลับ</span>
              </p>
              <p onClick={goNextSection}>
                <span className={styles.pink_text}>ถัดไป</span>
                &nbsp;
                <FontAwesomeIcon
                  icon={faChevronRight}
                  className={styles.green_text}
                />
              </p>
            </div>
          </section>
        )}

        {/* Section 1 */}
        {sectionNum === 1 && (
          <section className={styles.form_section}>
            {showCategoryWarning && (
              <p className={`${styles.warning_text}`}>
                * กรุณาเลือกหมวดหมู่อย่างน้อย 1 หมวดหมู่แต่ไม่เกิน 5 หมวดหมู่
              </p>
            )}
            <Select
              isMulti
              placeholder="กรุณาเลือกหมวดหมู่ *"
              value={dCategories}
              options={categoryOptions}
              onChange={onCategoriesChange}
              styles={{
                placeholder: (provided) => ({
                  ...provided,
                  color: "#AEB98A",
                }),
                control: (provided) => ({
                  ...provided,
                  borderColor: "#68B7B4",
                }),
                container: (provided) => ({
                  ...provided,
                  width: "80%",
                  marginBottom: "20px",
                }),
              }}
            />

            <div>
              <p>คุณต้องการจะแสดง username ของคุณในเรื่องราวหรือไม่ ?</p>
              <input
                onChange={() => onSetAuthorAlias("username")}
                className={styles.checkmark}
                type="radio"
                name="showAuthorName"
                value="yes"
                checked={isUseUsername}
              />
              <label htmlFor="showAuthorName">
                แสดง ({`${currentUser.username}`})
              </label>
              <br />
              <input
                onChange={() => onSetAuthorAlias("anonymous")}
                className={styles.checkmark}
                type="radio"
                name="showAuthorName"
                value="no"
                checked={!isUseUsername}
              />
              <label htmlFor="showAuthorName">ไม่แสดง (anonymous)</label>
            </div>

            {/* TODO: Preview */}
            {/* <p onClick={showPreview}>Preview</p>
          <Route path="/new-story/preview">
            <div style={{
              position: "fixed",
              width: "100%",
              height: "100%",

            }}>
              <StoryDetailPage />
            </div>
          </Route> */}

            <Button
              onClick={async () => {
                await onMutateStory();
              }}
              width="30%"
              text="ยืนยัน"
              backgroundColor="#76C3C0"
            />

            <div className={styles.section_two_nav}>
              <p onClick={goPrevSection}>
                <FontAwesomeIcon
                  icon={faChevronLeft}
                  className={styles.green_text}
                />
                &nbsp;
                <span className={styles.pink_text}>ย้อนกลับ</span>
              </p>
            </div>
          </section>
        )}

        {/* Section 2 */}
        {sectionNum === 2 && (
          <section className={styles.form_section}>
            <div className={styles.section_three_container}>
              {storyMutatedSuccess ? (
                <React.Fragment>
                  <p>ขอบคุณที่เล่าให้ฟังนะ :)</p>
                  <img
                    className={styles.result_image}
                    src={cactusHugPNG}
                    alt="cactus"
                  />
                  <div className={styles.section_three_notebook_button}>
                    <Button
                      onClick={() => {
                        history.push(`/user/${currentUser.username}/notebook`);
                      }}
                      margin="10px 0"
                      text="ดูเรื่องราวที่สมุดของคุณ"
                      backgroundColor="#76C3C0"
                      width="70%"
                    />
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <p>
                    เกิดบางอย่างผิดพลาด ไม่สามารถเชื่อมต่อกับเว็บไซต์ได้
                    กรุณาลองอีกครั้ง
                  </p>
                  <img
                    className={styles.result_image}
                    src={sadPotatoPNG}
                    alt="cactus"
                  />
                </React.Fragment>
              )}

              <div className={styles.section_three_map_button}>
                <Button
                  onClick={() => {
                    history.push("/map");
                  }}
                  margin="10px 0"
                  text="กลับสู่แผนที่"
                  backgroundColor="#f13d57"
                  width="70%"
                />
              </div>
            </div>
          </section>
        )}
      </form>
    </React.Fragment>
  );
};

export default NewStoryForm;

import React from "react";
import ConfettiPNG from "./../assets/icons/confetti_left.png";
import CheckedPNG from "./../assets/icons/checked.png";
import styles from "./SignupCompletePage.module.css";
import Button from "../components/ui/Button";
import SizedBox from "../components/ui/SizedBox";

const SignupCompletePage = (): JSX.Element => {
  return (
    <React.Fragment>
      <div className={styles.signup_complete_container}>
        <div>
          <img className={styles.confetti} src={ConfettiPNG} alt="confetti icon" />
          <img className={styles.checked} src={CheckedPNG} alt="confetti icon" />
          <img className={styles.confetti} src={ConfettiPNG} alt="confetti icon" />
        </div>

        <SizedBox height="1vh" />
        <h3>ดำเนินการเรียบร้อย</h3>
        <SizedBox height="5vh" />
        <Button backgroundColor="#f13d57" text="กลับสู่หน้าหลัก" />
      </div>
    </React.Fragment>
  )
}

export default SignupCompletePage;
import React, { useEffect, useState } from "react";
import StoryFold from "../components/story/StoryFold";
import { useAppDispatch, useAppSelector } from "../hooks";
import {
  LOCALSTORAGE_JWT,
  PlaceByString,
  placeIconByString,
} from "../types/constant";
import { Story, story } from "../types/story";
import styles from "./FeedPage.module.css";
import { displayLoadingScreen } from "../reducers/uiSlice";
import { useQuery } from "../hooks/useQuery";
import { useCallback } from "react";
import WriteIconSVG from "./../assets/icons/write-icon.svg";
import { NavLink } from "react-router-dom";

const FeedPage = (): JSX.Element => {
  const fetchLimit = 5;
  const [loadStoryReady, setLoadStoryReady] = useState(false);
  const [fetchStart, setFetchStart] = useState(0);
  const [noMoreStories, setNoMoreStories] = useState(false);
  const isReading = useAppSelector((state) => state.user.isReading);
  const jwt = localStorage.getItem(LOCALSTORAGE_JWT);
  const dispatch = useAppDispatch();
  const query = useQuery();
  const place = query.get("place");
  const placeID = PlaceByString.get(place!);
  const [stories, setStories] = useState<story[]>([]);
  const [fetchCriteria, setFetchCriteria] = useState("most recent");

  const scrollHandler = useCallback(() => {
    // * There exists only one story fold in the app
    // * The story fold id is inside StoryFold.tsx
    const storyFold = document.getElementById("story-fold");
    const scrollHeight = storyFold?.scrollHeight;
    const scrollPosY = window.scrollY;
    const clientHeight = storyFold?.clientHeight;

    if (scrollHeight! - scrollPosY <= clientHeight!) {
      if (!loadStoryReady && !noMoreStories) {
        setLoadStoryReady(true);
      }
    }
  }, [loadStoryReady, noMoreStories]);

  // do at start
  useEffect(() => {
    // scroll to top if page reloads
    window.onbeforeunload = () => {
      window.scrollTo(0, 0);
    };

    const place = query.get("place");
    if (place !== null) {
      const _placeIcon = placeIconByString.get(place);
    }

    window.addEventListener("scroll", scrollHandler);

    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, [jwt, dispatch, scrollHandler]);

  // Change fetch criteria
  useEffect(() => {
    // reset stories
    setStories([]);

    // reset fetchStart, loadStoryReady and noMoreStories
    setFetchStart(0);
    setLoadStoryReady(true);
    setNoMoreStories(false);

    return () => {};
  }, [fetchCriteria]);

  // Reload stories
  useEffect(() => {
    // if place wasn't given, fetch nothing
    if (placeID === undefined || placeID === null) {
      // history.replace("/home")
      return;
    }

    if (loadStoryReady && !noMoreStories) {
      if (fetchCriteria === "most recent") {
        Story.findByPlaceId(placeID!, {
          fetchLimit: fetchLimit,
          fetchSkip: fetchStart,
          orderBy: "most recent",
        })
          .then((resStories) => {
            if (resStories.length === 0) {
              setNoMoreStories(true);
            } else {
              setStories([...stories, ...resStories]);
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            dispatch(displayLoadingScreen(false));
            setLoadStoryReady(false);
            setFetchStart((state) => state + fetchLimit);
          });
      } else if (fetchCriteria === "most popular") {
        Story.findByMostPopular(placeID!, {
          fetchLimit: fetchLimit,
          fetchSkip: fetchStart,
        })
          .then((resStories) => {
            if (resStories.length === 0) {
              setNoMoreStories(true);
            } else {
              let storyIds: number[] = [];
              for (const story of resStories) {
                storyIds.push(story.id);
              }

              Story.findByIds(storyIds)
                .then((resStories) => {
                  setStories([...stories, ...resStories]);
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            dispatch(displayLoadingScreen(false));
            setLoadStoryReady(false);
            setFetchStart((state) => state + fetchLimit);
          });
      }
    }

    return () => {};
  }, [dispatch, loadStoryReady, noMoreStories, fetchCriteria]);

  return (
    <React.Fragment>
      <div
        style={{ display: "flex", minHeight: "100%" }}
        className={`${styles.feed_container} ${
          isReading ? styles.no_scroll : ""
        }`}
      >
        <StoryFold setFetchCriteria={setFetchCriteria} stories={stories} />

        <NavLink to="/new-story">
          <img
            className={styles.write_icon}
            src={WriteIconSVG}
            alt="write-icon"
          />
        </NavLink>
      </div>
    </React.Fragment>
  );
};

export default FeedPage;

import axios from "axios";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { useAppDispatch } from "../../hooks";
import { useQuery } from "../../hooks/useQuery";
import { displayLoadingScreen } from "../../reducers/uiSlice";
import {
  setCurrentUser,
  setUserIsAuthenticated,
} from "../../reducers/userSlice";
import { LOCALSTORAGE_JWT } from "../../types/constant";
import { isValidPassword } from "../../utils/validation";
import Button from "../ui/Button";
import TextInput from "../ui/TextInput";
import styles from "./ResetPasswordForm.module.css";

const ResetPasswordForm = (): JSX.Element => {
  const [newPassword, setNewPassword] = useState<string>("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState<string>("");
  const [showPasswordError, setShowPasswordError] = useState<boolean>(false);
  const [showPasswordNotMatch, setShowPasswordNotMatch] =
    useState<boolean>(false);
  const [showSubmitSuccess, setShowSubmitSuccess] = useState<boolean>(false);
  const [showSubmitError, setShowSubmitError] = useState<boolean>(false);
  const [disableInput, setDisableInput] = useState<boolean>(false);
  const history = useHistory();
  const query = useQuery();
  const dispatch = useAppDispatch();

  const onChangeNewPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(e.currentTarget.value);
    setShowPasswordError(false);
  };

  const onChangeNewPasswordConfirm = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNewPasswordConfirm(e.currentTarget.value);
    setShowPasswordError(false);
  };

  const sendResetPasswordRequest = () => {
    // validation
    if (!isValidPassword(newPassword) && !isValidPassword(newPasswordConfirm)) {
      setShowPasswordError(true);
      return;
    }

    if (newPassword !== newPasswordConfirm) {
      setShowPasswordNotMatch(true);
      return;
    }

    const code = query.get("code");
    if (code == null) {
      return;
    }

    dispatch(displayLoadingScreen(true));
    setDisableInput(true);

    axios
      .post("https://svc.allthaiwomen.com/auth/reset-password", {
        code: code,
        password: newPassword,
        passwordConfirmation: newPasswordConfirm,
      })
      .then((res) => {
        if (res.status === 200) {
          const resp = res.data;
          const jwt = resp.jwt;
          localStorage.setItem(LOCALSTORAGE_JWT, jwt);
          dispatch(setCurrentUser(res.data.user));
          dispatch(setUserIsAuthenticated(true));

          setShowSubmitSuccess(true);
        }
      })
      .catch((err) => {
        setShowSubmitError(true);
        console.log(err);
      })
      .finally(() => {
        dispatch(displayLoadingScreen(false));
        setTimeout(() => {
          history.replace("/home");
        }, 8000);
      });
  };

  return (
    <React.Fragment>
      <form
        className={styles.reset_password_form}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        {!showSubmitSuccess && !showSubmitError && (
          <React.Fragment>
            <p>กรุณาใส่รหัสผ่านใหม่ของคุณ</p>

            <label htmlFor="password">รหัสผ่านใหม่:</label>
            <div>
              <TextInput
                disabled={disableInput}
                hintText="กรอกรหัสผ่านใหม่ของคุณ"
                inputType="password"
                onChange={onChangeNewPassword}
                value={newPassword}
              />
            </div>
            {showPasswordError && (
              <p className={styles.warn_text}>
                * รหัสผ่านต้องมีความยาวมากกว่า 8 ตัวอักษร
              </p>
            )}

            <label htmlFor="password">กรุณายืนยันรหัสผ่าน:</label>
            <div>
              <TextInput
                disabled={disableInput}
                hintText="กรอกรหัสผ่านใหม่ของคุณอีกครั้ง"
                inputType="password"
                onChange={onChangeNewPasswordConfirm}
                value={newPasswordConfirm}
              />
            </div>
            {showPasswordError && (
              <p className={styles.warn_text}>
                * รหัสผ่านต้องมีความยาวมากกว่า 8 ตัวอักษร
              </p>
            )}
            {showPasswordNotMatch && (
              <p className={styles.warn_text}>
                * กรุณากรอก password ทั้งสองช่องให้ตรงกัน
              </p>
            )}

            <Button
              margin="10px 0 0 0"
              text="ยืนยัน"
              backgroundColor="#f13d57"
              onClick={sendResetPasswordRequest}
            />
          </React.Fragment>
        )}

        {showSubmitSuccess && (
          <p style={{ color: "green" }}>
            รีเซ็ตรหัสผ่านสำเร็จ กำลังพาคุณไปยังหน้า Home
          </p>
        )}

        {showSubmitError && (
          <p style={{ color: "red" }}>
            ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้ กรุณาลองใหม่อีกครั้ง
            กำลังพาคุณไปยังหน้า Home
          </p>
        )}
      </form>
    </React.Fragment>
  );
};

export default ResetPasswordForm;

import styles from "./LoadingPage.module.css";
import logo from "../assets/icons/logo-01.svg"

const LoadingPage = (): JSX.Element => {
  return (
    <div className={styles.loading_page}>
      <img className={styles.bounce} src={logo} alt="logo" />
      <div className={styles.spinner_container}>
        <div className={styles.spinner}></div>
        <div className={styles.spinner}></div>
        <div className={styles.spinner}></div>
        <div className={styles.spinner}></div>
        <div className={styles.spinner}></div>
      </div>
    </div>
  );
}

export default LoadingPage;
import React from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { displayModal } from "../../reducers/uiSlice";
import Button from "./Button";
import styles from "./Modal.module.css";

const Modal = (): JSX.Element => {
  const modalInfo = useAppSelector(state => state.ui.modalInfo);
  const dispatch = useAppDispatch();

  return (<React.Fragment>
    <div id="modal-container" className={styles.modal_cotainer} >
      <div className={styles.modal}>
        <div className={styles.modal_header}>
          {modalInfo.title}
        </div>
        <div className={styles.modal_content}>
          <div className={styles.modal_content_text}>
            {modalInfo.description}
          </div>
          <div className={styles.modal_content_button}>
            <Button
              onClick={() => { dispatch(displayModal(false)) }}
              text="ปิด"
              textColor="#000"
              backgroundColor="#ccc"
              width="20%" />
          </div>
        </div>
      </div>
    </div>
  </React.Fragment >)
}

export default Modal;
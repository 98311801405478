import React, { useState } from "react";
import Button from "../components/ui/Button";
import styles from "./NotebookPage.module.css";
import {
  faFacebookSquare,
  faInstagramSquare,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { userUpdateReq } from "../types/user";
import { iso8601ToPrettyDate } from "../utils/string";

import nbCover1 from "./../assets/images/notebook/cover/cov1.png";
import nbCover2 from "./../assets/images/notebook/cover/cov2.png";
import nbCover3 from "./../assets/images/notebook/cover/cov3.png";
import nbCover4 from "./../assets/images/notebook/cover/cov4.png";
import nbCover5 from "./../assets/images/notebook/cover/cov5.png";

import nbPat1 from "./../assets/images/notebook/pattern/Pattern1.png";
import nbPat2 from "./../assets/images/notebook/pattern/Pattern2.png";
import nbPat3 from "./../assets/images/notebook/pattern/Pattern3.png";
import nbPat4 from "./../assets/images/notebook/pattern/Pattern4.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { story } from "../types/story";
import axios from "axios";
import { Endpoint, LOCALSTORAGE_JWT } from "../types/constant";
import { user } from "../types/user";
import { useEffect } from "react";

// import stickers
import cactusHugPNG from "./../assets/icons/stickers/cactushug.png";
import coffeePNG from "./../assets/icons/stickers/coffee.png";
import crownPNG from "./../assets/icons/stickers/crown.png";
import fistPNG from "./../assets/icons/stickers/fist.png";
import flowerPNG from "./../assets/icons/stickers/flower.png";
import goodjobPNG from "./../assets/icons/stickers/goodjob.png";
import greenheartPNG from "./../assets/icons/stickers/greenheart.png";
import metooPNG from "./../assets/icons/stickers/metoo.png";
import musicPNG from "./../assets/icons/stickers/music.png";
import pinkheartPNG from "./../assets/icons/stickers/pinkheart.png";
import pplhugPNG from "./../assets/icons/stickers/pplhug.png";
import queenPNG from "./../assets/icons/stickers/queen.png";
import rainbowPNG from "./../assets/icons/stickers/rainbow.png";
import redheartPNG from "./../assets/icons/stickers/redheart.png";
import starPNG from "./../assets/icons/stickers/star.png";
import yellowheartPNG from "./../assets/icons/stickers/yellowheart.png";
import pearfectPNG from "./../assets/icons/stickers/pearfect.png";
import { useHistory, useLocation } from "react-router";
import { isMobile } from "../utils/validation";

type NotebookPageProps = {
  currentUser: user | undefined;
  setCurrentUser: React.Dispatch<React.SetStateAction<user | undefined>>;
  stories: story[];
};

const nbCoverByString = new Map<string, string>([
  ["cover1", nbCover1],
  ["cover2", nbCover2],
  ["cover3", nbCover3],
  ["cover4", nbCover4],
  ["cover5", nbCover5],
]);

const nbPatternByString = new Map<string, string>([
  ["pattern1", nbPat1],
  ["pattern2", nbPat2],
  ["pattern3", nbPat3],
  ["pattern4", nbPat4],
]);

const stickerByIdString = new Map<string, string>([
  ["1", coffeePNG],
  ["2", queenPNG],
  ["3", crownPNG],
  ["4", metooPNG],
  ["5", flowerPNG],
  ["6", goodjobPNG],
  ["7", pplhugPNG],
  ["8", cactusHugPNG],
  ["9", rainbowPNG],
  ["10", musicPNG],
  ["11", starPNG],
  ["12", greenheartPNG],
  ["13", pinkheartPNG],
  ["14", redheartPNG],
  ["15", yellowheartPNG],
  ["16", pearfectPNG],
  ["17", fistPNG],
]);

const NotebookPage = (props: NotebookPageProps): JSX.Element => {
  const history = useHistory();
  const location = useLocation();
  const jwt = localStorage.getItem(LOCALSTORAGE_JWT);
  const [changingCover, setChangingCover] = useState(false);
  const [changingPattern, setChangingPattern] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [coverDesign, setCoverDesign] = useState<string>();
  const [patternDesign, setPatternDesign] = useState<string>();

  // % array of stickers, this will be assigned when user lookup their stories
  const [stickers, setStickers] = useState<any[][]>();

  // % hasNoStories is a flag which indicates that a user has no stories at all
  // % it will govern the behavior of the right arrow
  const [hasNoStories, setHasNoStories] = useState<boolean>(false);
  // % hideRightArrow is a flag to show and hide right arrow
  // % its main function is to forcefully show and hide when a user doesn't have any stories
  // * a little bit of a hack
  const [hideRightArrow, setHideRightArrow] = useState<boolean>(false);

  useEffect(() => {
    setCoverDesign(nbCoverByString.get(props.currentUser?.notebook_cover!)!);
    setPatternDesign(
      nbPatternByString.get(props.currentUser?.notebook_pattern!)!
    );

    if (props.stories !== undefined) {
      if (props.stories.length === 0) {
        setHasNoStories(true);
      }
    }

    return () => {};
  }, [props.currentUser, props.stories]);

  useEffect(() => {
    // Load Stickers when user changes page
    if (currentPage === 0) return;

    if (props.stories.length !== 0) {
      // looping through the objects
      const stickerCounterMap =
        props.stories[currentPage - 1].sticker_counter_map;
      const stickerArr = [] as any;
      for (const key in stickerCounterMap) {
        if (Object.prototype.hasOwnProperty.call(stickerCounterMap, key)) {
          const element = stickerCounterMap[key];
          stickerArr.push([key, element]);
        }
      }

      // bubble sorting from max to min
      for (let i = 0; i < stickerArr.length; i++) {
        for (let j = 0; j < stickerArr.length - i - 1; j++) {
          if (stickerArr[j][1] < stickerArr[j + 1][1]) {
            [stickerArr[j], stickerArr[j + 1]] = [
              stickerArr[j + 1],
              stickerArr[j],
            ];
          }
        }
      }

      setStickers(stickerArr);
    }

    return () => {};
  }, [currentPage, props.stories]);

  const toggleChangingCover = () => {
    setChangingCover((state) => !state);
    setChangingPattern(false);
  };
  const toggleChangingPattern = () => {
    setChangingPattern((state) => !state);
    setChangingCover(false);
  };
  const incrementPage = () => {
    setStickers([]);

    if (hasNoStories) {
      setCurrentPage((currentPage) => currentPage + 1);
      setHideRightArrow(true);
      return;
    }

    if (currentPage + 1 <= props.stories!.length) {
      setCurrentPage((currentPage) => currentPage + 1);
    }
  };
  const decrementPage = () => {
    setHideRightArrow(false);
    setStickers([]);

    if (currentPage - 1 >= 0) {
      setCurrentPage((currentPage) => currentPage - 1);
    }
  };
  const updateCover = (cover: string) => {
    const data: userUpdateReq = {
      notebook_cover: cover,
    };

    axios
      .put(
        `${Endpoint.BACKEND_URL}${Endpoint.PATH_USER}/${props.currentUser?.id}`,
        data,
        { headers: { Authorization: `Bearer ${jwt}` } }
      )
      .then((res) => {
        setCoverDesign(nbCoverByString.get(cover)!);
        props.setCurrentUser({ ...props.currentUser, notebook_cover: cover });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const updatePattern = (pattern: string) => {
    const data: userUpdateReq = {
      notebook_pattern: pattern,
    };

    axios
      .put(
        `${Endpoint.BACKEND_URL}${Endpoint.PATH_USER}/${props.currentUser?.id}`,
        data,
        { headers: { Authorization: `Bearer ${jwt}` } }
      )
      .then((res) => {
        setPatternDesign(nbPatternByString.get(pattern)!);
        props.setCurrentUser({
          ...props.currentUser,
          notebook_pattern: pattern,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const shareToSocial = (shareTo: string) => {
    const currentStory = props.stories[currentPage - 1];
    const stickerIds = stickers?.map((stickerArr) => {
      return stickerArr[0];
    });

    const state = {
      story: currentStory,
      stickerIds: stickerIds,
      from: location.pathname,
      shareTo: shareTo,
    };

    history.push(`/share`, state);
  };

  return (
    <React.Fragment>
      <div className={styles.notebook_page}>
        {currentPage === 0 ? (
          <p className={styles.notebook_prologue}>
            นี่คือสมุดบันทึกของคุณ โพสต์ของคุณ
            รวมถึงสติกเกอร์ที่คุณได้รับจากแต่ละโพสต์จะถูกบันทึกไว้ในสมุด
            <br />
            <br />
            คุณสามารถปรับแต่งสมุดหรือแชร์เรื่องราว ของคุณได้
          </p>
        ) : (
          <div
            className={`${styles.share_social_container} ${
              (currentPage === 0 || hasNoStories) && styles.hidden
            }`}
          >
            <p className={styles.share_heading_text}>แชร์หน้าสมุดของคุณ</p>
            <div className={styles.social_icon_container}>
              <FontAwesomeIcon
                onClick={() => {
                  shareToSocial("facebook");
                }}
                icon={faFacebookSquare}
                size="2x"
                color="#3b5998"
              />
              &nbsp;&nbsp;&nbsp;
              {isMobile() && (
                <FontAwesomeIcon
                  onClick={() => {
                    shareToSocial("instagram");
                  }}
                  icon={faInstagramSquare}
                  size="2x"
                  color="#E1306C"
                />
              )}
              &nbsp;&nbsp;&nbsp;
              <FontAwesomeIcon
                onClick={() => {
                  shareToSocial("twitter");
                }}
                icon={faTwitter}
                size="2x"
                color="#08a0e9"
              />
            </div>
          </div>
        )}

        <div className={styles.notebook_container}>
          <FontAwesomeIcon
            className={currentPage === 0 ? styles.hidden : ""}
            onClick={decrementPage}
            icon={faCaretLeft}
            size="3x"
            color="#F13D57"
          />

          {/* Cover */}
          {currentPage === 0 && <img src={coverDesign} alt="notebookCover" />}

          {/* Pattern and Content */}
          {currentPage > 0 && !hasNoStories && (
            <div className={styles.pattern_container}>
              <div className={styles.pattern_overlay_content}>
                <p>
                  <strong>ชื่อเรื่อง:</strong>{" "}
                  {props.stories[currentPage - 1].title}
                </p>
                <p>
                  <strong>สถานที่:</strong>{" "}
                  {props.stories[currentPage - 1].place?.title_th}
                </p>
                <p>
                  <strong>วันที่โพสต์:</strong>{" "}
                  {iso8601ToPrettyDate(
                    props.stories[currentPage - 1].published_at!
                  )}
                </p>
                <p>
                  <strong>สติ๊กเกอร์ที่ได้รับ:</strong>
                </p>
                <div className={styles.stickers_container}>
                  {stickers?.slice(0, 7).map((v, idx) => {
                    const stickerImg = stickerByIdString.get(v[0] as string);
                    return (
                      <div key={`sticker-${idx}`}>
                        <img src={stickerImg} alt="" /> x{v[1]}
                      </div>
                    );
                  })}
                </div>
              </div>
              <img src={patternDesign} alt="notebookCover" />
            </div>
          )}

          {currentPage === 1 && hasNoStories && (
            <div className={styles.pattern_container}>
              <div className={styles.pattern_overlay_content}>
                <p>เรื่องราวของคุณจะถูกแสดงที่นี่ :)</p>
              </div>
              <img src={patternDesign} alt="notebookCover" />
            </div>
          )}

          <div className={hideRightArrow ? styles.hidden : ""}>
            <FontAwesomeIcon
              className={
                currentPage === props.stories?.length && !hasNoStories
                  ? styles.hidden
                  : ""
              }
              onClick={incrementPage}
              icon={faCaretRight}
              size="3x"
              color="#F13D57"
            />
          </div>
        </div>
      </div>

      <div className={styles.notebook_nav_container}>
        {changingCover && (
          <div className={styles.notebook_cover_panel}>
            <img
              onClick={() => updateCover("cover1")}
              src={nbCover1}
              alt="cover1"
            />
            <img
              onClick={() => updateCover("cover2")}
              src={nbCover2}
              alt="cover2"
            />
            <img
              onClick={() => updateCover("cover3")}
              src={nbCover3}
              alt="cover3"
            />
            <img
              onClick={() => updateCover("cover4")}
              src={nbCover4}
              alt="cover4"
            />
            <img
              onClick={() => updateCover("cover5")}
              src={nbCover5}
              alt="cover5"
            />
          </div>
        )}

        {changingPattern && (
          <div className={styles.notebook_cover_panel}>
            <img
              onClick={() => updatePattern("pattern1")}
              src={nbPat1}
              alt="pattern1"
            />
            <img
              onClick={() => updatePattern("pattern2")}
              src={nbPat2}
              alt="pattern2"
            />
            <img
              onClick={() => updatePattern("pattern3")}
              src={nbPat3}
              alt="pattern3"
            />
            <img
              onClick={() => updatePattern("pattern4")}
              src={nbPat4}
              alt="pattern4"
            />
          </div>
        )}

        <div className={styles.notebook_actions_container}>
          <div>
            <Button
              onClick={toggleChangingCover}
              width="100%"
              padding="0 20px"
              text="เลือกหน้าปก"
              backgroundColor={changingCover ? "grey" : "#76C3C0"}
            />
          </div>

          <div>
            <Button
              onClick={toggleChangingPattern}
              width="100%"
              padding="0 20px"
              text="เลือกกระดาษ"
              backgroundColor={changingPattern ? "grey" : "#76C3C0"}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default NotebookPage;

import React, { useState } from "react"
import styles from "./StickerShareBox.module.css"
import Carousel, { Dots } from '@brainhubeu/react-carousel';

import cactusHugPNG from "./../../assets/icons/stickers/cactushug.png"
import coffeePNG from "./../../assets/icons/stickers/coffee.png"
import crownPNG from "./../../assets/icons/stickers/crown.png"
import fistPNG from "./../../assets/icons/stickers/fist.png"
import flowerPNG from "./../../assets/icons/stickers/flower.png"
import goodjobPNG from "./../../assets/icons/stickers/goodjob.png"
import greenheartPNG from "./../../assets/icons/stickers/greenheart.png"
import metooPNG from "./../../assets/icons/stickers/metoo.png"
import musicPNG from "./../../assets/icons/stickers/music.png"
import pinkheartPNG from "./../../assets/icons/stickers/pinkheart.png"
import pplhugPNG from "./../../assets/icons/stickers/pplhug.png"
import queenPNG from "./../../assets/icons/stickers/queen.png"
import rainbowPNG from "./../../assets/icons/stickers/rainbow.png"
import redheartPNG from "./../../assets/icons/stickers/redheart.png"
import starPNG from "./../../assets/icons/stickers/star.png"
import yellowheartPNG from "./../../assets/icons/stickers/yellowheart.png"
import pearfectPNG from "./../../assets/icons/stickers/pearfect.png"
import { StickerIdByString } from "../../types/constant";

type stickerShareBoxProps = {
  storyId?: number
  // onClickSticker is used for executing any function
  onClickSticker?: () => void
  // onSetSentSticker is a react setState function that lifts the selected stateId
  // up to its parent
  onSetSentSticker?: React.Dispatch<React.SetStateAction<number>>
  toggleStickerPanel?: React.Dispatch<React.SetStateAction<boolean>>
}

const StickerShareBox = (props: stickerShareBoxProps): JSX.Element => {
  const [carouselDotVal, setCarouselDotVal] = useState(0)

  const onChangeStickerPane = (value: number) => {
    if (!Number.isNaN(value)) {
      setCarouselDotVal(value);
      return
    }
  }

  const onSelectSticker = (stickerName: string) => {
    const stickerId = StickerIdByString.get(stickerName)

    if (props.onSetSentSticker !== undefined) {
      if (stickerId! !== 0) {
        props.onSetSentSticker(stickerId!);
      }
    }

    // onClick handler
    if (props.onClickSticker !== undefined) {
      props.onClickSticker();
    }

    // close sticker pane
    if (props.toggleStickerPanel !== undefined) {
      props.toggleStickerPanel(false);
    }
  }

  return (
    <React.Fragment>
      <div className={styles.sticker_sharebox_container}>
        <Carousel value={carouselDotVal} onChange={onChangeStickerPane}>
          <div className={styles.sticker_pane}>
            <div className={styles.sticker_row}>
              <img onClick={() => onSelectSticker("cactushug")} src={cactusHugPNG} alt="cactusHug" />
              <img onClick={() => onSelectSticker("coffee")} src={coffeePNG} alt="coffee" />
              <img onClick={() => onSelectSticker("crown")} src={crownPNG} alt="crown" />
            </div>
            <div className={styles.sticker_row}>
              <img onClick={() => onSelectSticker("fist")} src={fistPNG} alt="fist" />
              <img onClick={() => onSelectSticker("flower")} src={flowerPNG} alt="flower" />
              <img onClick={() => onSelectSticker("goodjob")} src={goodjobPNG} alt="goodjob" />
            </div>
          </div>

          <div className={styles.sticker_pane}>
            <div className={styles.sticker_row}>
              <img onClick={() => onSelectSticker("greenheart")} src={greenheartPNG} alt="greenheart" />
              <img onClick={() => onSelectSticker("metoo")} src={metooPNG} alt="metoo" />
              <img onClick={() => onSelectSticker("music")} src={musicPNG} alt="music" />
            </div>
            <div className={styles.sticker_row}>
              <img onClick={() => onSelectSticker("pinkheart")} src={pinkheartPNG} alt="pinkheart" />
              <img onClick={() => onSelectSticker("pplhug")} src={pplhugPNG} alt="pplhug" />
              <img onClick={() => onSelectSticker("queen")} src={queenPNG} alt="queen" />
            </div>
          </div>

          <div className={styles.sticker_pane}>
            <div className={styles.sticker_row}>
              <img onClick={() => onSelectSticker("rainbow")} src={rainbowPNG} alt="rainbow" />
              <img onClick={() => onSelectSticker("redheart")} src={redheartPNG} alt="redheart" />
              <img onClick={() => onSelectSticker("star")} src={starPNG} alt="star" />
            </div>
            <div className={styles.sticker_row}>
              <img onClick={() => onSelectSticker("yellowheart")} src={yellowheartPNG} alt="yellowheart" />
              <img onClick={() => onSelectSticker("pearfect")} src={pearfectPNG} alt="yellowheart" />
            </div>
          </div>
        </Carousel>

        <Dots value={carouselDotVal} onChange={onChangeStickerPane} number={3} />
      </div>
    </React.Fragment>
  )
}


export default StickerShareBox;
import React, { BaseSyntheticEvent } from "react";
import TextInput from "../ui/TextInput";
import Button from "../ui/Button";
import SizedBox from "../ui/SizedBox";

import styles from "./LoginForm.module.css";
import GoogleButton from "../ui/GoogleButton";
import FacebookButton from "../ui/FacebookButton";
import { Link } from "react-router-dom";
import { useState } from "react";
import { userLoginReq } from "../../types/user";
import axios, { AxiosResponse } from "axios";
import { Endpoint, LOCALSTORAGE_JWT } from "../../types/constant";
import { useHistory } from "react-router";
import { useAppDispatch } from "../../hooks";
import {
  setCurrentUser,
  setUserIsAuthenticated,
} from "../../reducers/userSlice";
import { displayLoadingScreen } from "../../reducers/uiSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const LoginForm = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [userLoginData, setUserLoginData] = useState<userLoginReq>({});
  const [loginErrorMessage, setLoginErrorMessage] = useState<string>("");
  const [showLoginErrorMessage, setShowLoginErrorMessage] =
    useState<boolean>(false);

  const submitHandler = (e: BaseSyntheticEvent) => {
    e.preventDefault();

    dispatch(displayLoadingScreen(true));

    axios
      .post(
        `${Endpoint.BACKEND_URL}${Endpoint.PATH_USER_LOCAL_LOGIN}`,
        userLoginData
      )
      .then((res) => {
        if (res.status === 200) {
          const jwt = res.data.jwt;

          localStorage.setItem(LOCALSTORAGE_JWT, jwt);
          dispatch(setCurrentUser(res.data.user));
          dispatch(setUserIsAuthenticated(true));

          history.push("/home");
        }
      })
      .catch((err) => {
        const resp: AxiosResponse = err.response;
        if (resp.status === 400) {
          setShowLoginErrorMessage(true);
          setLoginErrorMessage(
            "อีเมลหรือรหัสผ่านผิด กรุณาลองใหม่อีกครั้ง หรือหากคุณยังไม่ได้ยืนยันตัวตน กรุณายืนยันตัวตนของคุณก่อนลงชื่อเข้าใช้"
          );
        } else if (resp.status === 500) {
          setShowLoginErrorMessage(true);
          setLoginErrorMessage("เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง");
        }
      })
      .finally(() => {
        dispatch(displayLoadingScreen(false));
      });
  };

  const onEmailChange = (e: React.FormEvent<HTMLInputElement>) => {
    const currentEmail = e.currentTarget.value;
    setUserLoginData({ ...userLoginData, identifier: currentEmail });
  };

  const onPasswordChange = (e: React.FormEvent<HTMLInputElement>) => {
    const currentPassword = e.currentTarget.value;
    setUserLoginData({ ...userLoginData, password: currentPassword });
  };

  return (
    <React.Fragment>
      <form className={styles.login_form} onSubmit={submitHandler}>
        <div
          className={styles.close_form}
          onClick={() => {
            history.push("/home");
          }}
        >
          <FontAwesomeIcon icon={faTimes} size="1x" />
        </div>

        <h1 className={styles.text_header}>LOG IN</h1>

        <TextInput hintText="Email" onChange={onEmailChange} />
        <SizedBox height="4vh" />
        <TextInput
          hintText="Password"
          inputType="password"
          onChange={onPasswordChange}
        />
        <div style={{ textAlign: "right" }}>
          <Link to="/forgot-password">forgot password</Link>
        </div>

        {showLoginErrorMessage && (
          <p className={styles.text_warning}>* {loginErrorMessage}</p>
        )}

        <SizedBox height="4vh" />
        <div>
          <Button backgroundColor="#76C3C0" text="Sign in" width="100%" />
        </div>

        <SizedBox height="2vh" />
        <p>OR</p>

        <a href="https://svc.allthaiwomen.com/connect/google">
          <GoogleButton disabled={false} />
        </a>

        {/* Facebook Login */}
        <a href="https://svc.allthaiwomen.com/connect/facebook">
          <FacebookButton disabled={false} />
        </a>

        <div className={styles.create_account_link}>
          <Link to="/signup">Create an account</Link>
        </div>
      </form>
    </React.Fragment>
  );
};

export default LoginForm;

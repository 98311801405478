import React, { useEffect } from "react";
import styles from "./StoryFold.module.css";

import SchoolSVG from "./../../assets/icons/locations/school.svg";

import { story } from "../../types/story";
import StoryThumbnail from "./StoryThumbnail";
import { useAppDispatch } from "../../hooks";
import StoryFoldDropdown from "./StoryFoldDropdown";
import { useHistory, useLocation } from "react-router";
import { useQuery } from "../../hooks/useQuery";
import { placeIconByString } from "../../types/constant";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

type storyFoldProps = {
  stories: story[];
  setFetchCriteria: React.Dispatch<React.SetStateAction<string>>;
};

const StoryFold = (props: storyFoldProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const query = useQuery();

  const [placeIcon, setPlaceIcon] = useState<string>(SchoolSVG);

  useEffect(() => {
    const place = query.get("place");
    if (place !== null) {
      const _placeIcon = placeIconByString.get(place);
      if (_placeIcon !== undefined) {
        setPlaceIcon(_placeIcon);
      }
    }
  }, [dispatch, query]);

  let stories = props.stories.map((story) => {
    return (
      <StoryThumbnail
        key={story.id}
        id={story.id}
        title={story.title}
        triggerWarnings={story.trigger_warnings}
        contentPreview={story.content}
        author_alias={story.author_alias}
        sitcker_pressed={story.sticker_pressed}
        sticker_count={story.sticker_count}
        goToStory={() => {
          history.push(`/s/${story.id}${location.search}`, { from: "spage" });
        }}
      />
    );
  });

  return (
    <React.Fragment>
      <div id="story-fold" className={styles.storyfold_container}>
        {/* Thumbnails container */}
        <div className={styles.thumbnails_container}>
          {/* Icon */}
          <div className={styles.location_icon}>
            <img src={placeIcon} alt="school icon" />
          </div>

          {/* Dropdown Filter */}
          <div className={styles.dropdown_filter}>
            <div
              onClick={() => {
                history.push("/map");
              }}
              className={styles.dropdown_filter_gomap}
            >
              <FontAwesomeIcon icon={faChevronLeft} /> กลับแผนที่
            </div>
            <StoryFoldDropdown
              onChange={props.setFetchCriteria}
              title="feed filter"
              listOptions={[
                {
                  name: "ใหม่ล่าสุด",
                  value: "most recent",
                },
                {
                  name: "เป็นที่นิยม",
                  value: "most popular",
                },
              ]}
            />
          </div>

          {/* Stories Thumbnails */}
          <div>
            {props.stories.length === 0 ? (
              <h3 className={styles.text_center}>ไม่พบเรื่องราว...</h3>
            ) : (
              stories
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default StoryFold;

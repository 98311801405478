import React from "react"
import styles from "./AnimatedArrow.module.css"

const AnimatedArrow = (): JSX.Element => {
  return (<React.Fragment>
    <div className={styles.arrow}>
      <span></span>
      <span></span>
      <span></span>
    </div>
  </React.Fragment>)
}

export default AnimatedArrow;

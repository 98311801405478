import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Route, Switch, useParams } from "react-router-dom";
import UserNav from "../components/user/UserNav";
import { useAppDispatch } from "../hooks";
import { displayLoadingScreen } from "../reducers/uiSlice";
import { Endpoint, LOCALSTORAGE_JWT } from "../types/constant";
import { story } from "../types/story";
import { user } from "../types/user";
import NotebookPage from "./NotebookPage";
import UserInfoPage from "./UserInfoPage";
import UserPostsPage from "./UserPostsPage";
import styles from "./UserPage.module.css"
import UserNavDesktop from "../components/user/UserNavDesktop";

type userPageRouteParams = {
  username: string,
}

const UserPage = (): JSX.Element => {
  const jwt = localStorage.getItem(LOCALSTORAGE_JWT);

  const history = useHistory();
  const dispatch = useAppDispatch();
  const [currentUser, setCurrentUser] = useState<user>();
  const [stories, setStories] = useState<story[]>();
  const { username } = useParams<userPageRouteParams>();

  useEffect(() => {
    const ac = new AbortController();
    let _user: user;

    dispatch(displayLoadingScreen(true));

    axios.get(`${Endpoint.BACKEND_URL}${Endpoint.PATH_USER_ME}`,
      { headers: { "Authorization": `Bearer ${jwt}` } })
      .then(res => {
        setCurrentUser(res.data);
        _user = res.data;
      }).then(() => {
        axios.get(`${Endpoint.BACKEND_URL}${Endpoint.PATH_STORY}?author_eq=${_user.id}&_sort=published_at:desc`)
          .then(res => {
            setStories(res.data);
          })
          .catch(err => {
            console.log(err);
          })
      })
      .catch(err => {
        if (err.response.status >= 400) {
          history.push("/login")
        }
      })
      .finally(() => {

        dispatch(displayLoadingScreen(false));
      })

    return () => {
      ac.abort();
    }
  }, [dispatch, jwt, username, history])

  return (
  <React.Fragment>
    <div className={styles.mobile_nav}>
      <UserNav />
    </div>

    <div className={styles.desktop_layout_controller}>
      <div className={styles.desktop_nav}>
        <UserNavDesktop />
      </div>

      <div className={styles.content_container}>
        <Switch>
          <Route path="/user/:username/info">
            <UserInfoPage currentUser={currentUser} setCurrentUser={setCurrentUser} />
          </Route>
          <Route path="/user/:username/notebook">
            <NotebookPage currentUser={currentUser} setCurrentUser={setCurrentUser} stories={stories!} />
          </Route>
          <Route path="/user/:username/posts">
            <UserPostsPage stories={stories} setStories={setStories} />
          </Route>
        </Switch>
      </div>
    </div>
  </React.Fragment>)
}

export default UserPage;
import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./Header.module.css";

import LogoSVG from "../../assets/icons/logo-02.png";
import { useEffect } from "react";
import { Endpoint, LOCALSTORAGE_JWT } from "../../types/constant";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { setCurrentUser, setUserIsAuthenticated } from "../../reducers/userSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useHistory } from "react-router";
import { useState } from "react";
import { displayLoadingScreen } from "../../reducers/uiSlice";

type HeaderProps = {
  useDefaultTitle: Boolean
  title: string
};

const Header = (props: HeaderProps): JSX.Element => {
  const [showUserNavDropdown, setShowUserNavDropdown] = useState(false);
  const userLoggedIn = useAppSelector(state => state.user.userIsAuthenticated);
  const currentUsername = useAppSelector(state => state.user.currentUser.username);

  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(() => {
    const jwt = localStorage.getItem(LOCALSTORAGE_JWT)
    if (jwt === null) {
      dispatch(setUserIsAuthenticated(false));
    } else {
      axios.get(`${Endpoint.BACKEND_URL}${Endpoint.PATH_USER_ME}`, {
        headers: { "Authorization": `Bearer ${jwt}`, }
      }).then(res => {
        if (res.status === 200) {
          dispatch(setUserIsAuthenticated(true));
          const user = res.data;

          dispatch(setCurrentUser(user));
        }
      }).catch(err => {
        console.log(err);
      })

    }

    return () => { }
  }, [dispatch]);

  const logoutHandler = () => {
    setShowUserNavDropdown(false);
    dispatch(displayLoadingScreen(true));

    localStorage.removeItem(LOCALSTORAGE_JWT);
    dispatch(setUserIsAuthenticated(false));
    dispatch(setCurrentUser({}));

    setTimeout(() => {
      dispatch(displayLoadingScreen(false));
    }, 1000)

    window.scrollTo(0, 0)
    history.push("/home")
  }

  const toggleUserNavDropdown = () => {
    setShowUserNavDropdown(state => !state)
  }

  const truncateUsername = (username: string | undefined, maxLenth: number): string | undefined => {
    if (username !== undefined) {
      if (username.length >= maxLenth) {
        return username.slice(0, maxLenth) + "..."
      }
      return username
    }
    return username
  }

  return (
    <React.Fragment>
      <header id="app-header" className={styles.header}>
        <ul className={styles["header--nav"]}>

          <div className={styles.nav_logo_container}>
            <NavLink
              to="/home">
              <li className={styles["header--nav_item"]}>
                <img className={styles["header--nav_logo"]} src={LogoSVG} alt="logo" />
              </li>
            </NavLink>
          </div>

          <div className={styles.nav_text_container}>
            {
              userLoggedIn ?
                <React.Fragment>
                  <li className={`${styles["header--nav_item"]} `} style={{ marginLeft: "auto" }}>
                    <FontAwesomeIcon icon={faUserCircle} /> &nbsp;
                    <span className={styles.nav_link} onClick={toggleUserNavDropdown}>
                      Hi, {truncateUsername(currentUsername, 16)}
                    </span>

                    {
                      showUserNavDropdown &&
                      <div className={styles.user_nav_item_dropdown}>
                        <div className={styles.user_nav_item_dropdown_first} onClick={() => setShowUserNavDropdown(false)}>
                          <NavLink style={{ color: "black" }} to={`/user/${currentUsername}/info`}>
                            แก้ไขข้อมูลส่วนตัว
                          </NavLink>
                        </div >
                        <div className={styles.user_nav_item_dropdown_middle} onClick={() => setShowUserNavDropdown(false)}>
                          <NavLink style={{ color: "black" }} to={`/user/${currentUsername}/notebook`}>
                            สมุดของฉัน
                          </NavLink>
                        </div>
                        <div className={styles.user_nav_item_dropdown_last} onClick={logoutHandler} >
                          ออกจากระบบ
                        </div>
                      </div>
                    }
                  </li>
                  <li className={styles["header--nav_item"]} >
                    <NavLink
                      style={{ color: "#f13d57" }} to={`/faq`}>
                      FAQ
                    </NavLink>
                  </li>
                </React.Fragment>
                :
                <React.Fragment>
                  <NavLink to="/faq"
                    className={styles.nav_link}
                    style={{ marginLeft: "auto" }}>
                    <li className={`${styles["header--nav_item"]}`} >FAQ</li>
                  </NavLink>

                  <NavLink to="/login"
                    className={styles.nav_link}
                  >
                    <li className={styles["header--nav_item"]}>Log in</li>
                  </NavLink>

                  <NavLink to="/signup"
                    className={styles.nav_link}
                  >
                    <li className={`${styles["header--nav_item"]} ${styles["header--nav_item_signup"]}`}>Sign up</li>
                  </NavLink>
                </React.Fragment>
            }
          </div>
        </ul>
      </header>

      {/* Fake Header */}
      <div
        // style={{ height: document.getElementById("app-header")?.offsetHeight }}
        className={styles.fake_header}></div>

    </React.Fragment >
  )
}

export default Header;
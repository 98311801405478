import React, { useEffect, useState } from "react";
import axios from "axios";
import { useHistory, useLocation, useParams } from "react-router";
import { useAppDispatch } from "../hooks";
import { setCurrentUser, setUserIsAuthenticated } from "../reducers/userSlice";

type LoginRedirectURLParams = {
  provider: string;
};

const LoginRedirect = (): JSX.Element => {
  const location = useLocation();
  const history = useHistory();
  const [text, setText] = useState("");
  const dispatch = useAppDispatch();
  const { provider } = useParams<LoginRedirectURLParams>();

  useEffect(() => {
    axios
      .get(
        `https://svc.allthaiwomen.com/auth/${provider}/callback${location.search}`
      )
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(`Couldn't login to Strapi. Status: ${res.status}`);
        }
        return res;
      })
      .then((res) => {
        const data = res.data;
        localStorage.setItem("jwt", data.jwt);
        dispatch(setCurrentUser(data.user));
        dispatch(setUserIsAuthenticated(true));

        setText(`ล็อกอินสำเร็จ คุณกำลังถูกพาไปที่หน้า Home...`);
        setTimeout(() => {
          history.push("/home");
        }, 3000);
      })
      .catch((err) => {
        console.log(err);

        if (err.response.status === 400) {
          console.log(err.response.data.message);
        } else {
          // console.log("unknown error occurred")
        }

        setText(
          `มีบางอย่างผิดพลาดเกิดขึ้น ไม่สามารถสมัครด้วย ${provider} ได้เนื่องจากคุณอาจใช้อีเมลนี้สมัครไปแล้ว กรุณาลองใหม่อีกครั้ง`
        );

        setTimeout(() => {
          history.push("/home");
        }, 3000);
      });
    return () => {};
  }, [history, location.search, dispatch, provider]);

  return (
    <React.Fragment>
      <div style={{ marginTop: "5vh" }}>{text}</div>
    </React.Fragment>
  );
};

export default LoginRedirect;

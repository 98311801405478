import React from "react";
import styles from "./SharePage.module.css";
import html2canvas from "html2canvas";
import logoPNG from "./../assets/icons/logo-02.png"
import { useAppDispatch } from "../hooks";
import { useEffect } from "react";
import { useHistory } from "react-router";

import cactusHugPNG from "./../assets/icons/stickers/cactushug.png"
import coffeePNG from "./../assets/icons/stickers/coffee.png"
import crownPNG from "./../assets/icons/stickers/crown.png"
import fistPNG from "./../assets/icons/stickers/fist.png"
import flowerPNG from "./../assets/icons/stickers/flower.png"
import goodjobPNG from "./../assets/icons/stickers/goodjob.png"
import greenheartPNG from "./../assets/icons/stickers/greenheart.png"
import metooPNG from "./../assets/icons/stickers/metoo.png"
import musicPNG from "./../assets/icons/stickers/music.png"
import pinkheartPNG from "./../assets/icons/stickers/pinkheart.png"
import pplhugPNG from "./../assets/icons/stickers/pplhug.png"
import queenPNG from "./../assets/icons/stickers/queen.png"
import rainbowPNG from "./../assets/icons/stickers/rainbow.png"
import redheartPNG from "./../assets/icons/stickers/redheart.png"
import starPNG from "./../assets/icons/stickers/star.png"
import yellowheartPNG from "./../assets/icons/stickers/yellowheart.png"
import pearfectPNG from "./../assets/icons/stickers/pearfect.png"
import { useState } from "react";
import { isMobile } from "../utils/validation";
import { useCallback } from "react";

const stickerByIdString = new Map<string, string>([
  ["1", coffeePNG],
  ["2", queenPNG],
  ["3", crownPNG],
  ["4", metooPNG],
  ["5", flowerPNG],
  ["6", goodjobPNG],
  ["7", pplhugPNG],
  ["8", cactusHugPNG],
  ["9", rainbowPNG],
  ["10", musicPNG],
  ["11", starPNG],
  ["12", greenheartPNG],
  ["13", pinkheartPNG],
  ["14", redheartPNG],
  ["15", yellowheartPNG],
  ["16", pearfectPNG],
  ["17", fistPNG],
])

const SharePage = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [place, setPlace] = useState<string>("");
  const [storyTitle, setStoryTitle] = useState<string>("");
  const [stickerIds, setStickerIds] = useState<string[]>([]);
  const [imageReady, setImageReady] = useState<boolean>(false);

  const saveImage = useCallback(
    () => {
      const node = document.getElementById("share-page-content-container");

      html2canvas(node!)
        .then((canvas) => {
          const link = document.createElement("a");
          link.href = canvas.toDataURL("image/jpeg");
          link.download = "allthaiwomen.jpeg";
          link.click();
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
        })
    },
    [],
  )

  useEffect(() => {
    // redirect to home if user hasn't come from his/her notebook page
    if (history.location.state === undefined) {
      history.push("/home");
      return
    }


    const state = history.location.state as any;
    const story = state.story;

    setStoryTitle(story.title);
    setPlace(story.place.title_th);
    setStickerIds(state.stickerIds);

    setImageReady(true);
    return () => { }
  }, [history, dispatch])

  useEffect(() => {
    if (imageReady) {
      saveImage();

      setTimeout(() => {

        const state = history.location.state as any;
        const story = state.story;
        const shareTo = state.shareTo;

        const shareLink = document.createElement("a")
        const shareStoryURL = `https://www.allthaiwomen.com/s/${story.id}`
        const shareText = `"${story.title}" อ่านเรื่องของฉันและร่วมแชร์ปัญหาของผู้หญิงไทย`

        switch (shareTo) {
          case "facebook":

            shareLink.setAttribute("target", "_blank")
            const facebookURL = `https://www.facebook.com/sharer.php?text=${shareText}&
        u=${shareStoryURL}&quote=${shareText}&hashtag=%23AllThaiWomen`
            shareLink.href = facebookURL;
            shareLink.click();

            break;

          case "instagram":
            if (isMobile()) {
              shareLink.href = "instagram://story-camera";
              shareLink.click();
            }

            break;

          case "twitter":
            shareLink.setAttribute("target", "_blank")
            const tweetURL = `https://twitter.com/intent/tweet?url=${shareStoryURL}&text=${shareText}&hashtags=AllThaiWomen`
            // open app
            shareLink.href = tweetURL
            shareLink.className = "twitter-share-button"
            shareLink.setAttribute("data-text", shareText)
            shareLink.setAttribute("data-size", "large")
            shareLink.setAttribute("data-url", shareStoryURL)
            shareLink.setAttribute("data-hashtags", "AllThaiWomen")
            shareLink.click();

            break;
          default:
            break;
        }

        history.replace(state.from)
      }, 5000);

    }

    return () => {
    }
  }, [imageReady, history, saveImage])

  return (<React.Fragment>

    <div className={styles.sharepage_container}>

      <div id="share-page-content-container" className={styles.sharebox_container}>
        <img className={styles.logo} src={logoPNG} alt="logo" />

        <div className={styles.nb_pattern_front}>
          <div className={styles.content_container}>
            <p>สถานที่: {place}</p>
            <p>ชื่อเรื่อง: {storyTitle}</p>
            <p className={styles.your_sticker_text}>สติ๊กเกอร์ที่ได้รับ</p>
            <div className={styles.stickers_container}>
              {stickerIds.map(stickerId => {
                return <img key={`sticker-${stickerId}`} src={stickerByIdString.get(stickerId)} alt="sticker" />
              })}
            </div>
          </div>
        </div>
        <div className={styles.nb_pattern_back}></div>
      </div>

      <div className={styles.prompt_container}>
        <p>
          กำลังดาวน์โหลดรูปภาพเรื่องราวของคุณ คุณจะถูกพาไปยังแพลตฟอร์มที่ต้องการแชร์...
        </p>
        <p>
          หากไม่มีอะไรเกิดขึ้น กรุณาคลิก
          <a href="/home">ที่นี่</a>
        </p>
      </div>

    </div>
  </React.Fragment>)
}

export default SharePage;
import axios from "axios";
import React from "react";
import { useState } from "react";
import { useHistory } from "react-router";
import { useAppDispatch } from "../../hooks";
import { displayLoadingScreen } from "../../reducers/uiSlice";
import { isValidEmail } from "../../utils/validation";
import Button from "../ui/Button";
import TextInput from "../ui/TextInput";
import styles from "./ForgotPasswordForm.module.css";

const ForgotPasswordForm = (): JSX.Element => {
  const [requestSentSuccess, setRequestSentSuccess] = useState<boolean>(false);
  const [requestSentFailed, setRequestSentFailed] = useState<boolean>(false);
  const [showEmailError, setShowEmailError] = useState<boolean>(false);
  const [disableInput, setDisableInput] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const history = useHistory();
  const dispatch = useAppDispatch();

  const onSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
    // * not used
    e.preventDefault();
  };

  const sendResetPasswordRequest = () => {
    // validate email
    if (!isValidEmail(email)) {
      setShowEmailError(true);
      return;
    }

    setDisableInput(true);
    dispatch(displayLoadingScreen(true));

    axios
      .post("https://svc.allthaiwomen.com/auth/forgot-password", {
        email: email,
      })
      .then((res) => {
        if (res.status === 200) {
          setRequestSentSuccess(true);
        }
      })
      .catch((err) => {
        setRequestSentFailed(true);
        console.log(err);
      })
      .finally(() => {
        dispatch(displayLoadingScreen(false));
        setTimeout(() => {
          history.push("/home");
        }, 8000);
      });
  };

  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowEmailError(false);
    setEmail(e.currentTarget.value);
  };

  return (
    <React.Fragment>
      <div>
        <form className={styles.forgot_password_form} onSubmit={onSubmitForm}>
          <label htmlFor="email">
            กรุณาใส่อีเมลของคุณเพื่อตั้งรหัสผ่านใหม่
          </label>
          <TextInput
            disabled={disableInput}
            value={email}
            hintText="กรุณากรอกอีเมลของคุณที่นี่"
            onChange={onChangeEmail}
          />
          {showEmailError && (
            <p className={styles.warn_text}>
              * อีเมลไม่ถูกต้อง กรุณากรอกใหม่อีกครั้ง
            </p>
          )}
          <Button
            onClick={sendResetPasswordRequest}
            text="ยืนยัน"
            backgroundColor="#f13d57"
            width="100%"
            margin="1em 0 2em 0"
          />

          {requestSentSuccess && (
            <React.Fragment>
              <p style={{ color: "green", textAlign: "left" }}>
                ส่งคำขอสำเร็จ กรุณาตรวจสอบอีเมลของคุณเพื่อตั้งรหัสผ่านใหม่{" "}
              </p>
              <p style={{ color: "green", textAlign: "left" }}>
                เรากำลังนำคุณกลับไปที่หน้า Home
              </p>
            </React.Fragment>
          )}

          {requestSentFailed && (
            <React.Fragment>
              <p style={{ color: "red", textAlign: "left" }}>
                ส่งคำขอไม่สำเร็จ เนื่องจากไม่สามารถติดต่อกับเซิร์ฟเวอร์ได้
                กรุณาลองอีกครั้ง
              </p>
              <p style={{ color: "red", textAlign: "left" }}>
                เรากำลังนำคุณกลับไปที่หน้า Home
              </p>
            </React.Fragment>
          )}
        </form>
      </div>
    </React.Fragment>
  );
};

export default ForgotPasswordForm;
